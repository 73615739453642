import React from "react";
import {useNavigate} from "react-router-dom";
import {faBars, faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import {quickMenu} from "~/components/sidebar/menus";
import {useUser} from "~/data/user";
import {usePlatform} from "~/utills/use-platform";

import {HeaderProps} from "./header.interface";
import Logo from "./lottemart logo.png";

import styles from "./header.module.scss";
const cx = classNames.bind(styles);

export const Header = (props: HeaderProps) => {
    const navigate = useNavigate();
    const {logout} = useUser();

    const handleLogoClick = () => navigate("/");

    const {platform} = usePlatform();
    const {user} = useUser();

    const handleLogout = () => {
        if (platform === "mobile") {
            Swal.fire({
                title: "로그아웃 하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "로그아웃",
                cancelButtonText: "돌아가기",
            }).then((result) => {
                if (result.isConfirmed) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.flutter_inappwebview.callHandler("handlerWithArgs", {type: 200});
                }
            });
        } else {
            logout();
        }
    };

    return (
        <div className={cx("container")}>
            <div className={cx("logo-container")}>
                <a href="javascript:void(0);" onClick={handleLogoClick}>
                    <img src={Logo} alt="롯데마트 로고" className={cx("logo")} />
                </a>
            </div>
            <div className={cx("contents-container")}>
                <ul className={cx("list")}>
                    <li className={cx("item")}>
                        <button onClick={props.toggleSidebar} className={cx("menu-button")}>
                            <FontAwesomeIcon icon={faBars} className={cx("icon")} />
                        </button>
                    </li>
                    {quickMenu.map((item, index) => (
                        <li className={cx("item", "quick-menu")} key={index}>
                            <a
                                href={"javascript:void(0);"}
                                onClick={() =>
                                    item?.path ? navigate(item.path) : window.open("https://old.lottemartfms.com")
                                }
                            >
                                {item?.icon && <FontAwesomeIcon icon={item.icon} className={cx("icon")} />}
                                <span className={cx("name")}>{item.name}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={cx("status-container")}>
                <span>{user?.division === "MEMBER" ? user?.name : user?.buildingName}</span>
                <button className={cx("logout-button")}>
                    <FontAwesomeIcon icon={faRightFromBracket} className={cx("icon")} onClick={handleLogout} />
                </button>
            </div>
        </div>
    );
};

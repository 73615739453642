import classNames from "classnames/bind";

import {FilterItemProps} from "./filter-item.interface";

import styles from "./filter-item.module.scss";

const cx = classNames.bind(styles);

const FilterItem = ({label, children, ...props}: FilterItemProps) => {
    return (
        <div>
            <div className={cx("container")}>
                <label className={cx("label")}>{label}</label>
                {children}
            </div>
        </div>
    );
};

export {FilterItem};

import {useEffect, useState} from "react";

const usePlatform = () => {
    const userAgent = navigator.userAgent;
    let tf = false;
    if (userAgent.includes("FEMS-Mobile-App")) tf = true;

    const [isMobile, setIsMobile] = useState(tf);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const userAgent = navigator.userAgent;
        if (userAgent.includes("FEMS-Mobile-App")) setIsMobile(true);
        setIsLoading(false);
    }, []);

    return {platform: isMobile ? "mobile" : "desktop", isLoading};
};

export {usePlatform};

import {useEffect, useState} from "react";

import {User} from "~/data/user/user.interface";
import {httpRequest} from "~/fetch/common/http-request";
import {useLocalStorage} from "~/utills/useLocalStorage";

import {useUserContext} from "./user-context";

const useUser = () => {
    const {user, setUser, isLoading} = useUserContext();
    const {setItem, getItem} = useLocalStorage();
    const [role, setRole] = useState<"MEMBER" | "MANAGER" | "EMPLOYEE">();
    const [buildingId, setBuildingId] = useState<number>();

    useEffect(() => {
        setRole(user?.division);
        setBuildingId(user?.buildingId);
    }, [user]);

    const login = async (id: string, password: string, platform: string) => {
        const request = httpRequest<{username: string; password: string; platform: string}, User>({
            method: "POST",
            url: "/auth",
            data: {
                username: id,
                password: password,
                platform: platform,
            },
        });

        const loginUser = await request.submit();

        setUser(loginUser.data);
        setItem("LM_USER", JSON.stringify(loginUser.data));
    };

    const logout = () => {
        setUser(null);
        setItem("LM_USER", "");
        window.location.href = "/login";
    };

    return {user, setUser, login, logout, role, buildingId, isLoading};
};

export {useUser};

import {Dispatch, SetStateAction, useCallback, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";
import {EquipmentRegisterInput} from "~/pages/equipment/register/equipment-board-register.modal.interface";

import {EquipmentHistoryDelete, EquipmentHistoryRegisterInput, EquipmentRegisterRequest} from "./register.interface";

const useEquipmentRegistration = (
    config: EquipmentRegisterInput,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
): [() => Promise<void>] => {
    const request = useMemo(() => {
        return httpRequest<EquipmentRegisterRequest, null>({
            method: "POST",
            url: "/machine/mc",
            data: config,
        });
    }, [config]);

    const submit = async () => {
        if (config.machineName.trim().length > 0 && config.machineCategoryCode !== "") {
            if (window.confirm("등록하시겠습니까?")) {
                try {
                    setIsLoading(true);
                    await request.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    setIsLoading(false);
                    alert("등록되었습니다");
                }
            } else {
                setIsLoading(true);
            }
        } else {
            alert("필수 항목을 입력해 주세요.");
        }
    };

    return [submit];
};

// 게시글 등록시 사용되는 select 리스트
// const useEquipmentRegisterTargetList = (): [() => Promise<void>, MergeRegisterTargetList | null, boolean] => {
const useEquipmentRegisterTargetList = (data: any): [() => Promise<void>, any | null, boolean] => {
    // const [response, setResponse] = useState<MergeRegisterTargetList | null>(null);
    const [response, setResponse] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const reqCategory = useMemo(() => {
        // return httpRequest<null, Array<SectorResponse>>({
        return httpRequest<null, Array<any>>({
            method: "GET",
            url: "/machine/category",
        });
    }, []);
    const reqCompany = useMemo(() => {
        // return httpRequest<null, Array<SpecificResponse>>({
        return httpRequest<null, any>({
            method: "GET",
            url: "/machine/getCompany",
        });
    }, []);
    const reqRegion = useMemo(() => {
        // return httpRequest<null, Array<TrainerResponse>>({
        return httpRequest<null, any>({
            method: "GET",
            url: `/buildings/${data.buildingId}/regions`,
        });
    }, []);

    const submit = async () => {
        try {
            setIsLoading(true);
            const resCategory = await reqCategory.submit();
            const resCompany = await reqCompany.submit();
            const resRegion = await reqRegion.submit();

            setResponse({
                category: resCategory.data.map((data) => ({
                    value: data.machineCategoryCode,
                    label: data.machineCategoryName,
                })),
                manufactureCompany: resCompany.data.manufactureCompanyList
                    .filter((item: any) => item !== null)
                    .map((data: any) => ({
                        value: data.machine_manufacture_company,
                        label: data.machine_manufacture_company,
                    })),
                region: resRegion.data.map((data: any) => ({
                    value: data.regionId,
                    label: data.regionName,
                })),
                installCompany: resCompany.data.installCompanyList
                    .filter((item: any) => item !== null)
                    .map((data: any) => ({
                        value: data.machine_install_company,
                        label: data.machine_install_company,
                    })),
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading];
};

const useEquipmentCheckDTOList = (data: any): [() => Promise<void>, any | null, boolean] => {
    // const [response, setResponse] = useState<MergeRegisterTargetList | null>(null);
    const [response, setResponse] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const reqCheckList = useMemo(() => {
        // return httpRequest<null, Array<TrainerResponse>>({
        return httpRequest<null, any>({
            method: "GET",
            url: `/machine/mv?machineCategoryCode=${data.machineCategoryCode}`,
        });
    }, [data.machineCategoryCode]);

    const submit = async () => {
        if (!data.machineCategoryCode) {
            return;
        }

        try {
            setIsLoading(true);
            const resCheckList = await reqCheckList.submit();
            setResponse(
                resCheckList.data.machineDTO.machineCheckDTOList,
                // .map((data: any) => ({
                //     machineCheckId: data.machineCheckId, // 추가정보 아이디 Integer number
                //     machineCategoryCode: data.machineCategoryCode, // 카테고리 코드 string
                //     machineCheckCode: data.machineCheckCode, // 추가정보 코드 string
                //     machineCheckName: data.machineCheckName, // 추가정보명 string - 왼쪽
                //     machineCheckAnswer: data.machineCheckAnswer, // 추가정보 답변 string - 오른쪽
                // })),
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading];
};

// 장비 이력 등록
const useEquipmentHistoryRegistration = (config: EquipmentHistoryRegisterInput): [() => Promise<void>, boolean] => {
    const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(false);
    const request = useMemo(() => {
        return httpRequest<EquipmentHistoryRegisterInput, null>({
            method: "POST",
            url: "/machine/hc",
            data: config,
        });
    }, [config]);

    const historySubmit = async () => {
        if (
            config.machineHistoryDate !== 0 &&
            Number.isNaN(config.machineHistoryDate) === false &&
            config.machineHistoryContent.trim().length > 0 &&
            config.machineHistoryContent !== ""
        ) {
            if (window.confirm("장비이력을 등록하시겠습니까?")) {
                try {
                    setIsLoadingHistory(true);
                    await request.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    setIsLoadingHistory(false);
                    alert("등록되었습니다");
                }
            } else {
                setIsLoadingHistory(true);
            }
        } else {
            alert("필수항목을 입력해주세요.");
            setIsLoadingHistory(true);
        }
    };

    return [historySubmit, isLoadingHistory];
};

// 장비 이력 수정
const useEquipmentHistoryEdit = (config: any, setIsHistoryEdit: any): [() => Promise<void>, boolean] => {
    const [isLoadingHistoryEdit, setIsLoadingHistoryEdit] = useState<boolean>(false);
    const request = useMemo(() => {
        return httpRequest<any, null>({
            method: "PUT",
            url: "/machine/hu",
            data: config,
        });
    }, [config]);

    const historySubmit = async () => {
        if (config.machineHistoryDate !== null && config.machineHistoryContent.trim().length > 0) {
            if (window.confirm("장비이력을 수정하시겠습니까?")) {
                try {
                    setIsLoadingHistoryEdit(true);
                    setIsHistoryEdit(false);
                    await request.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    setIsLoadingHistoryEdit(false);
                    alert("수정되었습니다");
                }
            } else {
                setIsHistoryEdit(false);
                setIsLoadingHistoryEdit(true);
            }
        } else {
            alert("필수항목을 입력해주세요.");
        }
    };

    return [historySubmit, isLoadingHistoryEdit];
};

// 장비 이력 삭제
const useEquipmentHistoryDelete = (
    machineHistoryId: number,
    setHistoryId: Dispatch<SetStateAction<number | any>>,
): [() => Promise<void>, boolean] => {
    const [isLoadingDeleteHistory, setIsLoadingDeleteHistory] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<{machineHistoryId: number}, null>({
            method: "DELETE",
            url: "/machine/hd",
            data: {
                machineHistoryId: machineHistoryId,
            },
        });
    }, [machineHistoryId]);

    const historyDeleteSubmit = async () => {
        if (machineHistoryId !== null && machineHistoryId !== undefined) {
            if (window.confirm("삭제하시겠습니까?")) {
                try {
                    setIsLoadingDeleteHistory(true);
                    await request.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    setIsLoadingDeleteHistory(false);
                    setHistoryId(null);
                    alert("삭제되었습니다");
                }
            } else {
                setIsLoadingDeleteHistory(true);
                setHistoryId(null);
            }
        }
    };

    return [historyDeleteSubmit, isLoadingDeleteHistory];
};

export {
    useEquipmentCheckDTOList,
    useEquipmentHistoryDelete,
    useEquipmentHistoryEdit,
    useEquipmentHistoryRegistration,
    useEquipmentRegisterTargetList,
    useEquipmentRegistration,
};

import {useMemo, useState} from "react";
import dayjs from "dayjs";

import {httpRequest} from "~/fetch/common/http-request";

import {CostTrainerFundRequest, CostTrainerFundResponse} from "./cost-trainer-fund.interface";

const useCostTrainerFund = (config: CostTrainerFundRequest): [() => Promise<void>, boolean, any] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const {
            year = dayjs(new Date()).format("YYYY"),
            month = dayjs(new Date()).format("MM"),
            trainerSectorId,
            trainerReserveFund = 0,
        } = config;
        return httpRequest<CostTrainerFundRequest, CostTrainerFundResponse>({
            method: "POST",
            url: "/lotte/cost/repair/trainer/fundUpsert",
            headers: {
                "Content-type": "application/json",
            },
            data: {
                year,
                month,
                trainerReserveFund,
                trainerSectorId,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            await request.submit();
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return [submit, isLoading, error];
};

export {useCostTrainerFund};

import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {CostTypeListRequest, CostTypeListResponse} from "./use-cost-filter.interface";

interface OptionsType {
    label: string;
    value: string;
}

// 유형, 공정 리스트
const useCostFilterTypeList = (): [
    () => Promise<void>,
    {typeList: OptionsType[]; processList: OptionsType[]},
    {
        typeList: CostTypeListResponse | null;
        processList: CostTypeListResponse | null;
    },
    any,
] => {
    const [response, setResponse] = useState<{
        typeList: CostTypeListResponse | null;
        processList: CostTypeListResponse | null;
    }>({
        typeList: null,
        processList: null,
    });
    const [error, setError] = useState<any>(null);
    const [options, setOptions] = useState<{typeList: OptionsType[]; processList: OptionsType[]}>({
        typeList: [],
        processList: [],
    });

    const request = useMemo(() => {
        return (code: string) => {
            return httpRequest<CostTypeListRequest, CostTypeListResponse>({
                method: "GET",
                url: "/common/detailCodes",
                params: {
                    groupCd: code,
                },
            });
        };
    }, []);

    const submit = async () => {
        try {
            const responseOfTypeList = await request("LC028").submit();
            const responseOfProcessList = await request("LC001").submit();
            setResponse({typeList: responseOfTypeList.data, processList: responseOfProcessList.data});
            setOptions({
                typeList: responseOfTypeList.data.map((data) => ({
                    label: data.description,
                    value: data.code,
                })),
                processList: responseOfProcessList.data.map((data) => ({
                    label: data.description,
                    value: data.code,
                })),
            });
        } catch (e) {
            setError(e);
        }
    };
    return [submit, options, response, error];
};

export {useCostFilterTypeList};

//TODO
/*
    ?groupCd
    LC001 공정리스트
    LC028 유형리스트
*/

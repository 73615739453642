import {useMemo, useState} from "react";
import {UploadFile} from "antd";
import Swal from "sweetalert2";

import {MergeRegisterFileUploadResponse} from "~/data/merge/register/register.interface";
import {httpRequest} from "~/fetch/common/http-request";

import {
    MergeAllCompleteRequest,
    MergeDetailRequest,
    MergeDetailResponse,
    MergeDownload,
    MergeDownloadRequest,
    MergeDownloadResponse,
    UpdateDetail,
    UpdateDetailRequest,
    UpdateDetailResponse,
} from "./detail.interface";

/* --------------------------------- 취합 상세정보 -------------------------------- */
const useMergeDetail = (
    config: MergeDetailRequest,
): [() => Promise<void>, MergeDetailResponse | null, boolean, any] => {
    const [response, setResponse] = useState<MergeDetailResponse | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const {noticeMergeId} = config;
        return httpRequest<MergeDetailRequest, MergeDetailResponse>({
            method: "GET",
            url: "/lotte/merge/detail",
            params: {
                noticeMergeId,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const response = await request.submit();
            setResponse(response.data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

/* -------------------------------- 취합 게시물 삭제 ------------------------------- */
const useMergeDelete = (noticeMergeId: number): [() => Promise<void>, boolean] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<{noticeMergeId: number}, undefined>({
            method: "PUT",
            url: "/lotte/merge/member/del",
            data: {
                noticeMergeId,
            },
        });
    }, [noticeMergeId]);

    const submit = async () => {
        try {
            setIsLoading(true);
            await request.submit();
        } catch (e) {
            console.log(e);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, isLoading];
};

/* ------------------------------- 취합 상세 일괄마감 ------------------------------- */
const useMergeAllComplete = (config: MergeAllCompleteRequest): [() => Promise<void>, boolean, any] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const {noticeMergeId, buildings} = config;
        return httpRequest<MergeAllCompleteRequest, null>({
            method: "POST",
            url: "/lotte/merge/member/allComplete",
            data: {
                noticeMergeId,
                buildings,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            await request.submit();
        } catch (e) {
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return [submit, isLoading, error];
};

/* ------------------------------- // 취합상세 수정 ------------------------------- */
const useMergeUpdateDetail = (config: {
    info: UpdateDetail;
    files: {main: File | null; sub: UploadFile[]};
}): [(files: {main: File | null; sub: UploadFile[]}) => Promise<void>, boolean, any] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return (fileInfo: MergeRegisterFileUploadResponse) => {
            const {
                noticeMergeId,
                title,
                content,
                startDate,
                endDate,
                managerNm,
                targetType,
                important,
                formFileOriginName = null,
                formFileStorePath = null,
                fileSheetNames = null,
                fileFreezePaneCounts = null,
                completeDTOList,
                removalFileIds,
            } = config.info;

            return httpRequest<UpdateDetailRequest, UpdateDetailResponse>({
                method: "PUT",
                url: "/lotte/merge/member/upd",
                data: {
                    noticeMergeId,
                    title,
                    content,
                    startDate,
                    endDate,
                    managerNm,
                    targetType,
                    important,
                    formFileOriginName: config.files.main ? fileInfo.excelFileInfo.fileOriginName : formFileOriginName,
                    formFileStorePath: config.files.main ? fileInfo.excelFileInfo.fileStorePath : formFileStorePath,
                    fileFreezePaneCounts,
                    fileSheetNames,
                    completeDTOList,
                    removalFileIds,
                    addFileDTOList: config.files.sub.length ? fileInfo.addFileDTOList : [],
                },
            });
        };
    }, [config]);

    const fileUpload = useMemo(() => {
        const formData = new FormData();
        const toFile = (uploadFile: UploadFile): File => {
            return (uploadFile.originFileObj as File) || null;
        };
        const convertedFiles = config.files.sub.map((data) => toFile(data));
        convertedFiles.forEach((file) => {
            formData.append(`addFiles`, file);
        });
        if (config.files.main) {
            formData.append("excelFile", config.files.main);
        }

        return httpRequest<FormData, MergeRegisterFileUploadResponse>({
            method: "POST",
            url: "/lotte/merge/member/fileUpload",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });
    }, [config.files]);

    const submit = async (files: {main: File | null; sub: UploadFile[]}) => {
        try {
            setIsLoading(true);

            if (files.main || files.sub.length) {
                const fileInfo = await fileUpload.submit();
                const result = await request(fileInfo.data).submit();
                if (result.data.responseData === "FAIL") {
                    Swal.fire({
                        text: "오류가 발생하였습니다. 다시 시도해주세요",
                        confirmButtonText: "확인",
                    });
                }
            } else {
                const result = await request({
                    excelFileInfo: {
                        fileOriginName: config.info.formFileOriginName ?? "",
                        fileStorePath: config.info.formFileStorePath ?? "",
                    },
                    addFileDTOList: [],
                }).submit();
                if (result.data.responseData === "FAIL") {
                    Swal.fire({
                        text: "오류가 발생하였습니다. 다시 시도해주세요",
                        confirmButtonText: "확인",
                    });
                }
            }
        } catch (e) {
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, isLoading, error];
};

/* ------------------------------- 취합 파일 다운로드 (파일 취합) ------------------------------- */
const useMergeDownload = (config: MergeDownload): [() => Promise<void>] => {
    const request = useMemo(() => {
        const {noticeMergeId} = config;
        if (config.mergeType === "FILE") {
            return httpRequest<MergeDownloadRequest, Blob>({
                method: "POST",
                url: "/lotte/merge/member/mergeDownload",
                data: {
                    noticeMergeId,
                },
                responseType: "blob",
            });
        } else {
            return httpRequest<MergeDownloadRequest, Blob>({
                method: "GET",
                url: "/lotte/merge/excel",
                params: {
                    noticeMergeId,
                },
                responseType: "blob",
            });
        }
    }, [config]);

    const check = useMemo(() => {
        const {noticeMergeId} = config;

        return httpRequest<{noticeMergeId: number}, MergeDownloadResponse>({
            method: "GET",
            url: "/lotte/merge/member/failedList",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            params: {
                noticeMergeId,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            const response = await request.submit();
            const blob = new Blob([response.data], {type: response.headers["content-type"]});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // 파일명 추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";

            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = decodeURIComponent(matches[1].trim().replace(/['"]/g, ""));

                    fileName = decodeURIComponent(fileName.replace(/\+/g, " "));
                }
            }

            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            if (config.mergeType === "FILE") {
                const failedList = await check.submit();

                if (failedList.data.responseData.length) {
                    Swal.fire({
                        title: "취합에 실패한 지점이 있습니다.",
                        text: failedList.data.responseData.map((data) => data.name).join(", "),
                    });
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return [submit];
};

/* ----------------------------- 완료지점 첨부파일 다운로드 ---------------------------- */
const useMergeDownloadBuilding = (): [
    (noticeMergeFileId: string, year: string, month: string) => Promise<void>,
    any,
] => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return (noticeMergeFileId: string, year: string, month: string) => {
            return httpRequest<{noticeMergeFileId: string; year: string; month: string}, Blob>({
                method: "GET",
                url: "/lotte/merge/download",
                params: {
                    year,
                    month,
                    noticeMergeFileId,
                },
                responseType: "blob",
            });
        };
    }, []);

    const submit = async (noticeMergeFileId: string, year: string, month: string) => {
        try {
            const response = await request(noticeMergeFileId, year, month).submit();
            const blob = new Blob([response.data], {type: response.headers["content-type"]});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // 파일명 추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";

            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = decodeURIComponent(matches[1].trim().replace(/['"]/g, ""));

                    fileName = decodeURIComponent(fileName.replace(/\+/g, " "));
                }
            }

            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.log(e);
            setError(e);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        }
    };
    return [submit, error];
};

/* ----------------------------- 취합파일 첨부 양식 다운로드 ---------------------------- */
const useMergeFormDownload = (noticeMergeId: number) => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<{noticeMergeId: number}, Blob>({
            method: "GET",
            url: "/lotte/merge/downloadForm",
            params: {
                noticeMergeId,
            },
            responseType: "blob",
        });
    }, [noticeMergeId]);

    const submit = async () => {
        try {
            const response = await request.submit();
            const blob = new Blob([response.data], {type: response.headers["content-type"]});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // 파일명 추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";

            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = decodeURIComponent(matches[1].trim().replace(/['"]/g, ""));

                    fileName = decodeURIComponent(fileName.replace(/\+/g, " "));
                }
            }

            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (e) {
            setError(e);
            console.log(e);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        }
    };

    return [submit, error];
};

export {
    useMergeAllComplete,
    useMergeDelete,
    useMergeDetail,
    useMergeDownload,
    useMergeDownloadBuilding,
    useMergeFormDownload,
    useMergeUpdateDetail,
};

import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import Button from "~/components/button";
import {CnRangePicker} from "~/components/cn-range-picker";
import {CnSelect} from "~/components/cn-select";
import {CnTable} from "~/components/cn-table/cn-table";
import {FilterArea} from "~/components/filter-area";
import {PageTitle} from "~/components/page-title";
import {useReport} from "~/data/check/report/use-report";
import {useUser} from "~/data/user";
import {ReportParam} from "~/pages/check/report/report.interface";

import styles from "./report.page.module.scss";

const cx = classNames.bind(styles);

const ReportPage = () => {
    const perPage = 50;
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState<"SUCCESS" | "ING" | "BEFORE" | null>(null);
    const [document, setDocument] = useState<HTMLElement | null>(null);
    const [documentWidth, setDocumentWidth] = useState<string>("");

    const {type} = useParams<ReportParam>();

    const title = useMemo(() => {
        if (type === "legal") return "법정점검";
        else if (type === "checklist") return "체크리스트";
        else if (type === "extra") return "추가점검";
        else if (type === "normal") return "일반점검";
    }, [type]);

    useEffect(() => {
        setDateRange([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
        setPage(1);
        setStatus(null);
        setDocument(null);
    }, [type]);

    const {user} = useUser();

    const {list, totalCount, loading, refresh, getReport, downloadReport} = useReport(
        type as string,
        user!.division === "MANAGER" ? user!.buildingId : null,
        page,
        perPage,
        dateRange[0].format("YYYY-MM-DD"),
        dateRange[1].format("YYYY-MM-DD"),
        status as string,
    );

    const handleResetFilter = () => {
        setPage(1);
        setDateRange([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
    };

    const handleSubmit = () => {
        setPage(1);
        refresh();
    };

    const columns: ColumnsType = useMemo(() => {
        const cols: ColumnsType = [
            {
                title: "No",
                key: "index",
                render: (_, __, index) => Number((page - 1) * perPage + index + 1).toLocaleString(),
                align: "center",
            },
            {
                title: "지점",
                dataIndex: "buildingName",
                key: "buildingName",
            },
            {
                title: "일시",
                dataIndex: "orderDate",
                key: "orderDate",
            },
            {
                title: type === "legal" || type === "checklist" ? "보고서 종류" : "점검명",
                dataIndex: "checkFormName",
                key: "checkFormName",
            },
            {
                title: "구역",
                dataIndex: "regionName",
                key: "regionName",
                align: "center",
            },
            {
                title: "점검자",
                dataIndex: "employeeName",
                key: "employeeName",
                align: "center",
            },
            {
                title: "상태",
                dataIndex: "result",
                key: "result",
                align: "center",
                render: (value) => (value === "SUCCESS" ? "완료" : value === "ING" ? "진행중" : "점검전"),
            },
            {
                title: "내려받기",
                dataIndex: "result",
                key: "result",
                align: "center",
                render: (_, row) =>
                    row.result === "SUCCESS" && (
                        <Button
                            label="내려받기"
                            size="small"
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                downloadReport(
                                    type === "extra" || type === "normal" || type === "checklist"
                                        ? row.checkFormId
                                        : row.reportListId,
                                    row.orderDate,
                                    type === "extra" || type === "normal" || type === "checklist",
                                );
                            }}
                        />
                    ),
            },
        ];

        return cols;
    }, [type]);

    return (
        <div>
            {/*{document && (*/}
            {/*    <div*/}
            {/*        className={cx("document")}*/}
            {/*        onClick={() => {*/}
            {/*            setDocument(null);*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <iframe*/}
            {/*            srcDoc={String(document?.innerHTML)}*/}
            {/*            onClick={(e) => {*/}
            {/*                e.preventDefault();*/}
            {/*                e.stopPropagation();*/}
            {/*            }}*/}
            {/*            style={{*/}
            {/*                width: `calc(${documentWidth} + 20px)`,*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*)}*/}

            <PageTitle>{title + " 점검이력"}</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnRangePicker
                        label={"기간"}
                        value={dateRange}
                        onChange={(value) => setDateRange(value)}
                        allowClear={false}
                    />
                    <CnSelect
                        label={"상태"}
                        showSearch
                        placeholder="상태"
                        onChange={(value) => setStatus(value)}
                        value={status}
                        options={[
                            {
                                value: null,
                                label: "전체",
                            },
                            {
                                value: "SUCCESS",
                                label: "완료",
                            },
                            {
                                value: "ING",
                                label: "진행중",
                            },
                            {
                                value: "BEFORE",
                                label: "점검전",
                            },
                        ]}
                    />
                    <div className={cx("button-container")}>
                        <Button onClick={handleSubmit} label={"조회"} color={"primary"} />
                        <Button onClick={handleResetFilter} label={"검색조건 초기화"} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CnTable
                    totalCount={totalCount}
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    page={page}
                    dataSource={list.map((row: any) => {
                        row["key"] = JSON.stringify(row);
                        return row;
                    })}
                    pagination={false}
                    loading={loading}
                    columns={columns}
                    onRow={(row) => ({
                        onClick: () => {
                            // if (type === "legal" || type === "checklist") {
                            //     getReport(row.reportListId, row.orderDate).then((res) => {
                            //         const domparser = new DOMParser();
                            //         const doc = domparser.parseFromString(res.data.trim(), "text/html");
                            //         setDocumentWidth(
                            //             res.data.indexOf("width: 209.97mm;") > 0 ? "209.97mm" : "409.97mm",
                            //         );
                            //         setDocument(doc.body);
                            //     });
                            // }
                        },
                    })}
                />
            </div>
        </div>
    );
};

export {ReportPage};

import {useEffect, useState} from "react";
import {ConfigProvider, DatePicker, Input, InputNumber, InputNumberProps} from "antd";
import {valueType} from "antd/es/statistic/utils";
import locale from "antd/lib/locale/ko_KR";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import {CostRepairApplyDTOListType} from "~/data/cost/maintenance-lighting/modal/maintenance.modal.interface";
import {formatCost} from "~/utills/formatCost";

import {CostMaintenanceTableRowProps} from "./cost-maintenance-table.interface";

import styles from "./cost-maintenance-table.module.scss";

import "dayjs/locale/ko";

const cx = classNames.bind(styles);

const CostMaintenanceTableRow = ({
    data,
    index,
    division,
    userId,
    setTableData,
    page,
    perPage,
}: CostMaintenanceTableRowProps) => {
    const [localTableData, setLocalTableData] = useState<CostRepairApplyDTOListType>({
        ...data,
    });

    dayjs.locale("ko");

    const inputProps: InputNumberProps = {
        controls: false,
        style: {width: "100%"},
        formatter: (value) => formatCost(value),
    };
    useEffect(() => {
        setLocalTableData({...data, applyUserId: userId, replectUserId: userId, updateUser: userId});
    }, [data]);

    const handleInputChange = (field: keyof CostRepairApplyDTOListType, value: valueType | null | Dayjs) => {
        setLocalTableData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };
    // 수선비품 / 등기구 값에 따라 금액 값 변경
    useEffect(() => {
        setLocalTableData((prevData) => ({
            ...prevData,
            applyCost: localTableData.applyCostLight + localTableData.applyCostPart,
            replectCost: localTableData.replectCostLight + localTableData.replectCostPart,
        }));
    }, [
        localTableData.applyCostLight,
        localTableData.applyCostPart,
        localTableData.replectCostLight,
        localTableData.replectCostPart,
    ]);

    // 입력한 값 테이블에 병합
    useEffect(() => {
        setTableData((prevData) => {
            const newData = [...prevData];
            newData[index] = localTableData;
            return newData;
        });
    }, [localTableData, setTableData]);

    return (
        <div className={cx("table-row")}>
            <div>{index + 1 + (page - 1) * perPage}</div>
            <div>{data.buildingName}</div>
            <div>{data.sectorName}</div>
            <div className={cx("input-cell", {cost: true})}>
                {division === "MEMBER" ? (
                    <InputNumber
                        value={localTableData ? localTableData.costRepairGoal : 0}
                        {...inputProps}
                        onChange={(value) => handleInputChange("costRepairGoal", value)}
                    />
                ) : (
                    data.costRepairGoal.toLocaleString()
                )}
            </div>
            <div className={cx("double-cell")}>
                <div className={cx("extra-cell")}>신청</div>
                <div className={cx("extra-cell")}>정산</div>
            </div>
            <div className={cx("double-cell")}>
                <div className={cx("extra-cell")}>
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            className={cx("datepicker")}
                            value={
                                localTableData.applyCalculateDate
                                    ? dayjs(new Date(localTableData.applyCalculateDate))
                                    : null
                            }
                            onChange={(value) =>
                                handleInputChange("applyCalculateDate", value ? value.valueOf() : null)
                            }
                            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                        />
                    </ConfigProvider>
                </div>
                <div className={cx("extra-cell")}>
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            className={cx("datepicker")}
                            value={
                                localTableData.replectCalculateDate
                                    ? dayjs(new Date(localTableData.replectCalculateDate))
                                    : null
                            }
                            onChange={(value) =>
                                handleInputChange("replectCalculateDate", value ? value.valueOf() : null)
                            }
                            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                        />
                    </ConfigProvider>
                </div>
            </div>
            <div className={cx("double-cell")}>
                <div className={cx("extra-cell")}>
                    <InputNumber
                        value={localTableData ? localTableData.applyCost : 0}
                        {...inputProps}
                        onChange={(value) => handleInputChange("applyCost", value)}
                        disabled
                        className={cx("cost-disabled")}
                    />
                </div>
                <div className={cx("extra-cell", {cost: true})}>
                    <InputNumber
                        value={localTableData ? localTableData.replectCost : 0}
                        {...inputProps}
                        onChange={(value) => handleInputChange("replectCost", value)}
                        disabled
                        className={cx("cost-disabled")}
                    />
                </div>
            </div>
            <div className={cx("double-cell")}>
                <div className={cx("extra-cell")}>
                    <InputNumber
                        value={localTableData ? localTableData.applyCostLight : 0}
                        {...inputProps}
                        onChange={(value) => handleInputChange("applyCostLight", value)}
                    />
                </div>
                <div className={cx("extra-cell", {cost: true})}>
                    <InputNumber
                        value={localTableData ? localTableData.replectCostLight : 0}
                        {...inputProps}
                        onChange={(value) => handleInputChange("replectCostLight", value)}
                    />
                </div>
            </div>
            <div className={cx("double-cell")}>
                <div className={cx("extra-cell")}>
                    <InputNumber
                        value={localTableData ? localTableData.applyCostPart : 0}
                        {...inputProps}
                        onChange={(value) => handleInputChange("applyCostPart", value)}
                    />
                </div>
                <div className={cx("extra-cell", {cost: true})}>
                    <InputNumber
                        value={localTableData ? localTableData.replectCostPart : 0}
                        {...inputProps}
                        onChange={(value) => handleInputChange("replectCostPart", value)}
                    />
                </div>
            </div>
            <div className={cx("double-cell", {text: true})}>
                <div className={cx("extra-cell")}>
                    <Input.TextArea
                        className={cx("text-area")}
                        value={localTableData ? localTableData.applyMemo ?? "" : ""}
                        onChange={(e) => handleInputChange("applyMemo", e.target.value)}
                    />
                </div>
                <div className={cx("extra-cell")}>
                    <Input.TextArea
                        className={cx("text-area")}
                        value={localTableData ? localTableData.replectMemo ?? "" : ""}
                        onChange={(e) => handleInputChange("replectMemo", e.target.value)}
                    />
                    {/* <Input.TextArea
                            className={cx("text-area")}
                            // value={localTableData ? localTableData.replectMemo ?? "" : ""}
                            value={localTableData.replectMemo ? localTableData.replectMemo : ""}
                            disabled
                            style={{color: "black"}}
                        /> */}
                </div>
            </div>
        </div>
    );
};

export {CostMaintenanceTableRow};

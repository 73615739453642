import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {CostMonthlyList, CostMonthlyListRequest, CostMonthlyListResponse} from "./cost-monthly.interface";

const useCostMonthlyList = (
    config: CostMonthlyList,
): [() => Promise<void>, CostMonthlyListResponse | null, boolean, any] => {
    const [response, setResponse] = useState<CostMonthlyListResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const refinedData = () => {
            if (config.selected.length) {
                switch (config.selected[0]) {
                    case "ALL":
                        return null;
                    // break;
                    case "SECTOR":
                        return {
                            sectorId: config.selected[1],
                            ...(config.selected[2] !== "0" && {buildingId: config.selected[2]}),
                        };
                    // break;
                    case "TRAINER":
                        return {
                            trainerSectorId: config.selected[1],
                            ...(config.selected[2] !== "0" && {buildingId: config.selected[2]}),
                        };
                    // break;
                    default:
                        return null;
                    // break;
                }
            }
        };

        return httpRequest<CostMonthlyListRequest, CostMonthlyListResponse>({
            method: "GET",
            url: "/lotte/cost/facility/month/plans",
            params: {
                year: config.year && config.year.format("YYYY"),
                month: config.month ? config.month.format("MM") : null,
                quaters: config.quarters ? config.quarters.format("Q") : null,
                yearProcessId: config.yearProcessId === "ALL" ? null : config.yearProcessId,
                state: config.state === "ALL" ? null : config.state,
                startCost: config.startCost,
                endCost: config.endCost,
                yearConstructName: config.yearConstructName === "" ? null : config.yearConstructName,
                pageNo: config.page,
                recordPerPage: config.perPage,
                ...refinedData(),
                budgetDivision: 1,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return [submit, response, isLoading, error];
};

export {useCostMonthlyList};

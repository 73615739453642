import React from "react";
import {Pagination, Table} from "antd";
import classNames from "classnames/bind";

import {CnTableProps} from "./cn-table.interface";

import styles from "./cn-table.module.scss";

const cx = classNames.bind(styles);

const CnTable = ({totalCount, perPage, page, onPageChange, ...props}: CnTableProps) => {
    const handlePaginationChange = (page: number) => {
        onPageChange(page);
    };
    return (
        <div className={cx("container")}>
            <div className={cx("count")}>
                {totalCount?.toLocaleString()}건 ({page?.toLocaleString()}/
                {Math.ceil(totalCount / perPage)?.toLocaleString()} 페이지)
            </div>
            <Table {...props} className={cx("table")} />
            <div className={cx("pagination-container")}>
                <Pagination
                    current={page}
                    total={totalCount}
                    pageSize={perPage}
                    showSizeChanger={false}
                    onChange={handlePaginationChange}
                />
            </div>
        </div>
    );
};

export {CnTable};

import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import {DatePicker, Input, InputNumber, Radio, Table} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import classNames from "classnames/bind";

import Button from "~/components/button/button";
import {EquipmentHistoryRegisterInput} from "~/data/machine/register/register.interface";
import {httpRequest} from "~/fetch/common/http-request";

import styles from "./history-submit.module.scss";

const cx = classNames.bind(styles);

const MobileEquipmentHistorySubmitPage = () => {
    const navigate = useNavigate();
    const {companyId, buildingId, machineId} = useParams<{
        companyId: string;
        buildingId: string;
        machineId: string;
    }>();

    // 설비 이력 값
    const [historyData, setHistoryData] = useState<any>({
        machineId: machineId,
        companyId: companyId,
        buildingId: buildingId,
        machineHistoryDate: 0, // 날짜
        machineHistoryCost: 0, // 비용
        machineHistoryContent: "", // 업무내역
        machineHistoryNote: "", // 비고
        type: "self", // 구분 (기본값 self로 지정)
        isManual: "Y", // 수동유무 (기본값 Y로 지정)
        isDel: "N", // 삭제유무
    });

    // 설비 이력 등록
    const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(false);
    const request = useMemo(() => {
        return httpRequest<EquipmentHistoryRegisterInput, null>({
            method: "POST",
            url: "/machine/hc",
            data: historyData,
        });
    }, [historyData]);

    const historySubmit = async () => {
        if (
            historyData.machineHistoryDate !== 0 &&
            Number.isNaN(historyData.machineHistoryDate) === false &&
            historyData.machineHistoryContent.trim().length > 0 &&
            historyData.machineHistoryContent !== ""
        ) {
            if (window.confirm("장비이력을 등록하시겠습니까?")) {
                try {
                    setIsLoadingHistory(true);
                    await request.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    setIsLoadingHistory(false);
                    alert("등록되었습니다");
                    navigate(-1);
                }
            } else {
                setIsLoadingHistory(true);
            }
        } else {
            alert("필수항목을 입력해주세요.");
        }
    };

    return (
        <div>
            <div className={cx("inner-container")}>
                <h1 className={cx("title")}>
                    설비 이력 등록하기{" "}
                    <span style={{color: "#d9534f", marginLeft: "8px", fontSize: "14px"}}>* 필수항목</span>
                </h1>
                <CloseOutlined
                    className={cx("close-button")}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            </div>

            <ul className={cx("list")}>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        날짜
                        <span style={{color: "#d9534f", marginLeft: "4px", fontSize: "14px"}}>*</span>
                    </label>
                    <DatePicker
                        className={cx("field")}
                        locale={locale}
                        allowClear={false}
                        inputReadOnly
                        onChange={(date, dateString: any) => {
                            setHistoryData({
                                ...historyData,
                                machineHistoryDate: new Date(dateString).getTime(),
                            });
                        }}
                        placeholder="년/월/일"
                        style={{width: "100%"}}
                        format="YYYY.MM.DD"
                    />
                </li>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        비용
                    </label>
                    <InputNumber
                        className={cx("field")}
                        type="number"
                        name="machineHistoryCost"
                        onChange={(e: any) => {
                            setHistoryData({
                                ...historyData,
                                machineHistoryCost: e,
                            });
                        }}
                        value={historyData.machineHistoryCost}
                    />
                </li>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        구분
                    </label>
                    <Radio.Group
                        onChange={(e) => {
                            setHistoryData({
                                ...historyData,
                                type: e.target.value,
                            });
                        }}
                        value={historyData.type}
                    >
                        <Radio value={"self"}>자체개선</Radio>
                        <Radio value={"construction"}>실재공사</Radio>
                    </Radio.Group>
                </li>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        업무내역<span style={{color: "#d9534f", marginLeft: "4px", fontSize: "14px"}}>*</span>
                    </label>
                    <Input
                        className={cx("field")}
                        name="machineHistoryContent"
                        onChange={(e: any) => {
                            setHistoryData({
                                ...historyData,
                                machineHistoryContent: e.target.value,
                            });
                        }}
                        value={historyData.machineHistoryContent}
                    />
                </li>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        비고
                    </label>
                    <Input
                        className={cx("field")}
                        name="machineHistoryNote"
                        onChange={(e: any) => {
                            setHistoryData({
                                ...historyData,
                                machineHistoryNote: e.target.value,
                            });
                        }}
                        value={historyData.machineHistoryNote}
                    />
                </li>
            </ul>
            <Button label="등록하기" size="large" fullWidth onClick={historySubmit} />
        </div>
    );
};

export {MobileEquipmentHistorySubmitPage};

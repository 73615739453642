import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {ResponsiveSankey} from "@nivo/sankey";
import {ResponsiveSunburst} from "@nivo/sunburst";
import classNames from "classnames/bind";

import {useUser} from "~/data/user";
import {MobileDashboardPage} from "~/pages/dashboard/mobile-dashboard.page";
import {usePlatform} from "~/utills/use-platform";

import styles from "./dashboard.module.scss";

const cx = classNames.bind(styles);

const DashboardPage = () => {
    const {platform} = usePlatform();

    const {user} = useUser();

    if (platform === "mobile") return user ? <MobileDashboardPage /> : null;
    else
        return (
            <div className={cx("contents")}>
                <div className={cx("row")}>
                    <div className={cx("card", "w45p")}>
                        <h2 className={cx("card-title")}>오늘의 점검현황</h2>
                        <div className={cx("check")}>
                            <div className={cx("check-chart")}>
                                <ResponsiveSunburst
                                    data={{
                                        name: "점검현황",
                                        color: "hsl(139,59%,45%)",
                                        children: [
                                            {
                                                name: "완료",
                                                color: "hsl(324,70%,50%)",
                                                children: [
                                                    {
                                                        name: "냉동제조시설 안전점검표",
                                                        color: "hsl(73, 70%, 50%)",
                                                        loc: 79,
                                                    },
                                                    {
                                                        name: "도시가스 일일 점검일지(GAS사용업소)",
                                                        color: "hsl(264, 70%, 50%)",
                                                        loc: 84,
                                                    },
                                                    {
                                                        name: "도시가스 일일 점검일지(기계실)",
                                                        color: "hsl(239, 70%, 50%)",
                                                        loc: 69,
                                                    },
                                                    {
                                                        name: "도시가스 일일 점검일지(정압기실)",
                                                        color: "hsl(298, 70%, 50%)",
                                                        loc: 63,
                                                    },
                                                    {
                                                        name: "방재 업무 일지",
                                                        color: "hsl(239, 70%, 50%)",
                                                        loc: 88,
                                                    },
                                                    {
                                                        name: "수변전설비점검일지",
                                                        color: "hsl(298, 70%, 50%)",
                                                        loc: 15,
                                                    },
                                                    {
                                                        name: "수변전설비점검일지2",
                                                        color: "hsl(298, 70%, 50%)",
                                                        loc: 2,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "진행중",
                                                color: "hsl(3, 70%, 50%)",
                                                children: [
                                                    {
                                                        name: "방재 업무 일지",
                                                        color: "hsl(239, 70%, 50%)",
                                                        loc: 1,
                                                    },
                                                    {
                                                        name: "수변전설비점검일지",
                                                        color: "hsl(298, 70%, 50%)",
                                                        loc: 70,
                                                    },
                                                    {
                                                        name: "수변전설비점검일지2",
                                                        color: "hsl(298, 70%, 50%)",
                                                        loc: 8,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "점검전",
                                                color: "hsl(338, 70%, 50%)",
                                                children: [
                                                    {
                                                        name: "냉동제조시설 안전점검표",
                                                        color: "hsl(73, 70%, 50%)",
                                                        loc: 18,
                                                    },
                                                    {
                                                        name: "도시가스 일일 점검일지(GAS사용업소)",
                                                        color: "hsl(264, 70%, 50%)",
                                                        loc: 16,
                                                    },
                                                    {
                                                        name: "도시가스 일일 점검일지(기계실)",
                                                        color: "hsl(239, 70%, 50%)",
                                                        loc: 14,
                                                    },
                                                    {
                                                        name: "도시가스 일일 점검일지(정압기실)",
                                                        color: "hsl(298, 70%, 50%)",
                                                        loc: 11,
                                                    },
                                                    {
                                                        name: "방재 업무 일지",
                                                        color: "hsl(239, 70%, 50%)",
                                                        loc: 18,
                                                    },
                                                    {
                                                        name: "수변전설비점검일지",
                                                        color: "hsl(298, 70%, 50%)",
                                                        loc: 20,
                                                    },
                                                    {
                                                        name: "수변전설비점검일지2",
                                                        color: "hsl(298, 70%, 50%)",
                                                        loc: 5,
                                                    },
                                                ],
                                            },
                                        ],
                                    }}
                                    margin={{top: 10, right: 10, bottom: 10, left: 10}}
                                    id="name"
                                    value="loc"
                                    cornerRadius={10}
                                    arcLabelsRadiusOffset={0.5}
                                    borderColor={{theme: "background"}}
                                    colors={{scheme: "nivo"}}
                                    childColor={{
                                        from: "color",
                                        modifiers: [["brighter", 0.1]],
                                    }}
                                    enableArcLabels={true}
                                    arcLabelsSkipAngle={0}
                                    arcLabelsTextColor={{
                                        from: "color",
                                        modifiers: [["darker", 1.4]],
                                    }}
                                />
                            </div>
                            <div className={cx("check-table")}>
                                <div className={cx("table-container")}>
                                    <table className={cx("table", "fixed")}>
                                        <colgroup>
                                            <col width="10%" />
                                            <col width="45%" />
                                            <col width="15%" />
                                            <col width="15%" />
                                            <col width="15%" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>권역</th>
                                                <th>전체</th>
                                                <th>완료</th>
                                                <th>미완료</th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <table className={cx("table")}>
                                        <colgroup>
                                            <col width="10%" />
                                            <col width="45%" />
                                            <col width="15%" />
                                            <col width="15%" />
                                            <col width="15%" />
                                        </colgroup>
                                        <tbody>
                                            <tr
                                                style={{
                                                    background: `linear-gradient(to right, #e8b9b9 ${(250 / 350) * 100}%, transparent ${(250 / 350) * 100}%)`,
                                                }}
                                            >
                                                <td>1</td>
                                                <td>서울</td>
                                                <td>350</td>
                                                <td>250</td>
                                                <td>100</td>
                                            </tr>
                                            <tr
                                                style={{
                                                    background: `linear-gradient(to right, #e8b9b9 ${(150 / 200) * 100}%, transparent ${(150 / 200) * 100}%)`,
                                                }}
                                            >
                                                <td>2</td>
                                                <td>경기서부</td>
                                                <td>200</td>
                                                <td>150</td>
                                                <td>50</td>
                                            </tr>
                                            <tr
                                                style={{
                                                    background: `linear-gradient(to right, #e8b9b9 ${(160 / 220) * 100}%, transparent ${(160 / 220) * 100}%)`,
                                                }}
                                            >
                                                <td>3</td>
                                                <td>경기남부</td>
                                                <td>220</td>
                                                <td>160</td>
                                                <td>60</td>
                                            </tr>
                                            <tr
                                                style={{
                                                    background: `linear-gradient(to right, #e8b9b9 ${(130 / 180) * 100}%, transparent ${(130 / 180) * 100}%)`,
                                                }}
                                            >
                                                <td>4</td>
                                                <td>경기북부</td>
                                                <td>180</td>
                                                <td>130</td>
                                                <td>50</td>
                                            </tr>
                                            <tr
                                                style={{
                                                    background: `linear-gradient(to right, #e8b9b9 ${(140 / 190) * 100}%, transparent ${(140 / 190) * 100}%)`,
                                                }}
                                            >
                                                <td>5</td>
                                                <td>호남</td>
                                                <td>190</td>
                                                <td>140</td>
                                                <td>50</td>
                                            </tr>
                                            <tr
                                                style={{
                                                    background: `linear-gradient(to right, #e8b9b9 ${(170 / 210) * 100}%, transparent ${(170 / 210) * 100}%)`,
                                                }}
                                            >
                                                <td>6</td>
                                                <td>충청</td>
                                                <td>210</td>
                                                <td>170</td>
                                                <td>40</td>
                                            </tr>
                                            <tr
                                                style={{
                                                    background: `linear-gradient(to right, #e8b9b9 ${(180 / 230) * 100}%, transparent ${(180 / 230) * 100}%)`,
                                                }}
                                            >
                                                <td>7</td>
                                                <td>영남서부</td>
                                                <td>230</td>
                                                <td>180</td>
                                                <td>50</td>
                                            </tr>
                                            <tr
                                                style={{
                                                    background: `linear-gradient(to right, #e8b9b9 ${(190 / 240) * 100}%, transparent ${(190 / 240) * 100}%)`,
                                                }}
                                            >
                                                <td>8</td>
                                                <td>영남동부</td>
                                                <td>240</td>
                                                <td>190</td>
                                                <td>50</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx("card", "w55p")}>
                        <h2 className={cx("card-title")}>승강기점검 현황</h2>
                        <div className={cx("penalty-chart")}>
                            <ResponsiveSankey
                                data={{
                                    nodes: [
                                        {
                                            id: "점검요청됨",
                                        },
                                        {
                                            id: "점검완료",
                                        },
                                        {
                                            id: "1시간이내",
                                        },
                                        {
                                            id: "1시간초과",
                                        },
                                        {
                                            id: "결재완료",
                                        },
                                    ],
                                    links: [
                                        {
                                            source: "점검요청됨",
                                            target: "점검완료",
                                            value: 70,
                                        },
                                        {
                                            source: "점검완료",
                                            target: "1시간이내",
                                            value: 52,
                                        },
                                        {
                                            source: "점검완료",
                                            target: "1시간초과",
                                            value: 25,
                                        },
                                        {
                                            source: "1시간이내",
                                            target: "결재완료",
                                            value: 52,
                                        },
                                        {
                                            source: "1시간초과",
                                            target: "결재완료",
                                            value: 25,
                                        },
                                    ],
                                }}
                                margin={{right: 40, left: 40, top: 20, bottom: 20}}
                                align="justify"
                                colors={{scheme: "category10"}}
                                nodeOpacity={1}
                                nodeHoverOthersOpacity={0.35}
                                nodeThickness={18}
                                nodeSpacing={24}
                                nodeBorderWidth={0}
                                nodeBorderColor={{
                                    from: "color",
                                    modifiers: [["darker", 0.8]],
                                }}
                                nodeBorderRadius={3}
                                linkOpacity={0.5}
                                linkHoverOthersOpacity={0.1}
                                linkContract={3}
                                enableLinkGradient={true}
                                labelPosition="outside"
                                labelOrientation="vertical"
                                labelPadding={16}
                                labelTextColor={{
                                    from: "color",
                                    modifiers: [["darker", 1]],
                                }}
                                legends={[
                                    {
                                        anchor: "bottom-right",
                                        direction: "column",
                                        translateX: 130,
                                        itemWidth: 100,
                                        itemHeight: 14,
                                        itemDirection: "right-to-left",
                                        itemsSpacing: 2,
                                        itemTextColor: "#999",
                                        symbolSize: 14,
                                        effects: [
                                            {
                                                on: "hover",
                                                style: {
                                                    itemTextColor: "#000",
                                                },
                                            },
                                        ],
                                    },
                                ]}
                            />
                        </div>
                        <div className={cx("elevator-table")}>
                            <div className={cx("table-container")}>
                                <table className={cx("table", "fixed")}>
                                    <colgroup>
                                        <col width="6%" />
                                        <col width="20%" />
                                        <col width="10%" />
                                        <col width="32%" />
                                        <col width="32%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>지점명</th>
                                            <th>근무자</th>
                                            <th>고장일시</th>
                                            <th>조치완료시간</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table className={cx("table")}>
                                    <colgroup>
                                        <col width="6%" />
                                        <col width="20%" />
                                        <col width="10%" />
                                        <col width="32%" />
                                        <col width="32%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>평택지점</td>
                                            <td>이택기</td>
                                            <td>2024-06-25 15:03</td>
                                            <td>2024-06-25 17:28</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>VIC신영통점</td>
                                            <td>조명길</td>
                                            <td>2024-06-25 15:03</td>
                                            <td>2024-06-25 17:28</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>경기양평점</td>
                                            <td>이수기</td>
                                            <td>2024-06-25 15:03</td>
                                            <td>2024-06-25 17:28</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>부산온라인센터</td>
                                            <td>최철기</td>
                                            <td>2024-06-25 15:03</td>
                                            <td>2024-06-25 17:28</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>비바건강마켓</td>
                                            <td>박태수</td>
                                            <td>2024-06-25 15:03</td>
                                            <td>2024-06-25 17:28</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>비바건강마켓</td>
                                            <td>박태수</td>
                                            <td>2024-06-25 15:03</td>
                                            <td>2024-06-25 17:28</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx("row")}>
                    <div className={cx("card", "w55p")}>
                        <h2 className={cx("card-title")}>설비현황</h2>
                        <div className={cx("equipment")}>
                            <div className={cx("chart-row")}>
                                <ResponsiveBar
                                    data={[
                                        {
                                            country: "서울",
                                            건축: 31,
                                            건축Color: "hsl(198, 70%, 50%)",
                                            전기: 185,
                                            전기Color: "hsl(108, 70%, 50%)",
                                            기계: 135,
                                            기계Color: "hsl(337, 70%, 50%)",
                                            소방: 150,
                                            소방Color: "hsl(212, 70%, 50%)",
                                            냉동냉장: 58,
                                            냉동냉장Color: "hsl(243, 70%, 50%)",
                                            승강기: 121,
                                            승강기Color: "hsl(137, 70%, 50%)",
                                        },
                                        {
                                            country: "경기서부",
                                            건축: 18,
                                            건축Color: "hsl(271, 70%, 50%)",
                                            전기: 111,
                                            전기Color: "hsl(178, 70%, 50%)",
                                            기계: 52,
                                            기계Color: "hsl(167, 70%, 50%)",
                                            소방: 4,
                                            소방Color: "hsl(266, 70%, 50%)",
                                            냉동냉장: 99,
                                            냉동냉장Color: "hsl(47, 70%, 50%)",
                                            승강기: 63,
                                            승강기Color: "hsl(309, 70%, 50%)",
                                        },
                                        {
                                            country: "경기남부",
                                            건축: 167,
                                            건축Color: "hsl(318, 70%, 50%)",
                                            전기: 87,
                                            전기Color: "hsl(124, 70%, 50%)",
                                            기계: 153,
                                            기계Color: "hsl(260, 70%, 50%)",
                                            소방: 116,
                                            소방Color: "hsl(40, 70%, 50%)",
                                            냉동냉장: 155,
                                            냉동냉장Color: "hsl(294, 70%, 50%)",
                                            승강기: 157,
                                            승강기Color: "hsl(154, 70%, 50%)",
                                        },
                                        {
                                            country: "경기북부",
                                            건축: 103,
                                            건축Color: "hsl(143, 70%, 50%)",
                                            전기: 155,
                                            전기Color: "hsl(37, 70%, 50%)",
                                            기계: 60,
                                            기계Color: "hsl(43, 70%, 50%)",
                                            소방: 194,
                                            소방Color: "hsl(117, 70%, 50%)",
                                            냉동냉장: 16,
                                            냉동냉장Color: "hsl(138, 70%, 50%)",
                                            승강기: 132,
                                            승강기Color: "hsl(94, 70%, 50%)",
                                        },
                                        {
                                            country: "호남",
                                            건축: 22,
                                            건축Color: "hsl(184, 70%, 50%)",
                                            전기: 193,
                                            전기Color: "hsl(80, 70%, 50%)",
                                            기계: 80,
                                            기계Color: "hsl(306, 70%, 50%)",
                                            소방: 135,
                                            소방Color: "hsl(344, 70%, 50%)",
                                            냉동냉장: 34,
                                            냉동냉장Color: "hsl(305, 70%, 50%)",
                                            승강기: 142,
                                            승강기Color: "hsl(177, 70%, 50%)",
                                        },
                                        {
                                            country: "충청",
                                            건축: 19,
                                            건축Color: "hsl(21, 70%, 50%)",
                                            전기: 85,
                                            전기Color: "hsl(274, 70%, 50%)",
                                            기계: 124,
                                            기계Color: "hsl(136, 70%, 50%)",
                                            소방: 126,
                                            소방Color: "hsl(278, 70%, 50%)",
                                            냉동냉장: 158,
                                            냉동냉장Color: "hsl(230, 70%, 50%)",
                                            승강기: 9,
                                            승강기Color: "hsl(161, 70%, 50%)",
                                        },
                                        {
                                            country: "영남서부",
                                            건축: 194,
                                            건축Color: "hsl(309, 70%, 50%)",
                                            전기: 149,
                                            전기Color: "hsl(259, 70%, 50%)",
                                            기계: 137,
                                            기계Color: "hsl(244, 70%, 50%)",
                                            소방: 119,
                                            소방Color: "hsl(162, 70%, 50%)",
                                            냉동냉장: 76,
                                            냉동냉장Color: "hsl(343, 70%, 50%)",
                                            승강기: 172,
                                            승강기Color: "hsl(88, 70%, 50%)",
                                        },
                                    ]}
                                    keys={["건축", "전기", "기계", "소방", "냉동냉장", "승강기"]}
                                    indexBy="country"
                                    margin={{top: 50, right: 130, bottom: 50, left: 60}}
                                    padding={0.3}
                                    valueScale={{type: "linear"}}
                                    indexScale={{type: "band", round: true}}
                                    colors={{scheme: "nivo"}}
                                    defs={[
                                        {
                                            id: "dots",
                                            type: "patternDots",
                                            background: "inherit",
                                            color: "#38bcb2",
                                            size: 4,
                                            padding: 1,
                                            stagger: true,
                                        },
                                        {
                                            id: "lines",
                                            type: "patternLines",
                                            background: "inherit",
                                            color: "#eed312",
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10,
                                        },
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: "냉동냉장",
                                            },
                                            id: "dots",
                                        },
                                        {
                                            match: {
                                                id: "기계",
                                            },
                                            id: "lines",
                                        },
                                    ]}
                                    borderColor={{
                                        from: "color",
                                        modifiers: [["darker", 1.6]],
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: "부문",
                                        legendPosition: "middle",
                                        legendOffset: 32,
                                        truncateTickAt: 0,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: "설비 - 공정",
                                        legendPosition: "middle",
                                        legendOffset: -40,
                                        truncateTickAt: 0,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{
                                        from: "color",
                                        modifiers: [["darker", 1.6]],
                                    }}
                                    legends={[
                                        {
                                            dataFrom: "keys",
                                            anchor: "bottom-right",
                                            direction: "column",
                                            justify: false,
                                            translateX: 120,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: "left-to-right",
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: "hover",
                                                    style: {
                                                        itemOpacity: 1,
                                                    },
                                                },
                                            ],
                                        },
                                    ]}
                                    role="application"
                                    ariaLabel="Nivo bar chart demo"
                                    barAriaLabel={(e) =>
                                        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={cx("card", "w45p")}>
                        <h2 className={cx("card-title")}>취합현황</h2>
                        <div className={cx("merge")}></div>
                        <div className={cx("merge-table")}>
                            <div className={cx("table-container")}>
                                <table className={cx("table", "fixed")}>
                                    <colgroup>
                                        <col width="5%" />
                                        <col width="25%" />
                                        <col width="10%" />
                                        <col width="30%" />
                                        <col width="10%" />
                                        <col width="10%" />
                                        <col width="10%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>취합기간</th>
                                            <th>대상</th>
                                            <th>제목</th>
                                            <th>대상지점</th>
                                            <th>완료지점</th>
                                            <th>미완료지점</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table className={cx("table")}>
                                    <colgroup>
                                        <col width="5%" />
                                        <col width="25%" />
                                        <col width="10%" />
                                        <col width="30%" />
                                        <col width="10%" />
                                        <col width="10%" />
                                        <col width="10%" />
                                    </colgroup>
                                    <tbody>
                                        <tr
                                            style={{
                                                background: `linear-gradient(to right, #e8b9b9 ${(1 / 14) * 100}%, transparent ${(1 / 14) * 100}%)`,
                                            }}
                                        >
                                            <td>1</td>
                                            <td>2024.07.23 ~ 2024.08.10</td>
                                            <td>충청</td>
                                            <td>(충청) 4분기 시설 투자비 취합 件</td>
                                            <td>14</td>
                                            <td>1</td>
                                            <td>13</td>
                                        </tr>
                                        <tr
                                            style={{
                                                background: `linear-gradient(to right, #e8b9b9 ${(10 / 34) * 100}%, transparent ${(10 / 34) * 100}%)`,
                                            }}
                                        >
                                            <td>2</td>
                                            <td>2024.07.23 ~ 2024.07.26</td>
                                            <td>서울,경기북부</td>
                                            <td>
                                                수도권1지역 7월 시설보수비 정산현황 작성 및 8월 통합수선비 등록 요청
                                            </td>
                                            <td>34</td>
                                            <td>10</td>
                                            <td>24</td>
                                        </tr>
                                        <tr
                                            style={{
                                                background: `linear-gradient(to right, #e8b9b9 ${(1 / 14) * 100}%, transparent ${(1 / 14) * 100}%)`,
                                            }}
                                        >
                                            <td>3</td>
                                            <td>2024.07.22 ~ 2024.08.02</td>
                                            <td>충청</td>
                                            <td>충청_24년 7월 통합수선비, 안전시설공사 정산현황 취합 件</td>
                                            <td>14</td>
                                            <td>1</td>
                                            <td>13</td>
                                        </tr>
                                        <tr
                                            style={{
                                                background: `linear-gradient(to right, #e8b9b9 ${(6 / 14) * 100}%, transparent ${(6 / 14) * 100}%)`,
                                            }}
                                        >
                                            <td>4</td>
                                            <td>2024.07.22 ~ 2024.08.02</td>
                                            <td>충청</td>
                                            <td>충청_24년 7월 하수도 비산량 감면 신청 취합 件</td>
                                            <td>14</td>
                                            <td>6</td>
                                            <td>8</td>
                                        </tr>
                                        <tr
                                            style={{
                                                background: `linear-gradient(to right, #e8b9b9 ${(6 / 16) * 100}%, transparent ${(6 / 16) * 100}%)`,
                                            }}
                                        >
                                            <td>5</td>
                                            <td>2024.07.19 ~ 2024.07.31</td>
                                            <td>경기서부</td>
                                            <td>★수도권2지역★(경기서부) 7월 수선비 정산현황 취합 건</td>
                                            <td>16</td>
                                            <td>6</td>
                                            <td>10</td>
                                        </tr>
                                        <tr
                                            style={{
                                                background: `linear-gradient(to right, #e8b9b9 ${(12 / 34) * 100}%, transparent ${(12 / 34) * 100}%)`,
                                            }}
                                        >
                                            <td>6</td>
                                            <td>2024.07.19 ~ 2024.07.25</td>
                                            <td>서울,경기북부</td>
                                            <td>★수도권1지역 24년 4분기 시설투자비 신청 작성 요청</td>
                                            <td>34</td>
                                            <td>12</td>
                                            <td>22</td>
                                        </tr>
                                        <tr
                                            style={{
                                                background: `linear-gradient(to right, #e8b9b9 ${(5 / 14) * 100}%, transparent ${(5 / 14) * 100}%)`,
                                            }}
                                        >
                                            <td>7</td>
                                            <td>2024.07.18 ~ 2024.07.30</td>
                                            <td>충청</td>
                                            <td>(충청) 8월 통합수선비 취합 件</td>
                                            <td>14</td>
                                            <td>5</td>
                                            <td>9</td>
                                        </tr>
                                        <tr
                                            style={{
                                                background: `linear-gradient(to right, #e8b9b9 ${(64 / 123) * 100}%, transparent ${(64 / 123) * 100}%)`,
                                            }}
                                        >
                                            <td>8</td>
                                            <td>2024.07.18 ~ 2024.07.25</td>
                                            <td>전체</td>
                                            <td>ESCO 1,2단계 LED조명 店 재고분 취합 요청 件</td>
                                            <td>123</td>
                                            <td>64</td>
                                            <td>59</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx("row")}>
                    <div className={cx("card", "w100p")}>
                        <h2 className={cx("card-title")}>정산현황</h2>
                        <div className={cx("cost")}>
                            <ResponsiveBar
                                data={[
                                    {
                                        "country": "서울",
                                        "등기구구입대 신청": 90,
                                        "등기구구입대 신청Color": "hsl(132, 70%, 50%)",
                                        "등기구구입대 반영": 40,
                                        "등기구구입대 반영Color": "hsl(257, 70%, 50%)",
                                        "수선비품구입대 신청": 33,
                                        "수선비품구입대 신청Color": "hsl(100, 70%, 50%)",
                                        "수선비품구입대 반영": 33,
                                        "수선비품구입대 반영Color": "hsl(239, 70%, 50%)",
                                        "기타유형자산": 118,
                                        "기타유형자산Color": "hsl(14, 70%, 50%)",
                                        "안전시설공사": 45,
                                        "안전시설공사Color": "hsl(294, 70%, 50%)",
                                    },
                                    {
                                        "country": "경기서부",
                                        "등기구구입대 신청": 193,
                                        "등기구구입대 신청Color": "hsl(174, 70%, 50%)",
                                        "등기구구입대 반영": 196,
                                        "등기구구입대 반영Color": "hsl(56, 70%, 50%)",
                                        "수선비품구입대 신청": 0,
                                        "수선비품구입대 신청Color": "hsl(170, 70%, 50%)",
                                        "수선비품구입대 반영": 82,
                                        "수선비품구입대 반영Color": "hsl(130, 70%, 50%)",
                                        "기타유형자산": 153,
                                        "기타유형자산Color": "hsl(262, 70%, 50%)",
                                        "안전시설공사": 14,
                                        "안전시설공사Color": "hsl(84, 70%, 50%)",
                                    },
                                    {
                                        "country": "경기남부",
                                        "등기구구입대 신청": 191,
                                        "등기구구입대 신청Color": "hsl(85, 70%, 50%)",
                                        "등기구구입대 반영": 125,
                                        "등기구구입대 반영Color": "hsl(273, 70%, 50%)",
                                        "수선비품구입대 신청": 3,
                                        "수선비품구입대 신청Color": "hsl(132, 70%, 50%)",
                                        "수선비품구입대 반영": 156,
                                        "수선비품구입대 반영Color": "hsl(242, 70%, 50%)",
                                        "기타유형자산": 108,
                                        "기타유형자산Color": "hsl(67, 70%, 50%)",
                                        "안전시설공사": 133,
                                        "안전시설공사Color": "hsl(356, 70%, 50%)",
                                    },
                                    {
                                        "country": "경기북부",
                                        "등기구구입대 신청": 166,
                                        "등기구구입대 신청Color": "hsl(313, 70%, 50%)",
                                        "등기구구입대 반영": 154,
                                        "등기구구입대 반영Color": "hsl(11, 70%, 50%)",
                                        "수선비품구입대 신청": 41,
                                        "수선비품구입대 신청Color": "hsl(103, 70%, 50%)",
                                        "수선비품구입대 반영": 160,
                                        "수선비품구입대 반영Color": "hsl(292, 70%, 50%)",
                                        "기타유형자산": 141,
                                        "기타유형자산Color": "hsl(276, 70%, 50%)",
                                        "안전시설공사": 133,
                                        "안전시설공사Color": "hsl(116, 70%, 50%)",
                                    },
                                    {
                                        "country": "호남",
                                        "등기구구입대 신청": 168,
                                        "등기구구입대 신청Color": "hsl(259, 70%, 50%)",
                                        "등기구구입대 반영": 185,
                                        "등기구구입대 반영Color": "hsl(250, 70%, 50%)",
                                        "수선비품구입대 신청": 135,
                                        "수선비품구입대 신청Color": "hsl(109, 70%, 50%)",
                                        "수선비품구입대 반영": 11,
                                        "수선비품구입대 반영Color": "hsl(180, 70%, 50%)",
                                        "기타유형자산": 115,
                                        "기타유형자산Color": "hsl(118, 70%, 50%)",
                                        "안전시설공사": 82,
                                        "안전시설공사Color": "hsl(40, 70%, 50%)",
                                    },
                                    {
                                        "country": "충청",
                                        "등기구구입대 신청": 128,
                                        "등기구구입대 신청Color": "hsl(10, 70%, 50%)",
                                        "등기구구입대 반영": 28,
                                        "등기구구입대 반영Color": "hsl(82, 70%, 50%)",
                                        "수선비품구입대 신청": 84,
                                        "수선비품구입대 신청Color": "hsl(219, 70%, 50%)",
                                        "수선비품구입대 반영": 74,
                                        "수선비품구입대 반영Color": "hsl(59, 70%, 50%)",
                                        "기타유형자산": 46,
                                        "기타유형자산Color": "hsl(138, 70%, 50%)",
                                        "안전시설공사": 193,
                                        "안전시설공사Color": "hsl(83, 70%, 50%)",
                                    },
                                    {
                                        "country": "영남서부",
                                        "등기구구입대 신청": 140,
                                        "등기구구입대 신청Color": "hsl(177, 70%, 50%)",
                                        "등기구구입대 반영": 98,
                                        "등기구구입대 반영Color": "hsl(173, 70%, 50%)",
                                        "수선비품구입대 신청": 91,
                                        "수선비품구입대 신청Color": "hsl(59, 70%, 50%)",
                                        "수선비품구입대 반영": 107,
                                        "수선비품구입대 반영Color": "hsl(188, 70%, 50%)",
                                        "기타유형자산": 170,
                                        "기타유형자산Color": "hsl(3, 70%, 50%)",
                                        "안전시설공사": 198,
                                        "안전시설공사Color": "hsl(113, 70%, 50%)",
                                    },
                                ]}
                                keys={[
                                    "등기구구입대 신청",
                                    "등기구구입대 반영",
                                    "수선비품구입대 신청",
                                    "수선비품구입대 반영",
                                    "기타유형자산",
                                    "안전시설공사",
                                ]}
                                indexBy="country"
                                margin={{top: 50, right: 150, bottom: 50, left: 60}}
                                padding={0.3}
                                groupMode="grouped"
                                valueScale={{type: "linear"}}
                                indexScale={{type: "band", round: true}}
                                colors={{scheme: "nivo"}}
                                defs={[
                                    {
                                        id: "dots",
                                        type: "patternDots",
                                        background: "inherit",
                                        color: "#38bcb2",
                                        size: 4,
                                        padding: 1,
                                        stagger: true,
                                    },
                                    {
                                        id: "lines",
                                        type: "patternLines",
                                        background: "inherit",
                                        color: "#eed312",
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10,
                                    },
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: "기타유형자산",
                                        },
                                        id: "dots",
                                    },
                                    {
                                        match: {
                                            id: "수선비품구입대 신청",
                                        },
                                        id: "lines",
                                    },
                                ]}
                                borderColor={{
                                    from: "color",
                                    modifiers: [["darker", 1.6]],
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "부문",
                                    legendPosition: "middle",
                                    legendOffset: 32,
                                    truncateTickAt: 0,
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "예산",
                                    legendPosition: "middle",
                                    legendOffset: -40,
                                    truncateTickAt: 0,
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{
                                    from: "color",
                                    modifiers: [["darker", 1.6]],
                                }}
                                legends={[
                                    {
                                        dataFrom: "keys",
                                        anchor: "bottom-right",
                                        direction: "column",
                                        justify: false,
                                        translateX: 120,
                                        translateY: 0,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 20,
                                        itemDirection: "left-to-right",
                                        itemOpacity: 0.85,
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: "hover",
                                                style: {
                                                    itemOpacity: 1,
                                                },
                                            },
                                        ],
                                    },
                                ]}
                                role="application"
                                ariaLabel="Nivo bar chart demo"
                                barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
};

export {DashboardPage};

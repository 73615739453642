import React, {useEffect, useMemo, useRef} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {useShop} from "~/hooks/use-shop/use-shop";

import Logo from "./lottemart-logo.png";

import styles from "./elevator-check-qr-list.module.scss";

const cx = classNames.bind(styles);

const ElevatorCheckQrList = () => {
    const navigate = useNavigate();

    const {id} = useParams<{id: string}>();

    const handlePageMove = (liftId: string) => {
        navigate("/qr/check/elevator/" + liftId);
    };

    const {loading, summary, list, statusValueByKey, totalCount, refresh} = useCheckElevatorList({
        perPage: 1000,
        page: 1,
        buildingId: Number(id),
        startDate: dayjs(new Date()).format("YYYY-MM-DD"),
        endDate: dayjs(new Date()).format("YYYY-MM-DD"),
    });

    const {shopList} = useShop();

    const buildingName = useMemo(
        () => shopList[shopList.findIndex((item) => Number(item.value) === Number(id))]?.label,
        [id, shopList],
    );

    const location = useLocation();
    const isInitialMount = useRef(true);
    const [isBlocking, setIsBlocking] = React.useState(true);

    useEffect(() => {
        window.history.replaceState({page: 2}, "title", "/qr/check/elevator/list/" + id);
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            window.history.pushState(null, "", window.location.href);
        }

        const handlePopState = (event: PopStateEvent) => {
            if (isBlocking) {
                setIsBlocking(false);
                navigate(-100);
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [isBlocking, navigate, location.pathname]);

    return (
        <div className={cx("container")}>
            <div className={cx("logo-container")}>
                <img src={Logo} alt="lottemart logo" />
            </div>
            <h1 className={cx("title")}>
                롯데마트 {buildingName}
                <br />
                <span>승강기 점검 목록</span>
            </h1>
            {list.length > 0 ? (
                <ul className={cx("list")}>
                    {list.map((item) => {
                        return (
                            <li key={item.liftId} className={cx("item")} onClick={() => handlePageMove(item.liftId)}>
                                <span className={cx(item.checkStatus)}>
                                    {item.checkStatus === "REQUEST"
                                        ? "요청됨"
                                        : item.checkStatus === "ING"
                                          ? "점검중"
                                          : "점검완료"}
                                </span>
                                <div className={cx("half-container")}>
                                    <div className={cx("half")}>
                                        <h2>근무자</h2>
                                        <p>{item.employeeName}</p>
                                        <h2>고장일시</h2>
                                        <p>{item.breakDate.substring(0, 16)}</p>
                                    </div>
                                    <div className={cx("half")}>
                                        <h2>수신자</h2>
                                        <p>{item.recipientName}</p>
                                        <h2>고장호기</h2>
                                        <p>
                                            {item.breakLocation}/{item.breakUnit}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <h2>고장내용</h2>
                                    <p>{item.content}</p>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <div className={cx("empty")}>점검내역이 없습니다.</div>
            )}
            <div className={cx("request-button")} onClick={() => navigate(`/mobile/check/elevator/request/${id}`)}>
                등록하기
            </div>
        </div>
    );
};

export {ElevatorCheckQrList};

import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button/button";
import {useMachineDetail} from "~/pages/equipment/detail/use-detail";

import styles from "./detail.module.scss";

const cx = classNames.bind(styles);

const MobileEquipmentDetailPage = () => {
    const navigate = useNavigate();
    const {companyId, buildingId, machineId} = useParams<{
        companyId: string;
        buildingId: string;
        machineId: string;
    }>();

    // 기존 데이터 불러오기
    const [submit, response, isLoading] = useMachineDetail({
        companyId: Number(companyId),
        buildingId: Number(buildingId),
        machineId: Number(machineId),
    });
    const tableData = response?.machineDTO;

    useEffect(() => {
        submit();
    }, [companyId, buildingId, machineId]);

    return (
        <div>
            <div className={cx("inner-container")}>
                <h1 className={cx("title")}>설비 현황 상세보기</h1>
                <CloseOutlined
                    className={cx("close-button")}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            </div>

            <div className={cx("list-container")}>
                <div
                    style={{
                        width: "100%",
                        height: tableData?.base64Image ? "400px" : "320px",
                        backgroundColor: "#fafafa",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {isLoading ? (
                        <Spin />
                    ) : tableData?.base64Image ? (
                        <img style={{height: "100%"}} src={`data:image/jpeg;base64,${tableData?.base64Image}`} />
                    ) : (
                        <span style={{color: "#686868"}}>이미지가 없습니다.</span>
                    )}
                </div>
                <h2>{tableData?.machineName}</h2>
                <table className={cx("table")}>
                    <colgroup>
                        <col width="25%" />
                        <col width="25%" />
                        <col width="25%" />
                        <col width="25%" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>카테고리</th>
                            <td colSpan={3}>{tableData?.machineCategoryName}</td>
                        </tr>
                        <tr>
                            <th>제조사</th>
                            <td>{tableData?.machineManufactureCompany}</td>
                            <th>제조연월</th>
                            <td>
                                {tableData?.machineManufactureDate === "" ||
                                tableData?.machineManufactureDate === undefined
                                    ? ""
                                    : dayjs(tableData?.machineManufactureDate).format("YYYY-MM")}
                            </td>
                        </tr>
                        <tr>
                            <th>설치업체</th>
                            <td>{tableData?.machineInstallCompany}</td>
                            <th>설치연월</th>
                            <td>
                                {tableData?.machineInstallDate === "" || tableData?.machineInstallDate === undefined
                                    ? ""
                                    : dayjs(tableData?.machineInstallDate).format("YYYY-MM")}
                            </td>
                        </tr>
                        <tr>
                            <th>제조번호</th>
                            <td>{tableData?.machineManufactureNumber}</td>
                            <th>모델명</th>
                            <td>{tableData?.machineModelName}</td>
                        </tr>
                        <tr>
                            <th>장비코드</th>
                            <td colSpan={3}>{tableData?.machineLocalCode}</td>
                        </tr>
                    </tbody>
                </table>
                <h2>설비 이력</h2>

                <table className={cx("table")}>
                    <colgroup>
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="35%" />
                        <col width="20%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>날짜</th>
                            <th>비용</th>
                            <th>구분</th>
                            <th>업무내역</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.machineHistoryDTOList
                            .filter((m) => m.isDel === "N")
                            .map(
                                (
                                    el: {
                                        machineHistoryDate: number;
                                        machineHistoryCost: number;
                                        machineHistoryContent: string;
                                        machineHistoryNote: string;
                                        machineHistoryId: number;
                                        type: string;
                                    },
                                    index: number,
                                ) => (
                                    <tr
                                        key={index}
                                        onClick={() => {
                                            navigate(
                                                `/mobile/equipment/detail/edit/${companyId}/${buildingId}/${machineId}/${el.machineHistoryId}`,
                                            );
                                        }}
                                    >
                                        <td>{dayjs(new Date(el.machineHistoryDate)).format("YYYY.MM.DD")}</td>
                                        <td>{el.machineHistoryCost.toLocaleString()}</td>
                                        <td>
                                            {el.type === "self"
                                                ? "자체개선"
                                                : el.type === "construction"
                                                  ? "실재공사"
                                                  : ""}
                                        </td>
                                        <td>{el.machineHistoryContent}</td>
                                        <td>{el.machineHistoryNote}</td>
                                    </tr>
                                ),
                            )}
                    </tbody>
                </table>
            </div>
            <div className={cx("button-container")}>
                <Button
                    label="새 이력 입력하기"
                    size="large"
                    onClick={() => navigate(`/mobile/equipment/detail/request/${companyId}/${buildingId}/${machineId}`)}
                />
            </div>
        </div>
    );
};

export {MobileEquipmentDetailPage};

import React from "react";
import {useNavigate} from "react-router-dom";
import {faCommentDots, faQrcode, faUserGear} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import styles from "./quick.module.scss";

const cx = classNames.bind(styles);

const QuickPage = () => {
    const navigate = useNavigate();
    return (
        <div className={cx("container")}>
            {/* <button
                className={cx("button")}
                onClick={() => {
                    navigate("/mobile/check/elevator/request");
                }}
            >
                <FontAwesomeIcon icon={faCommentDots} />
                <span>점검요청</span>
            </button> */}
            <button
                className={cx("button")}
                onClick={() => {
                    navigate("/mobile/check/elevator/list");
                }}
            >
                <FontAwesomeIcon icon={faUserGear} />
                <span>점검현황</span>
            </button>
            <button
                className={cx("button")}
                onClick={() => {
                    navigate("/mobile/check/elevator/qr");
                }}
            >
                <FontAwesomeIcon icon={faQrcode} />
                <span>점검인 QR코드</span>
            </button>
        </div>
    );
};

export {QuickPage};

import {useMemo, useState} from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";

import {
    CostFacilityDelete,
    CostFacilityRegistration,
    CostFacilityRegistrationResponse,
} from "./cost-facility-cud.interface";

// 등록, 수정
const useCostFacilityRegistration = (
    config: CostFacilityRegistration,
): [() => Promise<void>, CostFacilityRegistrationResponse | null, boolean, any] => {
    const [response, setResponse] = useState<CostFacilityRegistrationResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const {
            year = dayjs(new Date()).format("YYYY"),
            month = dayjs(new Date()).format("MM"),
            typeId = null,
            yearProcessId = null,
            yearConstructName = "",
            yearMachineLinkYn = "N",
            monthConstructGoYn = "N",
            yearMachineId = [],
            monthConstructDate = dayjs(new Date()).format("YYYY-MM-DD"),
            monthConstructGoReason = "",
            monthConstructCompany = "",
            monthConstructCost = null,
            storeContributionAmount = null,
            offsetAmount = null,
            monthEstimateFileObj = null,
            monthMemo = null,
            budgetDivision = undefined,
            buildingId = null,
        } = config;

        const formData = new FormData();

        formData.append("year", config.year.format("YYYY"));
        formData.append("month", config.month.format("MM"));
        if (typeId !== null && !budgetDivision) formData.append("typeId", typeId);
        if (yearProcessId !== null) formData.append("yearProcessId", yearProcessId);
        formData.append("yearConstructName", yearConstructName);
        formData.append("yearMachineLinkYn", yearMachineLinkYn);
        formData.append("monthConstructGoYn", monthConstructGoYn);
        if (monthConstructDate)
            formData.append("monthConstructDate", config.monthConstructDate?.format("YYYY-MM-DD") ?? "");
        formData.append("monthConstructGoReason", monthConstructGoReason);
        formData.append("monthConstructCompany", monthConstructCompany);
        if (monthConstructCost !== null) formData.append("monthConstructCost", monthConstructCost.toString());
        if (storeContributionAmount !== null)
            formData.append("storeContributionAmount", storeContributionAmount.toString());
        if (offsetAmount !== null) formData.append("offsetAmount", offsetAmount.toString());
        if (monthEstimateFileObj !== null) formData.append("monthEstimateFileObj", monthEstimateFileObj);
        formData.append("monthMemo", monthMemo ?? "");
        formData.append("yearMachineId", yearMachineId.join(","));
        formData.append("costFacilityId", config.costFacilityId.toString());
        if (budgetDivision) formData.append("budgetDivision", budgetDivision.toString());
        if (buildingId) formData.append("buildingId", buildingId.toString());

        return httpRequest<FormData, CostFacilityRegistrationResponse>({
            method: "POST",
            url: "/lotte/cost/facility/month/plans/plan",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return [submit, response, isLoading, error];
};

// 삭제
const useCostFacilityDelete = ({costFacilityId, division}: CostFacilityDelete): [() => Promise<void>, any] => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<{costFacilityId: number}, null>({
            method: "DELETE",
            url: `/lotte/cost/facility/month/plans/plan${division === "MANAGER" ? "/manager" : ""}`,
            params: {
                costFacilityId,
            },
        });
    }, [costFacilityId]);

    const submit = async () => {
        try {
            await request.submit();
        } catch (e) {
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
            console.log(e);
            setError(e);
        }
    };
    return [submit, error];
};

export {useCostFacilityDelete, useCostFacilityRegistration};

import {Dispatch, SetStateAction, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {MachineDetailRequest, MachineDetailResponse} from "./equipment-detail.interface";

/* --------------------------------- 설비 상세정보 -------------------------------- */
const useMachineDetail = (
    config: MachineDetailRequest,
): [() => Promise<void>, MachineDetailResponse | null, boolean, any] => {
    const [response, setResponse] = useState<MachineDetailResponse | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const {machineId, companyId, buildingId} = config;
        return httpRequest<MachineDetailRequest, MachineDetailResponse>({
            method: "GET",
            url: "/machine/mv",
            params: {
                companyId,
                buildingId,
                machineId,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const response = await request.submit();
            setResponse(response.data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

/* -------------------------------- 설비 게시물 삭제 ------------------------------- */
const useMachineDelete = (
    machineId: number,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
): [() => Promise<void>] => {
    const request = useMemo(() => {
        return httpRequest<{machineId: number}, undefined>({
            method: "DELETE",
            url: "/machine/md",
            data: {
                machineId: machineId,
            },
        });
    }, [machineId]);

    const submit = async () => {
        if (window.confirm("삭제하시겠습니까?")) {
            try {
                setIsLoading(true);
                const res = await request.submit();
            } catch (e) {
                alert("에러가 발생하였습니다 다시 시도해주세요");
            } finally {
                setIsLoading(false);
                alert("삭제되었습니다");
            }
        }
    };

    return [submit];
};

/* ------------------------------- // 설비 상세 수정 ------------------------------- */
// const useMachineUpdateDetail = (config: UpdateDetailRequest): [() => Promise<void>, boolean, any] => {
const useMachineUpdateDetail = (config: any, setIsLoading: any): [() => Promise<void>] => {
    // const [response, setResponse] = useState(null);
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        // return httpRequest<UpdateDetailRequest, UpdateDetailResponse>({
        return httpRequest<any>({
            method: "PUT",
            url: "/machine/mu",
            data: config,
        });
    }, [config]);

    const submit = async () => {
        if (window.confirm("수정하시겠습니까?")) {
            try {
                setIsLoading(true);
                const res = await request.submit();
                alert("저장되었습니다");
            } catch (e) {
                setError(e);
                alert("에러가 발생하였습니다 다시 시도해주세요");
            } finally {
                setIsLoading(false);
            }
        }
    };

    return [submit];
};

export {useMachineDelete, useMachineDetail, useMachineUpdateDetail};

import React from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames/bind";

import styles from "./404.page.module.scss";

const cx = classNames.bind(styles);

const Error404 = () => {
    const navigate = useNavigate();
    return (
        <div className={cx("not-found")}>
            <h1 className={cx("not-found__title")}>404</h1>
            <p className={cx("not-found__message")}>해당 페이지가 존재하지 않습니다.</p>
            <button className={cx("not-found__button")} onClick={() => navigate("/")}>
                돌아가기
            </button>
        </div>
    );
};

export {Error404};

import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {CostIntegratedRequest, CostIntegratedResponse, useCostIntegratedFundRequest} from "./integrated.interface";

const useCostIntegratedList = (
    config: CostIntegratedRequest,
): [() => Promise<void>, CostIntegratedResponse | null, boolean, any] => {
    const [response, setResponse] = useState<CostIntegratedResponse | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<CostIntegratedRequest, CostIntegratedResponse>({
            method: "GET",
            url: "/lotte/cost/repair/integrate/list",
            params: {
                year: config.date.year?.format("YYYY"),
                month: config.date.month?.format("MM"),
                quaters: config.date.quarter?.format("Q"),
                startCost: config.costRange.min,
                endCost: config.costRange.max,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

// 요청금액 기준 조회
const useCostIntegratedFund = (config: {year: string; month: string}): [() => Promise<void>, number | null, any] => {
    const [response, setResponse] = useState<number | null>(null);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<typeof config, {year: string; month: string; amountAllocation: number}>({
            method: "GET",
            url: "/lotte/cost/repair/integrate/fundList",
            params: {
                year: config.year,
                month: config.month,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            const res = await request.submit();
            setResponse(res.data.amountAllocation);
        } catch (e) {
            console.log(e);
            setError(e);
        }
    };
    return [submit, response, error];
};

// 요청금액 기준 값 변경
const useCostIntegratedFundUpdate = (config: {
    year: string | null;
    month: string | null;
    amountAllocation: number;
}): [() => Promise<void>, any] => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<useCostIntegratedFundRequest, {responseData: string}>({
            method: "POST",
            url: "/lotte/cost/repair/integrate/fundUpsert",
            data: {
                year: config.year,
                month: config.month,
                amountAllocation: config.amountAllocation * 100000000,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            await request.submit();
        } catch (e) {
            console.log(e);
            setError(e);
        }
    };
    return [submit, error];
};

// 엑셀 다운로드
const useCostIntegratedExcelDownload = (config: {year: string | null; month: string | null}) => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<{year: string | null; month: string | null}, Blob>({
            method: "GET",
            url: "/lotte/cost/repair/integrate/excelDown",
            params: {
                year: config.year,
                month: config.month,
            },
            responseType: "blob",
        });
    }, [config]);
    const submit = async () => {
        try {
            const response = await request.submit();

            // 파일 다운로드 처리
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // 파일명추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";
            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = matches[1].trim().replace(/['"]/g, "");
                }
            }
            link.setAttribute("download", decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
            setError(e);
        }
    };

    return [submit, error];
};

export {useCostIntegratedExcelDownload, useCostIntegratedFund, useCostIntegratedFundUpdate, useCostIntegratedList};

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import {useMachineBoardChart, useMobileProcessData} from "~/data/machine/board/use-machine";

import styles from "./list.module.scss";

const cx = classNames.bind(styles);

const MobileEquipmentListPage = () => {
    const navigate = useNavigate();

    const [list, setList] = useState<JSX.Element[]>([]);

    const [chartResponse] = useMachineBoardChart();
    const {response} = useMobileProcessData();
    const labels: {[name: string]: string} = {
        building: "건축",
        electricity: "전기",
        machine: "기계",
        firefighting: "소방",
        freezing: "냉동냉장",
        elevator: "승강기",
        autoSorter: "오터소터",
    };

    useEffect(() => {
        const mappingName: Record<string, string> = response.reduce(
            (map: Record<string, string>, item: itemProcess) => {
                map[item.name] = item.code;
                return map;
            },
            {},
        );
        const resultArr = Object.keys(chartResponse)
            .map((k) => {
                const name = labels[k];
                if (name) {
                    return {
                        code: mappingName[name],
                        name: name,
                        value: chartResponse[k],
                    };
                }
                return null;
            })
            .filter((i): i is {code: string; name: string; value: any} => i !== null)
            .sort((a, b) => a.name.localeCompare(b.name, "ko-KR"))
            .map((el, index: number) => (
                <li
                    key={index}
                    id={el.code}
                    onClick={() => {
                        navigate(`/mobile/equipment/${el.code}`);
                    }}
                >
                    <label>{el.name}</label>
                    <span>{el.value}</span>
                </li>
            ));
        setList(resultArr);
    }, [chartResponse, response]);

    return (
        <div className={cx("container")}>
            <h1 className={cx("title")}>설비 현황</h1>
            <button
                className={cx("button")}
                onClick={() => {
                    navigate("/mobile/equipment/search");
                }}
            >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <span>설비 검색</span>
            </button>
            <div className={cx("list-container")}>
                <h2>공정별 현황</h2>
                <ul>
                    <li>
                        <label>공정</label>
                        <span>수량</span>
                    </li>
                    {list}
                </ul>
            </div>
        </div>
    );
};

export {MobileEquipmentListPage};

interface itemProcess {
    code: string;
    name: string;
}

import {useMemo, useState} from "react";
import {message} from "antd";

import {httpRequest} from "~/fetch/common/http-request";

import {InquiryBoardDetailResponse} from "./inquiry-board.interface";

const useInquiryBoardDetail = (noticeId: number) => {
    const [response, setResponse] = useState<null | InquiryBoardDetailResponse>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<null, InquiryBoardDetailResponse>({
            method: "GET",
            url: "/commonBoard/commonBoardDetail",
            params: {
                id: noticeId,
            },
        });
    }, [noticeId]);

    const submit = async () => {
        try {
            setIsLoading(true);

            const res = await request.submit();

            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }; // submit

    const changeStatus = (status: string) => {
        return httpRequest<{id: number; status: string}, any>({
            method: "PUT",
            url: "/commonBoard/changeStatus",
            data: {
                id: noticeId,
                status,
            },
        })
            .submit()
            .then(() => {
                message.success("변경되었습니다.");
            })
            .catch((e) => {
                console.log(e);
                message.error("에러 발생!");
            });
    };

    return {submitDetail: submit, changeStatus, detail: response, isDetailLoading: isLoading, error};
};

export {useInquiryBoardDetail};

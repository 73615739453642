import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DatePicker, InputNumber} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {FilterItem} from "~/components/filter-item/filter-item";
import {PageTitle} from "~/components/page-title/page-title";
import {
    useCostIntegratedExcelDownload,
    useCostIntegratedFund,
    useCostIntegratedFundUpdate,
    useCostIntegratedList,
} from "~/data/cost/integrated/use-integrated";
import {formatCost} from "~/utills/formatCost";

import {FilterDateType} from "./cost-integrated.interface";

import styles from "./cost-integrated.module.scss";
import tableStyles from "~/components/custom-label-table/custom-label-table.module.scss";
import filterStyles from "~/components/filter-item/filter-item.module.scss";

const cx = classNames.bind(styles);
const filterCx = classNames.bind(filterStyles);
const tableCx = classNames.bind(tableStyles);

const CostIntegratedPage = () => {
    const perPage = 50;
    const [date, setDate] = useState<FilterDateType>({
        year: dayjs(new Date()),
        quarter: null,
        month: dayjs(new Date()),
    });
    const [costRange, setCostRange] = useState<{min: null | number; max: null | number}>({
        min: null,
        max: null,
    });
    const [page, setPage] = useState<number>(1);
    const [allocation, setAllocation] = useState<number | null>(null);

    const [submit, response, isLoading] = useCostIntegratedList({
        date,
        costRange,
    });
    const [updateAllocation] = useCostIntegratedFundUpdate({
        year: date.year?.format("YYYY") ?? null,
        month: date.month?.format("MM") ?? null,
        amountAllocation: allocation ?? 0,
    });

    const [downloadExcel] = useCostIntegratedExcelDownload({
        year: date.year?.format("YYYY") ?? null,
        month: date.month?.format("MM") ?? null,
    });

    const navigate = useNavigate();

    const handleReset = () => {
        setDate({
            year: dayjs(new Date()),
            quarter: null,
            month: null,
        });
        setCostRange({
            min: null,
            max: null,
        });
    };

    useEffect(() => {
        submit();
    }, []);

    const handleUpdateAllocation = () => {
        if (date.year === null || date.month === null || allocation === null) {
            Swal.fire({
                text: "연도, 월, 기준 값을 모두 입력해주세요",
                confirmButtonText: "확인",
            });
        } else {
            Swal.fire({
                text: "요청금액 기준 값을 변경하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "확인",
                cancelButtonText: "취소",
            }).then((info) => {
                if (info.isConfirmed) {
                    updateAllocation();
                    setTimeout(submit, 500);
                }
            });
        }
    };

    return (
        <div>
            <PageTitle>통합수선비</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <FilterItem label={"연도"}>
                        <DatePicker
                            placeholder={"연도 선택"}
                            picker={"year"}
                            style={{width: "120px"}}
                            status={date.year === null ? "error" : ""}
                            value={date.year}
                            onChange={(e) => {
                                if (e === null) {
                                    setDate((prev) => ({...prev, year: e, month: null, quarter: null}));
                                }
                                setDate((prev) => ({...prev, year: e}));
                            }}
                        />
                    </FilterItem>
                    {/* <FilterItem label={"분기"}>
                        <DatePicker
                            placeholder={"분기 선택"}
                            locale={locale}
                            popupClassName="no-header"
                            picker={"quarter"}
                            format={"Q분기"}
                            disabled={date.year === null}
                            style={{width: "120px"}}
                            value={date.quarter}
                            onChange={(e) => setDate((prev) => ({...prev, quarter: e, month: null}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("Q분기")}</div>
                            )}
                        />
                    </FilterItem> */}
                    <FilterItem label={"월"}>
                        <DatePicker
                            placeholder={"월 선택"}
                            className={filterCx("filter-datepicker")}
                            popupClassName="no-header"
                            picker={"month"}
                            disabled={date.year === null}
                            format={"MM"}
                            style={{width: "120px"}}
                            value={date.month}
                            onChange={(e) => setDate((prev) => ({...prev, month: e, quarter: null}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                            )}
                        />
                    </FilterItem>
                    {/* <FilterItem label={"금액"}>
                        <div className={filterCx("cost-range")} style={{width: "300px"}}>
                            <InputNumber
                                className={filterCx("cost-input")}
                                value={costRange.min}
                                placeholder="최소 금액"
                                controls={false}
                                name={"min"}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                onChange={(e) => setCostRange((prev) => ({...prev, min: e}))}
                            />{" "}
                            ~{" "}
                            <InputNumber
                                className={filterCx("cost-input")}
                                value={costRange.max}
                                placeholder="최대 금액"
                                controls={false}
                                name={"max"}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                onChange={(e) => setCostRange((prev) => ({...prev, max: e}))}
                            />
                        </div>
                    </FilterItem> */}
                    <div className={cx("button-container")}>
                        <Button
                            label={"조회"}
                            color={"primary"}
                            onClick={() => {
                                if ((date.month !== null || date.quarter !== null) && date.year === null) {
                                    Swal.fire({
                                        text: "연도 값을 입력하세요",
                                        confirmButtonText: "확인",
                                    });
                                } else {
                                    submit();
                                }
                            }}
                        />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <>
                            {response
                                ? response?.year === null
                                    ? "권역별 목록"
                                    : `${response?.year}년`
                                : "권역별 목록"}
                            {response
                                ? response.quaters
                                    ? ` ${response.quaters}분기`
                                    : response.month
                                      ? ` ${response?.month}월`
                                      : ""
                                : ""}
                            <div className={tableCx("button-container")} style={{height: "32px"}}>
                                {date.year === null || date.month === null ? (
                                    <></>
                                ) : (
                                    <>
                                        <InputNumber
                                            value={allocation}
                                            onChange={(e) => setAllocation(e)}
                                            min={0}
                                            placeholder="요청금액 기준 값 입력"
                                            style={{
                                                width: "180px",
                                                textAlign: "right",
                                                paddingRight: "10px",
                                            }}
                                            formatter={(value) => formatCost(value)}
                                            controls={false}
                                            prefix="億"
                                        />
                                        <Button label="요청금액 기준 값 변경" onClick={handleUpdateAllocation} />
                                    </>
                                )}
                                <Button label={"엑셀 다운"} onClick={() => downloadExcel()} color={"primary"} />
                            </div>
                        </>
                    }
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    dataSource={response?.integratedRepairCostList}
                    rowKey={"trainerSectorId"}
                    loading={isLoading}
                    rowClassName={"table-row"}
                    onRow={(record) => {
                        return {
                            style: {
                                backgroundColor: record.trainerSectorName === "합계" ? "#ffefef" : "",
                            },
                        };
                    }}
                    pagination={false}
                    noPagination
                    bordered
                    page={page}
                    totalCount={1}
                    columns={[
                        {
                            title: "부문명",
                            key: "trainerSectorName",
                            dataIndex: "trainerSectorName",
                            className: tableCx("vertical"),
                        },
                        {
                            title: "점포수",
                            children: [
                                {
                                    title: "점포수",
                                    key: "buildingCnt",
                                    dataIndex: "buildingCnt",
                                },
                                {
                                    title: "구성비",
                                    key: "compositionRatio",
                                    dataIndex: "compositionRatio",
                                },
                                {
                                    title: "배정금액",
                                    key: "amountAllocation",
                                    dataIndex: "amountAllocation",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                },
                            ],
                        },
                        {
                            title: `요청 금액 (${response?.totalAllocationAmount} 기준)`,
                            className: tableCx("parent-column"),
                            children: [
                                {
                                    title: "기타유형자산",
                                    key: "totalOtherTangible",
                                    dataIndex: "totalOtherTangible",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                    onCell: (record) => ({
                                        onClick: () => {
                                            navigate(
                                                record.trainerSectorName === "합계"
                                                    ? `/cost/facility?dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`
                                                    : `/cost/facility?typeId=LC028_2&trainerSectorId=${record.trainerSectorId}&dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`,
                                            );
                                        },
                                    }),
                                },
                                {
                                    title: "안전시설공사",
                                    key: "totalSafetyFacility",
                                    dataIndex: "totalSafetyFacility",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                    onCell: (record) => ({
                                        onClick: () => {
                                            navigate(
                                                record.trainerSectorName === "합계"
                                                    ? `/cost/facility?dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`
                                                    : `/cost/facility?typeId=LC028_1&trainerSectorId=${record.trainerSectorId}&dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`,
                                            );
                                        },
                                    }),
                                },
                                {
                                    title: "등기구 구입비",
                                    key: "totalLightFixture",
                                    dataIndex: "totalLightFixture",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                    onCell: (record) => ({
                                        onClick: () => {
                                            navigate(
                                                record.trainerSectorName === "합계"
                                                    ? `/cost/maintenance?dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`
                                                    : `/cost/maintenance?trainerSectorId=${record.trainerSectorId}&dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`,
                                            );
                                        },
                                    }),
                                },
                                {
                                    title: "수선유지비품 구입비",
                                    key: "totalRepairMaintenance",
                                    dataIndex: "totalRepairMaintenance",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                    onCell: (record) => ({
                                        onClick: () => {
                                            navigate(
                                                record.trainerSectorName === "합계"
                                                    ? `/cost/maintenance?dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`
                                                    : `/cost/maintenance?trainerSectorId=${record.trainerSectorId}&dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`,
                                            );
                                        },
                                    }),
                                },
                                {
                                    title: "소계",
                                    key: "subtotal",
                                    dataIndex: "subtotal",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                },
                            ],
                        },
                        {
                            title: "",
                            children: [
                                {
                                    title: "트레이너 예비비",
                                    key: "trainerReserveFund",
                                    dataIndex: "trainerReserveFund",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                    onCell: (record) => ({
                                        onClick: () => {
                                            navigate(
                                                `/cost/trainer?dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`,
                                            );
                                        },
                                    }),
                                },
                                {
                                    title: "상계처리비용",
                                    key: "offsetAmount",
                                    dataIndex: "offsetAmount",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                    onCell: (record) => ({
                                        onClick: () => {
                                            navigate(
                                                `/cost/facility?dates=${date.year?.format("YYYY") ?? dayjs(new Date()).format("YYYY")},${date.month?.format("MM") ?? dayjs(new Date()).format("MM")}`,
                                            );
                                        },
                                    }),
                                },
                                {
                                    title: "소계",
                                    key: "totalTrainerOffset",
                                    dataIndex: "totalTrainerOffset",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                },
                            ],
                        },
                        {
                            title: "최종 합계",
                            key: "total",
                            dataIndex: "total",
                            className: tableCx("vertical"),
                            render: (value) => (value ? value.toLocaleString() : 0),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {CostIntegratedPage};

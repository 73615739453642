import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Checkbox, Input, Radio} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";
import {v4 as uuidV4} from "uuid";

import Button from "~/components/button";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {ChecklistItemForm, useCheckListForms} from "~/data/check/form/use-checklist-form";
import {useUser} from "~/data/user";

import {NewFormProps} from "./new-form.interface";

import styles from "./new-form.modal.module.scss";

const cx = classNames.bind(styles);

const {TextArea} = Input;

const NewFormModal = ({onClose}: NewFormProps) => {
    const [division, setDivision] = useState<string>("일일");
    const [name, setName] = useState<string>("");
    const [note, setNote] = useState<string>("");
    const [items, setItems] = useState<Array<ChecklistItemForm>>([]);

    const {user} = useUser();
    const {type} = useParams<{type: "checklist" | "extra"}>();
    const {addCheckListRequest} = useCheckListForms(user!.buildingId, type as string);

    const title = useMemo(() => {
        if (type === "checklist") return "체크리스트";
        else if (type === "extra") return "추가점검";
        else if (type === "normal") return "일반점검";
    }, [type]);

    const addChecklist = () => {
        setItems([
            ...items,
            {
                orderNumber: items.length + 1,
                imageYn: false,
                valueYn: false,
                checkFormItemName: "",
                checkFormItemId: uuidV4(),
            },
        ]);
    };

    const handleSubmit = () => {
        const set = new Set(items.map((item: ChecklistItemForm) => item.checkFormItemName));
        if (set.size === items.length) {
            Swal.fire({
                title: "저장하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "저장하기",
                cancelButtonText: "취소하기",
            }).then((result) => {
                if (result.isConfirmed) {
                    addCheckListRequest({
                        division: division,
                        checkFormName: name,
                        note: note,
                        checkFormItemList: items.map((item) => {
                            return {
                                orderNumber: item.orderNumber,
                                imageYn: item.imageYn,
                                valueYn: item.valueYn,
                                checkFormItemName: item.checkFormItemName,
                            };
                        }),
                    })
                        .submit()
                        .then((res) => Swal.fire("저장되었습니다."))
                        .catch(() => Swal.fire("오류가 발생했습니다.\n잠시 후 다시 시도해 주세요."))
                        .finally(() => onClose());
                }
            });
        } else {
            Swal.fire({
                title: "중복된 항목이 있습니다",
                text: "제목이 중복되지 않게 작성해 주시기 바랍니다.",
                confirmButtonText: "확인",
            });
        }
    };

    const handleMove = (index: number, n: number) => {
        const newIndex = index + n;
        if (newIndex < 0 || newIndex >= items.length) {
            return;
        }
        const newItems = [...items];
        [newItems[index], newItems[newIndex]] = [newItems[newIndex], newItems[index]];
        setItems(newItems);
    };

    return (
        <SideModal onClose={onClose} size="normal" confirmLabel={"저장하기"} onConfirm={handleSubmit}>
            <PageTitle>{title} 추가하기</PageTitle>
            <div className={cx("description")}>
                <p>신규 {title} 추가하기.</p>
            </div>
            <div className={cx("form-container")}>
                <Input
                    placeholder="제목"
                    id="checkFormName"
                    className={cx("name")}
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Radio.Group
                    onChange={(e) => setDivision(e.target.value)}
                    defaultValue={division}
                    className={cx("radio-container")}
                >
                    <Radio value={"일일"}>일일</Radio>
                    <Radio value={"주간"}>주간</Radio>
                    <Radio value={"야간"}>야간</Radio>
                    <Radio value={"주간+야간"}>주간+야간</Radio>
                </Radio.Group>
                <TextArea
                    placeholder="기타"
                    id="error-message"
                    className={cx("note")}
                    defaultValue={note}
                    onChange={(e) => setNote(e.target.value)}
                />
                <table className={cx("table")}>
                    <colgroup>
                        <col width="5%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="70%" />
                        <col width="10%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th>순번</th>
                            <th>이미지</th>
                            <th>값</th>
                            <th>제목</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={item.checkFormItemId}>
                                <td>
                                    <button className={cx("icon-button")} onClick={() => handleMove(index, -1)}>
                                        <FontAwesomeIcon icon={faChevronUp} className={cx("icon")} />
                                    </button>
                                    <br />
                                    <button className={cx("icon-button")} onClick={() => handleMove(index, +1)}>
                                        <FontAwesomeIcon icon={faChevronDown} className={cx("icon")} />
                                    </button>
                                </td>
                                <td>{index + 1}</td>
                                <td>
                                    <Checkbox
                                        key={uuidV4()}
                                        onChange={(value) => {
                                            setItems((prevItems) => {
                                                const newItems = [...prevItems];
                                                newItems[index].imageYn = value.target.checked;
                                                return newItems;
                                            });
                                        }}
                                        checked={item.imageYn}
                                    />
                                </td>
                                <td>
                                    <Checkbox
                                        key={uuidV4()}
                                        onChange={(value) => {
                                            setItems((prevItems) => {
                                                const newItems = [...prevItems];
                                                newItems[index].valueYn = value.target.checked;
                                                return newItems;
                                            });
                                        }}
                                        checked={item.valueYn}
                                    />
                                </td>
                                <td>
                                    <Input
                                        placeholder="제목"
                                        id="checkFormName"
                                        className={cx("title")}
                                        onChange={(value) => {
                                            setItems((prevItems) => {
                                                const newItems = [...prevItems];
                                                newItems[index].checkFormItemName = value.target.value;
                                                return newItems;
                                            });
                                        }}
                                        defaultValue={item.checkFormItemName}
                                    />
                                </td>
                                <td>
                                    <Button
                                        label={"삭제"}
                                        color="signature"
                                        size="small"
                                        onClick={() => {
                                            setItems(items.filter((_, idx) => idx !== index));
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Button label={"추가하기"} color="primary" onClick={addChecklist} />
            </div>
        </SideModal>
    );
};

export {NewFormModal};

import {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {DatePicker, InputNumber, Pagination, Spin, Table} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title/page-title";
import {TrainerReserveFundList} from "~/data/cost/trainer/cost-trainer-list.interface";
import {useCostTrainerFund} from "~/data/cost/trainer/use-cost-trainer-fund";
import {useCostTrainerList} from "~/data/cost/trainer/use-cost-trainer-list";
import {httpRequest} from "~/fetch/common/http-request";
import {ShopCascaderBuildingResponse} from "~/hooks/use-shop-cascader/use-shop-cascader.interface";
import {formatCost} from "~/utills/formatCost";

import {FilterDateType} from "../cost-trainer.interface";
import {CostTrainerModalProps} from "./cost-trainer.modal.interface";

import styles from "./cost-trainer.modal.module.scss";

const cx = classNames.bind(styles);

const CostTrainerModal = ({close, division, submitTableList, trainerSectorId, year, month}: CostTrainerModalProps) => {
    const [trainerCost, setTrainerCost] = useState<number | null>(0);
    const [data, setData] = useState<TrainerReserveFundList | null>(null);
    const [page, setPage] = useState<number>(1);
    const [date, setDate] = useState<FilterDateType>({
        year: year,
        month: month,
    });
    const modalRef = useRef<HTMLDivElement>(null);

    const [submitTableListModal, tableList, isTableListLoading] = useCostTrainerList({
        pageNo: page,
        ...date,
    });

    const [submitFund] = useCostTrainerFund({
        year: date.year?.format("YYYY") ?? null,
        month: date.month?.format("MM") ?? null,
        trainerReserveFund: trainerCost,
        trainerSectorId,
    });

    useEffect(() => {
        submitTableListModal();
    }, [page]);

    useEffect(() => {
        if (tableList) {
            setData(
                tableList.trainerReserveFundList.filter((item) => item.trainerSectorId === trainerSectorId)[0] ?? null,
            );
        }
    }, [tableList, trainerSectorId]);

    useEffect(() => {
        setTrainerCost(data?.trainerReserveFund ?? 0);
    }, [data]);

    return createPortal(
        <SideModal
            size="large"
            confirmLabel="저장"
            onConfirm={() => {
                if (date.year === null || date.month === null) {
                    Swal.fire({text: "연도, 월을 반드시 입력해야 합니다.", confirmButtonText: "확인"});
                } else {
                    Swal.fire({
                        text: "저장하시겠습니까?",
                        confirmButtonText: "확인",
                        showCancelButton: true,
                        cancelButtonText: "취소",
                    }).then((info) => {
                        if (info.isConfirmed) {
                            submitFund();
                            setTimeout(submitTableList, 500);
                            close();
                        }
                    });
                }
            }}
            onClose={close}
        >
            {isTableListLoading ? (
                <div className={cx("loading")}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <div ref={modalRef} />
                    <PageTitle>트레이너 예비비 수정</PageTitle>
                    <div className={cx("modal-filter-area")}>
                        연도
                        <DatePicker
                            placeholder={"연도 선택"}
                            picker={"year"}
                            style={{width: "120px"}}
                            status={date.year === null && date.month !== null ? "error" : ""}
                            value={date.year}
                            onChange={(e) => {
                                if (e === null) {
                                    setDate((prev) => ({...prev, year: e, month: null}));
                                } else {
                                    setDate((prev) => ({...prev, year: e}));
                                }
                            }}
                        />
                        월
                        <DatePicker
                            placeholder={"월 선택"}
                            popupClassName="no-header"
                            picker={"month"}
                            format={"MM"}
                            style={{width: "120px"}}
                            status={date.year !== null && date.month === null ? "error" : ""}
                            value={date.month}
                            onChange={(e) => setDate((prev) => ({...prev, month: e}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                            )}
                            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                        />
                        <div style={{marginLeft: "auto"}}>
                            <Button
                                label="조회"
                                color={"primary"}
                                onClick={() => {
                                    if (
                                        (date.year === null && date.month !== null) ||
                                        (date.year !== null && date.month === null)
                                    ) {
                                        Swal.fire({
                                            html: "연도, 월 모두 선택하거나 <br/> 선택하지 않아야 합니다.",
                                            confirmButtonText: "확인",
                                        });
                                    } else {
                                        submitTableListModal();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={cx("form-container")}>
                        <div className={cx("subtitle")}>종합</div>
                        <div className={cx("total-table")}>
                            <div className={cx("col")}>트레이너 권역</div>
                            <div className={cx("col")}>트레이너 예비비</div>
                            <div className={cx("col")}>배정 금액</div>
                            <div className={cx("col")}>요청 금액</div>
                            <div className={cx("col")}>차액</div>
                            <div>{data?.trainerSectorName}</div>
                            <div>
                                <InputNumber
                                    controls={false}
                                    className={cx("input-number")}
                                    style={{width: "90%", textAlign: "center"}}
                                    value={trainerCost}
                                    formatter={(value) => formatCost(value)}
                                    onChange={(e) => setTrainerCost(e)}
                                />
                            </div>
                            <div>{data?.allocationAmount.toLocaleString() ?? 0}</div>
                            <div>{data?.requestAmount.toLocaleString() ?? 0}</div>
                            <div>{data?.differenceAmount.toLocaleString() ?? 0}</div>
                        </div>
                        <div className={cx("subtitle")}>
                            <span className={cx("subtitle-text")}>지점별</span>
                        </div>
                        <Table
                            pagination={false}
                            rowKey={"num"}
                            dataSource={data?.trainerReserveFundBuildingList}
                            columns={[
                                {
                                    title: "No",
                                    dataIndex: "num",
                                    key: "num",
                                    align: "center",
                                },
                                {
                                    title: "지점명",
                                    dataIndex: "buildingName",
                                    key: "buildingName",
                                    align: "center",
                                },
                                {
                                    title: "기타유형자산",
                                    dataIndex: "totalOtherTangible",
                                    key: "totalOtherTangible",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                },
                                {
                                    title: "안전시설공사",
                                    dataIndex: "totalSafetyFacility",
                                    key: "totalSafetyFacility",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                },
                                {
                                    title: "등기구구입대",
                                    dataIndex: "totalLightFixture",
                                    key: "totalLightFixture",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                },
                                {
                                    title: "수선유지비품",
                                    dataIndex: "totalRepairMaintenance",
                                    key: "totalRepairMaintenance",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                },
                                {
                                    title: "합계",
                                    dataIndex: "subtotal",
                                    key: "subtotal",
                                    render: (value) => (value ? value.toLocaleString() : 0),
                                },
                            ]}
                        />
                    </div>
                </>
            )}
        </SideModal>,
        document.body,
    );
};

export {CostTrainerModal};

import React from "react";
import classNames from "classnames/bind";

import {ButtonProps} from "./button.interface";

import styles from "./button.module.scss";
const cx = classNames.bind(styles);

function Button({
    label,
    color = "signature",
    onClick,
    variant = "contained",
    disabled = false,
    fullWidth = false,
    size = "medium",
}: ButtonProps) {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) onClick(event);
    };

    return (
        <button
            onClick={handleClick}
            disabled={disabled}
            className={cx("container", variant, color, {
                fullWidth: fullWidth,
                large: size === "large",
            })}
        >
            {label}
        </button>
    );
}

export default Button;

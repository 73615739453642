import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {
    CostTrainerExcelDownloadRequest,
    CostTrainerListRequest,
    CostTrainerListResponse,
} from "./cost-trainer-list.interface";

// 테이블 리스트
const useCostTrainerList = (
    config: CostTrainerListRequest,
): [() => Promise<void>, CostTrainerListResponse | null, boolean, any] => {
    const [response, setResponse] = useState<CostTrainerListResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const {pageNo = 1, year = null, month = null} = config;
        return httpRequest<CostTrainerListRequest, CostTrainerListResponse>({
            method: "GET",
            url: "/lotte/cost/repair/trainer/list",
            params: {
                pageNo,
                year: year?.format("YYYY") ?? null,
                month: month?.format("MM") ?? null,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return [submit, response, isLoading, error];
};

//ExcelDownload
const useCostTrainerExcelDownload = (config: CostTrainerExcelDownloadRequest) => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<CostTrainerExcelDownloadRequest, Blob>({
            method: "GET",
            url: "/lotte/cost/repair/trainer/excelDown",
            params: {
                year: config.year?.format("YYYY") ?? null,
                month: config.month?.format("MM") ?? null,
            },
            responseType: "blob",
        });
    }, [config]);

    const submit = async () => {
        try {
            const response = await request.submit();

            // 파일 다운로드 처리
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // 파일명추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";
            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = matches[1].trim().replace(/['"]/g, "");
                }
            }
            link.setAttribute("download", decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
            setError(e);
        }
    };

    return [submit, error];
};

export {useCostTrainerExcelDownload, useCostTrainerList};

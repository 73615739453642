import {UploadRequestOption} from "rc-upload/lib/interface";

import {httpRequest} from "~/fetch/common/http-request";

function singleFileUpload({file, action, onProgress, onSuccess, onError}: UploadRequestOption<any>) {
    const request = httpRequest<
        any,
        {
            responseData: {
                filePath: string;
                fileName: string;
                base64Image: string;
            };
        }
    >({
        url: `/lotte/file/upload`,
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: ({loaded, total}) => {
            if (loaded && total && onProgress) {
                onProgress({percent: (loaded / total) * 100});
            }
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        transformRequest: () => {
            const body = new FormData();
            body.append("uploadFile", file);

            return body;
        },
    });

    request
        .submit()
        .then((res) => onSuccess && onSuccess(res.data.responseData))
        .catch((e) => onError && onError(e));
}

function checkFileUpload(
    checkItemHistoryId: any,
    {file, action, onProgress, onSuccess, onError}: UploadRequestOption<any>,
) {
    const request = httpRequest<
        any,
        {
            responseData: {
                filePath: string;
                fileName: string;
                base64Image: string;
            };
        }
    >({
        url: `/mobileCheck/upload/${checkItemHistoryId}`,
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: ({loaded, total}) => {
            if (loaded && total && onProgress) {
                onProgress({percent: (loaded / total) * 100});
            }
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        transformRequest: () => {
            const body = new FormData();
            body.append("uploadFile", file);

            return body;
        },
    });

    request
        .submit()
        .then((res) => onSuccess && onSuccess(res.data.responseData))
        .catch((e) => onError && onError(e));
}

export {checkFileUpload, singleFileUpload};

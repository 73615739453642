import {ChangeEvent, useEffect, useState} from "react";
import classNames from "classnames/bind";

import Button from "~/components/button/button";

import {MergeQuestionInput} from "./merge-question-input";
import {MergeQuestionListProps} from "./merge-question-list.interface";

import styles from "./merge-question-list.module.scss";

const cx = classNames.bind(styles);

const MergeQuestionList = ({inputData, setInputData}: MergeQuestionListProps) => {
    const [questionList, setQuestionList] = useState<Array<JSX.Element>>([]);

    const handleDelete = (name: string) => {
        setInputData((prev) => ({
            ...prev,
            questionDTOList: prev.questionDTOList.filter((item) => item.name !== name),
        }));
    };

    useEffect(() => {
        setQuestionList(
            inputData.questionDTOList.map((data, index) => (
                <div className={cx("question")} key={index}>
                    <MergeQuestionInput name={data.name} setInput={setInputData} value={data.value} input={inputData} />
                    <Button label="삭제" onClick={() => handleDelete(data.name)} />
                </div>
            )),
        );
    }, [inputData.questionDTOList]);

    return <>{questionList}</>;
};

export {MergeQuestionList};

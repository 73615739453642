import {useEffect, useState} from "react";
import {Input} from "antd";
import classNames from "classnames/bind";

import {MergeQuestionInputProps} from "./merge-question-list.interface";

import styles from "./merge-question-list.module.scss";

const cx = classNames.bind(styles);

const MergeQuestionInput = ({setInput, name, value: initialValue, input, ...props}: MergeQuestionInputProps) => {
    const [value, setValue] = useState<string>(initialValue);

    useEffect(() => {
        setInput((prev) => ({
            ...prev,
            questionDTOList: prev.questionDTOList.map((item) => (item.name === name ? {...item, value} : item)),
        }));
    }, [value, name, setInput]);

    useEffect(() => {
        setValue(initialValue);
    }, [input.questionDTOList.length]);

    return (
        <Input className={cx("question-input")} value={value} {...props} onChange={(e) => setValue(e.target.value)} />
    );
};

export {MergeQuestionInput};

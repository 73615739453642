import {useEffect, useMemo, useState} from "react";
import {Cascader} from "antd";

import {httpRequest} from "~/fetch/common/http-request";

import {
    ShopCascaderBuildingResponse,
    ShopCascaderRefinedData,
    ShopCascaderSectorResponse,
    ShopCascaderTrainerResponse,
} from "./use-shop-cascader.interface";

const useShopCascader = (width: number, initialState?: string[]) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<Array<string>>(initialState ? initialState : ["ALL"]);
    const [data, setData] = useState<{
        sector: ShopCascaderSectorResponse[];
        trainer: ShopCascaderTrainerResponse[];
        building: ShopCascaderBuildingResponse[];
    }>({
        sector: [],
        trainer: [],
        building: [],
    });
    const [selectList, setSelectList] = useState<
        Array<{
            value: string;
            label: string;
            children?: Array<{value: string; label: string; children?: Array<{value: string; label: string}>}>;
        }>
    >([]);

    // sector
    const sectorReq = useMemo(() => {
        return httpRequest<null, ShopCascaderSectorResponse[]>({
            url: "/sector/list",
        });
    }, []);
    // trainer
    const trainerReq = useMemo(() => {
        return httpRequest<null, ShopCascaderTrainerResponse[]>({
            url: "/common/trainerSector",
        });
    }, []);
    // building
    const buildingReq = useMemo(() => {
        return httpRequest<null, ShopCascaderBuildingResponse[]>({
            url: "/common/separate",
        });
    }, []);

    const submit = async () => {
        try {
            setIsLoading(true);
            const sectors = await sectorReq.submit();
            const trainers = await trainerReq.submit();
            const buildings = await buildingReq.submit();
            setData({
                sector: sectors.data,
                trainer: trainers.data,
                building: buildings.data,
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        submit();
    }, []);

    useEffect(() => {
        const sector = data.sector.map((item) => ({
            value: item.sectorId.toString(),
            label: item.sectorName,
            children: [
                {label: "전체", value: "0"},
                ...data.building
                    .filter((building) => building.sectorId === item.sectorId)
                    .map((building) => ({
                        value: building.buildingId.toString(),
                        label: building.buildingName,
                    })),
            ],
        }));

        const trainer = data.trainer.map((item) => ({
            value: item.trainerSectorId.toString(),
            label: item.trainerSectorName,
            children: [
                {label: "전체", value: "0"},
                ...data.building
                    .filter((building) => building.trainerSectorId === item.trainerSectorId)
                    .map((building) => ({
                        value: building.buildingId.toString(),
                        label: building.buildingName,
                    })),
            ],
        }));

        setSelectList([
            {
                value: "ALL",
                label: "전체",
            },
            {
                value: "SECTOR",
                label: "부문",
                children: sector,
            },
            {
                value: "TRAINER",
                label: "트레이너 권역",
                children: trainer,
            },
        ]);
    }, [data]);

    const handleReset = () => {
        setSelected([]);
    };

    const ShopCascader = () => {
        return (
            <Cascader
                style={{width: width, zIndex: 999}}
                loading={isLoading}
                value={selected}
                options={selectList}
                onChange={(e) => setSelected(e)}
                allowClear={false}
                getPopupContainer={(trigger) => trigger.parentNode}
            />
        );
    };

    return {
        selected,
        setSelected,
        reset: handleReset,
        ShopCascader,
    };
};

// request용 정제
const useShopCascaderRefined = (value: string[]) => {
    const [refinedData, setRefinedData] = useState<ShopCascaderRefinedData | null>(null);

    useEffect(() => {
        if (value.length) {
            switch (value[0]) {
                case "ALL":
                    setRefinedData(null);
                    break;
                case "SECTOR":
                    setRefinedData({
                        sectorId: value[1],
                        ...(value[2] !== "0" && {buildingId: value[2]}),
                    });
                    break;
                case "TRAINER":
                    setRefinedData({
                        trainerSectorId: value[1],
                        ...(value[2] !== "0" && {buildingId: value[2]}),
                    });
                    break;
                default:
                    setRefinedData(null);
                    break;
            }
        }
    }, [value]);

    return refinedData;
};

export {useShopCascader, useShopCascaderRefined};

import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import {Input, Table} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useMobileMachineBoardList, useMobileProcessData} from "~/data/machine/board/use-machine";

import styles from "./search.module.scss";

const cx = classNames.bind(styles);

const MobileEquipmentProcessListPage = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [searchValue, setSearchValue] = useState<string>("");
    const [originalList, setOriginalList] = useState<JSX.Element[]>([]);
    const [displayList, setDisplayList] = useState<JSX.Element[]>([]);

    const [submit, responseList, isLoading] = useMobileMachineBoardList({searchProcessCode: params.searchProcessCode});
    const {response} = useMobileProcessData();
    const processName = response.filter((i) => i.code === params.searchProcessCode)[0]?.name;

    useEffect(() => {
        submit();
    }, [params]);
    useEffect(() => {
        if (responseList?.resultList) {
            setOriginalList(responseList?.resultList);
            setDisplayList(responseList?.resultList);
        }
    }, [responseList]);
    useEffect(() => {
        if (searchValue === "") {
            setDisplayList(originalList);
        }
    }, [searchValue, originalList]);

    const onClickSearchValue = () => {
        const lowerCaseSearchValue = searchValue.toLowerCase();
        const filterValue = originalList.filter((item) =>
            Object.values(item).some((value) => String(value).toLowerCase().includes(lowerCaseSearchValue)),
        );
        setDisplayList(filterValue);
    };
    const onClickOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onClickSearchValue();
            (e.target as HTMLInputElement).blur();
        }
    };

    return (
        <div>
            <div className={cx("inner-container")}>
                <h1 className={cx("title")}>공정별 설비 현황</h1>
                <CloseOutlined
                    className={cx("close-button")}
                    onClick={() => {
                        navigate("/mobile/equipment");
                    }}
                />
            </div>
            {/* 아래는 검색기능 사용시 주석 해제 */}
            {/* <div className={cx("inner-container")}> 
                <Input
                    className={cx("input")}
                    placeholder="검색어를 입력해 주세요."
                    onChange={(e) => setSearchValue(e.target.value)}
                    allowClear={{clearIcon: <CloseOutlined className={cx("input-reset-button")} />}}
                    onKeyUp={onClickOnKeyUp}
                />
                <button className={cx("button")} onClick={onClickSearchValue}>
                    검색
                </button>
            </div> */}
            <div className={cx("list-container")}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <h2>{processName}</h2>
                    <div className={cx("count")}>( {displayList?.length.toLocaleString()}건 )</div>
                </div>
                <Table
                    className={cx("table")}
                    loading={isLoading}
                    dataSource={displayList?.map((row: any) => {
                        row["key"] = row.machineId;
                        return row;
                    })}
                    pagination={false}
                    onRow={(data, index) => ({
                        onClick: () => {
                            navigate(
                                `/mobile/equipment/detail/${data!.companyId}/${data!.buildingId}/${data!.machineId}`,
                            );
                        },
                    })}
                    columns={[
                        {
                            title: "카테고리",
                            dataIndex: "machineCategoryName",
                            key: "machineCategoryName",
                            align: "center",
                            width: "20%",
                        },
                        {
                            title: "장비명",
                            dataIndex: "machineName",
                            key: "machineName",
                            align: "center",
                            ellipsis: true,
                        },
                        {
                            title: "제조사",
                            dataIndex: "machineManufactureCompany",
                            key: "machineManufactureCompany",
                            align: "center",
                        },
                        {
                            title: "설치연월",
                            dataIndex: "machineInstallDate",
                            key: "machineInstallDate",
                            align: "center",
                            width: "20%",
                            render: (date) => (dayjs(date).isValid() ? date : ""),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {MobileEquipmentProcessListPage};

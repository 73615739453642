import React from "react";
import {DatePicker} from "antd";
import classNames from "classnames/bind";

import {CnRangePickerProps} from "./cn-range-picker.interface";

import styles from "./cn-range-picker.module.scss";

const cx = classNames.bind(styles);

const {RangePicker} = DatePicker;

const CnRangePicker = ({label, ...props}: CnRangePickerProps) => {
    return (
        <div>
            <div className={cx("container")}>
                <label className={cx("label")}>{label}</label>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <RangePicker className={cx("range-picker")} {...props} />
            </div>
        </div>
    );
};

export {CnRangePicker};

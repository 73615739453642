import React from "react";
import classNames from "classnames/bind";

import {FilterAreaProps} from "./filter-area.interface";

import styles from "./filter-area.module.scss";

const cx = classNames.bind(styles);

const FilterArea = ({children}: FilterAreaProps) => {
    return <div className={cx("container")}>{children}</div>;
};

export {FilterArea};

import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames/bind";

import Button from "~/components/button";
import {useUser} from "~/data/user";
import {usePlatform} from "~/utills/use-platform";

import LottemartCi from "./lottemart-ci.png";
import UpdateApp from "./updateApp.png";

import styles from "./login.page.module.scss";

const cx = classNames.bind(styles);

const LoginPage = () => {
    const [id, setId] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isPopup, setIsPopup] = useState<boolean>(false);
    const navigate = useNavigate();

    const {user, login} = useUser();
    const {platform} = usePlatform();

    useEffect(() => {
        if (user) navigate("/");
    }, [user]);

    const handleChangeId = (event: ChangeEvent<HTMLInputElement>) => {
        setIsError(false);
        setId(event.target.value);
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setIsError(false);
        setPassword(event.target.value);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const handleLoginButton = async () => {
        try {
            setIsError(false);
            await login(id, password, platform);
        } catch (e) {
            setIsError(true);
        }
    };

    return (
        <div className={cx("container")}>
            <div className={cx("background-layer")} />
            <div className={cx("blur-layer")} />
            <div className={cx("login-container")}>
                <div className={cx("logo-container")}>
                    <img src={LottemartCi} alt="롯데마트 CI" className={cx("logo")} />
                </div>
                <div className={cx("text-container")}>
                    <p className={cx("text")}>FEMS 시설관리 시스템</p>
                </div>
                <div className={cx("form-container")}>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            className={cx("input")}
                            placeholder="ID"
                            value={id}
                            onChange={handleChangeId}
                        />
                        <input
                            type="password"
                            className={cx("input")}
                            placeholder="PASSWORD"
                            value={password}
                            onChange={handleChangePassword}
                        />
                        <Button label="로그인" fullWidth onClick={handleLoginButton} />
                    </form>
                    <p className={cx("error", {on: isError})}>
                        아이디 또는 비밀번호에 오류가 있습니다.
                        <br />
                        오류가 지속될 경우 관리자에 문의 바랍니다.
                    </p>
                </div>
                <button className={cx("app-download")} onClick={() => setIsPopup(true)}>
                    점검인 APP 다운로드
                </button>
                {isPopup && (
                    <div className={cx("app-download-popup")}>
                        <img src={UpdateApp} />
                        <p>
                            <a
                                href="https://lottemartfms.com/app/lottemartfms.v1.0.3.apk"
                                target="_blank"
                                rel="noreferrer"
                                download="lottemartfms.v1.0.3.apk"
                            >
                                앱 다운로드
                            </a>
                            <button onClick={() => setIsPopup(false)}>닫기</button>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export {LoginPage};

import React from "react";
import {Select} from "antd";
import classNames from "classnames/bind";

import type {CnSelectProps} from "./cn-select.interface";

import styles from "./cn-select.module.scss";

const cx = classNames.bind(styles);

const CnSelect = (props: CnSelectProps) => {
    return (
        <div className={cx("container")}>
            <label className={cx("label")}>{props.label}</label>
            <Select className={cx("select")} {...props} />
        </div>
    );
};

export {CnSelect};

import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {FacilityListRequest, FacilityListResponse} from "./use-facility-list.interface";

// 설비 리스트
const useFacilityList = (buildingId: number): [() => Promise<void>, null | FacilityListResponse[], boolean, any] => {
    const [response, setResponse] = useState<null | FacilityListResponse[]>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<FacilityListRequest, FacilityListResponse[]>({
            method: "GET",
            url: "/common/machines",
            params: {
                buildingId,
            },
        });
    }, [buildingId]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return [submit, response, isLoading, error];
};

export {useFacilityList};

import React, {useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {
    CheckList,
    CheckListFormDetail,
    CheckListItem,
    ChecklistItemForm,
    NewCheckListForm,
    NewCheckListFormResponse,
    UpdateCheckListForm,
    UpdateCheckListFormResponse,
} from "./use-checklist-form.interface";

function useCheckListForms(buildingId: number, type: string) {
    const [checklistForm, setChecklistForm] = useState<Array<CheckList>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<undefined, CheckListFormDetail>({
            url: `/buildings/${buildingId}/checks-form`,
            params: {
                limit: 500,
                offset: 1,
                category: type === "normal" ? "NORMAL" : "ADD",
            },
        });
    }, []);

    useEffect(() => getList(), [request]);

    const getList = () => {
        setLoading(true);
        request
            .submit()
            .then((response) => setChecklistForm(response.data.checkFormList))
            .finally(() => {
                setLoading(false);
            });
    };

    const addCheckListRequest = (data: NewCheckListForm) => {
        return httpRequest<NewCheckListFormResponse, undefined>({
            method: "POST",
            url: `/buildings/${buildingId}/checks-form`,
            data: {...data, category: type === "normal" ? "NORMAL" : "ADD"},
        });
    };

    const updateCheckListRequest = (data: UpdateCheckListForm, checkFormId: number) => {
        return httpRequest<UpdateCheckListFormResponse, undefined>({
            method: "PUT",
            url: `/buildings/${buildingId}/checks-form/${checkFormId}`,
            data: {...data, category: type === "normal" ? "NORMAL" : "ADD"},
        });
    };

    return {
        loading,
        refresh: getList,
        checklistForms: checklistForm,
        totalCount: checklistForm.length,
        addCheckListRequest: addCheckListRequest,
        updateCheckListRequest: updateCheckListRequest,
    };
}

export {useCheckListForms};
export type {CheckList, CheckListFormDetail, CheckListItem, ChecklistItemForm};

import React from "react";
import {Pagination, Table} from "antd";
import classNames from "classnames/bind";

import {CustomLabelTableProps} from "./custom-label-table.interface";

import styles from "./custom-label-table.module.scss";

const cx = classNames.bind(styles);

const CustomLabelTable = ({
    label,
    totalCount,
    noPagination,
    perPage,
    page,
    onPageChange,
    ...props
}: CustomLabelTableProps) => {
    const handlePaginationChange = (page: number) => {
        onPageChange(page);
    };
    return (
        <div className={cx("container")}>
            <div className={cx("label")}>{label}</div>
            <Table {...props} className={cx("table")} />
            {!noPagination && (
                <div className={cx("pagination-container")}>
                    <Pagination
                        current={page}
                        total={totalCount}
                        pageSize={perPage}
                        showSizeChanger={false}
                        onChange={handlePaginationChange}
                    />
                </div>
            )}
        </div>
    );
};

export {CustomLabelTable};

import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {BoardListRequest, BoardListResponse} from "./use-board-list.interface";

const useBoardList = (config: BoardListRequest): [() => Promise<void>, BoardListResponse | null, boolean, any] => {
    const [response, setResponse] = useState<BoardListResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<BoardListRequest, BoardListResponse>({
            method: "GET",
            url: "/commonBoard/commonBoardList",
            params: {
                category: config.category,
                startDate: config.startDate,
                endDate: config.endDate,
                status: config.status,
                title: config.title,
                content: config.content,
                comment: config.comment,
                page: config.page,
                perPage: config.perPage,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return [submit, response, isLoading, error];
};

export {useBoardList};

import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {
    faCalculator,
    faChalkboard,
    faChartLine,
    faCircleExclamation,
    faClipboardCheck,
    faClipboardList,
    faClone,
    faCloudArrowUp,
    faEarthAsia,
    faHelmetSafety,
    faServer,
    faShop,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

interface Menu {
    name: string;
    path?: string;
    isToggle?: boolean;
    icon?: IconDefinition;
    children?: Array<Menu>;
}

const quickMenu: Array<Menu> = [
    {
        name: "대시보드",
        path: "/",
        icon: faChartLine,
    },
    {
        name: "이전시스템",
        icon: faClone,
    },
    // {
    //     name: "공지사항",
    //     path: "/notice",
    //     icon: faCircleExclamation,
    // },
    // {
    //     name: "캘린더",
    //     path: "/calendar",
    //     icon: faCalendar,
    // },
    // {
    //     name: "점포현황",
    //     path: "/shop",
    //     icon: faShop,
    // },
    // {
    //     name: "일일 업무계획",
    //     path: "/plan",
    //     icon: faClipboardList,
    // },
    // {
    //     name: "자료실",
    //     path: "/cloud",
    //     icon: faCloudArrowUp,
    // },
];

const member: Array<Menu> = [
    {
        name: "대시보드",
        path: "/",
        icon: faEarthAsia,
    },
    {
        name: "취합게시판",
        path: "/merge",
        icon: faClipboardCheck,
    },
    {
        name: "설비",
        icon: faServer,
        isToggle: true,
        children: [
            {
                name: "설비현황",
                path: "/equipment",
            },
        ],
    },

    {
        name: "점검",
        icon: faHelmetSafety,
        isToggle: true,
        children: [
            {
                name: "승강기점검",
                path: "/check/elevator",
            },
            {
                name: "법정점검",
                children: [
                    {
                        name: "지시하기",
                        path: "/check/legal/instruction",
                    },
                    {
                        name: "지시이력",
                        path: "/check/legal/history",
                    },
                    {
                        name: "점검이력",
                        path: "/check/legal/report",
                    },
                    {
                        name: "양식관리",
                        path: "/check/legal/form",
                    },
                    // {
                    //     name: "결재하기",
                    //     path: "/check/legal/approval",
                    // },
                ],
            },
            {
                name: "체크리스트",
                children: [
                    {
                        name: "지시하기",
                        path: "/check/checklist/instruction",
                    },
                    {
                        name: "지시이력",
                        path: "/check/checklist/history",
                    },
                    {
                        name: "점검이력",
                        path: "/check/checklist/report",
                    },
                    {
                        name: "양식관리",
                        path: "/check/checklist/form",
                    },
                    // {
                    //     name: "결재하기",
                    //     path: "/check/checklist/approval",
                    // },
                ],
            },
            {
                name: "추가점검",
                children: [
                    {
                        name: "지시하기",
                        path: "/check/extra/instruction",
                    },
                    {
                        name: "지시이력",
                        path: "/check/extra/history",
                    },
                    {
                        name: "점검이력",
                        path: "/check/extra/report",
                    },
                    {
                        name: "양식관리",
                        path: "/check/extra/form",
                    },
                ],
            },
            {
                name: "일반점검",
                children: [
                    {
                        name: "지시하기",
                        path: "/check/normal/instruction",
                    },
                    {
                        name: "지시이력",
                        path: "/check/normal/history",
                    },
                    {
                        name: "점검이력",
                        path: "/check/normal/report",
                    },
                    {
                        name: "양식관리",
                        path: "/check/normal/form",
                    },
                ],
            },
        ],
    },
    {
        name: "예산",
        icon: faCalculator,
        isToggle: true,
        children: [
            {
                name: "통합수선비",
                path: "/cost/integrated",
            },
            {
                name: "수선비품/등기구",
                path: "/cost/maintenance",
            },
            {
                name: "시설보수비 월간계획",
                path: "/cost/facility",
            },
            {
                name: "트레이너 예비비",
                path: "/cost/trainer",
            },
            {
                name: "월간계획관리",
                path: "/cost/monthly",
            },
        ],
    },
    {
        name: "게시판",
        icon: faChalkboard,
        isToggle: true,
        children: [
            {
                name: "공지사항",
                path: "/board/notice",
            },
            {
                name: "문의게시판",
                path: "/board/inquiry",
            },
        ],
    },
];

const hiddenMenuPathForManager: Array<string> = ["/cost/integrated", "/cost/trainer"];
const hiddenMenuPathForMember: Array<string> = [
    "/check/legal/instruction",
    "/check/legal/history",
    "/check/legal/form",
    "/check/checklist/instruction",
    "/check/checklist/history",
    "/check/checklist/form",
    "/check/extra/instruction",
    "/check/extra/history",
    "/check/extra/form",
    "/check/normal/instruction",
    "/check/normal/history",
];
const hiddenMenuPathNameForMember: Array<string> = ["일반점검"];

const mobileMenu: Menu[] = [
    {
        name: "승강기점검",
        path: "/mobile/check/elevator",
    },
    {
        name: "법정점검",
        path: "/mobile/check/list?type=legal",
    },
    {
        name: "체크리스트",
        path: "/mobile/check/list?type=checklist",
    },
    {
        name: "추가점검",
        path: "/mobile/check/list?type=extra",
    },
    {
        name: "일반점검",
        path: "/mobile/check/list?type=normal",
    },
    {
        name: "설비현황",
        path: "/mobile/equipment",
    },
];

export type {Menu};

export {hiddenMenuPathForManager, hiddenMenuPathForMember, hiddenMenuPathNameForMember, member, mobileMenu, quickMenu};

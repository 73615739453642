import React from "react";
import classNames from "classnames/bind";

import {PageTitleProps} from "./page-title.interface";

import styles from "./page-title.module.scss";
const cx = classNames.bind(styles);

export const PageTitle = ({children}: PageTitleProps) => {
    return <h1 className={cx("title")}>{children}</h1>;
};

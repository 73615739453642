import {useEffect, useMemo, useState} from "react";

import {ReportItem, ReportResponse} from "~/data/check/report/use-report.interface";
import {axiosInstance} from "~/fetch/common/axios-instance";
import {httpRequest} from "~/fetch/common/http-request";

function useReport(
    type: string,
    buildingId: number | null,
    page: number,
    recordPerPage: number,
    startDate: string,
    endDate: string,
    status: string,
) {
    const [list, setList] = useState<Array<ReportItem>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);

    const request = useMemo(() => {
        let path = "";
        if (type === "legal" || type === "checklist") path = `/reportStatus/userReportStatus`;
        else path = `/buildings/checks/printOut`;

        return httpRequest<
            {
                pageNo: number;
                linePerPage: number;
                category: "LEGAL" | "CHECK" | "ADD" | "NORMAL";
                result: string;
            },
            ReportResponse
        >({
            url: path,
            params: {
                pageNo: page,
                linePerPage: recordPerPage,
                buildingId: buildingId,
                category:
                    type === "legal" ? "LEGAL" : type === "checklist" ? "CHECK" : type === "extra" ? "ADD" : "NORMAL",
                startDate,
                endDate,
                result: status,
            },
        });
    }, [type, buildingId, page, recordPerPage, startDate, endDate, status]);

    const shoot = () => {
        setLoading(true);
        request
            .submit()
            .then((response) => {
                setList(response.data.checkList);
                setTotalCount(response.data.pageInfo.allMaxCnt);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => shoot(), [request]);

    const getReport = (reportListId: number, orderDate: string) => {
        return httpRequest<any, any>({
            url: `/excel/${reportListId}/${orderDate}`,
            headers: {
                Accept: "*/*",
            },
        }).submit();
    };

    const downloadReport = (reportListId: number, orderDate: string, isCheck?: boolean) => {
        window.open(
            axiosInstance.defaults.baseURL + `/excel/${isCheck ? "check/" : ""}${reportListId}/${orderDate}`,
            "_blank",
        );
    };

    return {
        loading,
        list: list,
        totalCount,
        refresh: shoot,
        getReport,
        downloadReport,
    };
}

export {useReport};

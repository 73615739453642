import React, {createContext, ReactNode, useContext, useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";
import {useLocalStorage} from "~/utills/useLocalStorage";

import {User, UserContextInterface} from "./user.interface";

const UserContext = createContext<UserContextInterface>({
    user: null,
    setUser: () => {
        return;
    },
    isLoading: false,
});

const UserProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const {getItem, setItem} = useLocalStorage();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const userDefaultValue = useMemo(() => {
        try {
            const queryString = window.location.search;
            if (queryString) {
                const rawToken = queryString.match(/[?&]token=([^&]+)/)![1];
                const token = decodeURIComponent(rawToken);
                if (token) {
                    setIsLoading(true);
                    httpRequest<undefined, any>({
                        url: "/common/userInfo",
                        headers: {
                            AuthToken: encodeURI(token),
                        },
                    })
                        .submit()
                        .then((res) => {
                            console.log(res.data);
                            setItem("LM_USER", JSON.stringify(res.data));
                            setUser(res.data);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            }

            const userFromLocalStorage = getItem("LM_USER");
            if (!userFromLocalStorage) return null;
            return JSON.parse(userFromLocalStorage);
        } catch (e) {
            return null;
        }
    }, []);

    const [user, setUser] = useState<User | null>(userDefaultValue);

    return <UserContext.Provider value={{user, setUser, isLoading}}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
};

export {UserContext, UserProvider, useUserContext};

import React, {useEffect, useMemo, useState} from "react";
import {DownOutlined} from "@ant-design/icons";
import {Tree, TreeDataNode, TreeProps} from "antd";
import classNames from "classnames/bind";

import {useMemberFromDepartment} from "~/data/shop/use-department";
import {Member, User} from "~/data/shop/use-department.interface";
import {Region, useRegion} from "~/data/shop/use-region";
import {useUser} from "~/data/user";
import {RegionSelectProps} from "~/pages/check/instruction/region-select.interface";

import styles from "./region-select.module.scss";

const cx = classNames.bind(styles);

const RegionSelect = ({onChange, onChangeMember}: RegionSelectProps) => {
    const {user} = useUser();

    const [shop, setShop] = useState<number>(0);

    const {regions} = useRegion(shop);
    const {members} = useMemberFromDepartment(shop);

    useEffect(() => {
        if (user?.buildingId) setShop(user?.buildingId);
    }, [user?.buildingId]);

    const memberTreeData = useMemo<Array<TreeDataNode>>(() => {
        const treeData: TreeDataNode[] = [];

        members.forEach((member) => {
            treeData.push({
                key: member.departmentDto.departmentId,
                title: member.departmentDto.departmentName,
                children: [
                    ...member.userDtoList.map((item) => ({
                        key: item.userId,
                        title: item.name,
                        children: [],
                    })),
                ],
            });
        });

        return treeData;
    }, [members]);

    const treeData = useMemo<Array<TreeDataNode>>(() => {
        const regionMap: {[key: number]: TreeDataNode} = {};

        regions.forEach((region) => {
            regionMap[region.regionId] = {
                key: region.regionId,
                title: region.regionName,
                children: [],
            };
        });

        const treeData: TreeDataNode[] = [];
        regions.forEach((region) => {
            const treeNode = regionMap[region.regionId];
            if (region.parentId !== undefined && regionMap[region.parentId]) {
                regionMap[region.parentId].children!.push(treeNode);
            } else {
                treeData.push(treeNode);
            }
        });

        return treeData;
    }, [regions]);

    function findRegionById(regions: Region[], regionId: number): Region | undefined {
        return regions.find((region) => region.regionId === regionId);
    }

    function findMemberById(members: Member[], memberId: number): User | undefined {
        let target = undefined;
        members.map((member) => {
            const find = member.userDtoList.find((member) => member.userId === memberId);
            if (find) target = find;
        });
        return target;
    }

    const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
        const selectedKey = selectedKeys[0];
        const selectedRegion = findRegionById(regions, selectedKey as number);
        if (selectedRegion) onChange(selectedRegion);
    };

    const onSelectMember: TreeProps["onSelect"] = (selectedKeys, info) => {
        const selectedKey = selectedKeys[0];
        const selectedMember = findMemberById(members, selectedKey as number);
        if (selectedMember) onChangeMember(selectedMember);
    };

    return (
        <div className={cx("container")}>
            <div>
                <span className={cx("label")}>구역</span>
                <Tree
                    showLine
                    switcherIcon={<DownOutlined />}
                    defaultExpandAll
                    onSelect={onSelect}
                    treeData={treeData}
                />
            </div>
            {/* <div>
                <span className={cx("label")}>점검인</span>
                <Tree
                    showLine
                    switcherIcon={<DownOutlined />}
                    defaultExpandAll
                    onSelect={onSelectMember}
                    treeData={memberTreeData}
                />
            </div> */}
        </div>
    );
};

export {RegionSelect};

import React, {useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {LegalForm, LegalFormDetail, ReportCategory} from "./use-legal-form.interface";

function useLegalForms(type: string) {
    const [legalForm, setLegalForm] = useState<Array<LegalForm>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<
            {
                category: string;
            },
            Array<LegalForm>
        >({
            url: "/reportCheck/userReportList",
            params: {
                category: type === "legal" ? "LEGAL" : "CHECK",
            },
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        request
            .submit()
            .then((response) => setLegalForm(response.data))
            .finally(() => {
                setLoading(false);
            });
    }, [request]);

    return {
        loading,
        legalForms: legalForm,
        totalCount: legalForm.length,
    };
}

function useLegalForm(formId?: number) {
    const [legalForm, setLegalForm] = useState<LegalFormDetail>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLegalForm(undefined);
        setLoading(false);
    }, [formId]);

    const request = useMemo(() => {
        if (!formId) return null;

        return httpRequest<undefined, LegalFormDetail>({
            url: "/reportCheck/userReportInfo/" + formId,
        });
    }, [formId]);

    useEffect(() => {
        if (request) {
            setLoading(true);
            request
                .submit()
                .then((response) => setLegalForm(response.data))
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [request]);

    return {
        loading,
        legalFormDetail: legalForm,
    };
}

export {useLegalForm, useLegalForms};
export type {ReportCategory};

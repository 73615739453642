import React from "react";
import {Input} from "antd";
import classNames from "classnames/bind";

import type {CnInputProps} from "./cn-input.interface";

import styles from "./cn-input.module.scss";

const cx = classNames.bind(styles);

const CnInput = (props: CnInputProps) => {
    return (
        <div className={cx("container")}>
            <label className={cx("label")}>{props.label}</label>
            <Input className={cx("input")} {...props} />
        </div>
    );
};

export {CnInput};

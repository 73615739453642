import React, {useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {Region} from "./use-region.interface";

function useRegion(buildingId: number) {
    const [regions, setRegions] = useState<Array<Region>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        if (buildingId > 0)
            return httpRequest<undefined, Array<Region>>({
                url: `/buildings/${buildingId}/regions`,
            });
        else return null;
    }, [buildingId]);

    useEffect(() => getList(), [request]);

    const getList = () => {
        if (request) {
            setLoading(true);
            request
                .submit()
                .then((response) => setRegions(response.data))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return {
        loading,
        refresh: getList,
        regions: regions,
        totalCount: regions.length,
    };
}

export {useRegion};
export type {Region};

import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import {DatePicker, Flex, Input, InputNumber, Radio, Spin, Table} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button/button";
import {httpRequest} from "~/fetch/common/http-request";
import {useMachineDetail} from "~/pages/equipment/detail/use-detail";

import styles from "./history-edit-detail.module.scss";

const cx = classNames.bind(styles);

const MobileEquipmentHistoryEditPage = () => {
    const navigate = useNavigate();
    const {companyId, buildingId, machineId, machineHistoryId} = useParams<{
        companyId: string;
        buildingId: string;
        machineId: string;
        machineHistoryId: string;
    }>();

    // 기존 데이터 불러오기
    const [submit, response, isLoading] = useMachineDetail({
        companyId: Number(companyId),
        buildingId: Number(buildingId),
        machineId: Number(machineId),
    });
    useEffect(() => {
        submit();
    }, [companyId, buildingId, machineId, machineHistoryId]);

    // 설비 이력 수정값 모음
    const [historyDataEdit, setHistoryDataEdit] = useState<any>({
        machineHistoryId: machineHistoryId,
        machineId: machineId,
        machineHistoryDate: 0, // 날짜
        machineHistoryCost: 0, // 비용
        machineHistoryContent: "", // 업무내역
        machineHistoryNote: "", // 비고
        type: "self", // 구분 (기본값 self로 지정)
    });

    useEffect(() => {
        const getData = response?.machineDTO.machineHistoryDTOList.filter(
            (i) => String(i.machineHistoryId) === machineHistoryId,
        );
        if (getData && getData.length > 0) {
            const data = getData[0];
            setHistoryDataEdit({
                machineHistoryId: machineHistoryId,
                machineId: machineId,
                machineHistoryDate: data.machineHistoryDate,
                machineHistoryCost: data.machineHistoryCost,
                machineHistoryContent: data.machineHistoryContent,
                machineHistoryNote: data.machineHistoryNote,
                type: data.type,
            });
        }
    }, [response]);

    // 장비이력 수정
    const requestEdit = useMemo(() => {
        return httpRequest<any, null>({
            method: "PUT",
            url: "/machine/hu",
            data: historyDataEdit,
        });
    }, [historyDataEdit]);

    const historySubmit = async () => {
        console.log("바꾼값: ", historyDataEdit);
        if (historyDataEdit.machineHistoryContent.trim().length > 0) {
            if (window.confirm("장비이력을 수정하시겠습니까?")) {
                try {
                    await requestEdit.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    alert("수정되었습니다");
                    navigate(-1);
                }
            }
        } else {
            alert("필수항목을 입력해주세요.");
        }
    };

    // 장비이력 삭제
    const requestDelete = useMemo(() => {
        return httpRequest<{machineHistoryId: number}, null>({
            method: "DELETE",
            url: "/machine/hd",
            data: {
                machineHistoryId: Number(machineHistoryId),
            },
        });
    }, [machineHistoryId]);
    const historyDeleteSubmit = async () => {
        if (machineHistoryId !== null && machineHistoryId !== undefined) {
            if (window.confirm("삭제하시겠습니까?")) {
                try {
                    await requestDelete.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    alert("삭제되었습니다");
                    navigate(-1);
                }
            }
        }
    };

    return (
        <div>
            <div className={cx("inner-container")}>
                <h1 className={cx("title")}>
                    설비 이력 수정하기{" "}
                    <span style={{color: "#d9534f", marginLeft: "8px", fontSize: "14px"}}>* 필수항목</span>
                </h1>
                <CloseOutlined
                    className={cx("close-button")}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            </div>
            <ul className={cx("list")}>
                {isLoading && (
                    <div className={cx("list-loading")}>
                        <Spin size="large" />
                    </div>
                )}
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        날짜<span style={{color: "#d9534f", marginLeft: "4px", fontSize: "14px"}}>*</span>
                    </label>
                    <DatePicker
                        className={cx("field")}
                        locale={locale}
                        inputReadOnly
                        allowClear={false}
                        onChange={(date, dateString: any) => {
                            setHistoryDataEdit({
                                ...historyDataEdit,
                                machineHistoryDate: new Date(dateString).getTime(),
                            });
                        }}
                        placeholder="년/월/일"
                        format="YYYY.MM.DD"
                        value={dayjs(historyDataEdit?.machineHistoryDate)}
                    />
                </li>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        비용
                    </label>
                    <InputNumber
                        className={cx("field")}
                        type="number"
                        name="machineHistoryCost"
                        onChange={(e: any) => {
                            setHistoryDataEdit({
                                ...historyDataEdit,
                                machineHistoryCost: e,
                            });
                        }}
                        value={historyDataEdit?.machineHistoryCost}
                    />
                </li>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        구분
                    </label>
                    <Radio.Group
                        onChange={(e) => {
                            setHistoryDataEdit({
                                ...historyDataEdit,
                                type: e.target.value,
                            });
                        }}
                        value={historyDataEdit?.type}
                    >
                        <Radio value={"self"}>자체개선</Radio>
                        <Radio value={"construction"}>실재공사</Radio>
                    </Radio.Group>
                </li>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        업무내역
                        <span style={{color: "#d9534f", marginLeft: "4px", fontSize: "14px"}}>*</span>
                    </label>
                    <Input
                        className={cx("field")}
                        name="machineHistoryContent"
                        onChange={(e: any) => {
                            setHistoryDataEdit({
                                ...historyDataEdit,
                                machineHistoryContent: e.target.value,
                            });
                        }}
                        value={historyDataEdit?.machineHistoryContent}
                    />
                </li>
                <li className={cx("item")}>
                    <label htmlFor="time" className={cx("label")}>
                        비고
                    </label>
                    <Input
                        className={cx("field")}
                        name="machineHistoryNote"
                        onChange={(e: any) => {
                            setHistoryDataEdit({
                                ...historyDataEdit,
                                machineHistoryNote: e.target.value,
                            });
                        }}
                        value={historyDataEdit?.machineHistoryNote}
                    />
                </li>
            </ul>
            {/* ))} */}
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Button label="삭제하기" size="large" color="primary" onClick={historyDeleteSubmit} />
                <Button label="수정하기" size="large" onClick={historySubmit} />
            </div>
        </div>
    );
};

export {MobileEquipmentHistoryEditPage};

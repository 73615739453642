import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {CostFacilityDataRequest, CostFacilityDataResponse} from "./cost-facility-data.interface";

const useCostFacilityData = (
    costFacilityId: number,
    budgetDivision?: number,
): [() => Promise<void>, CostFacilityDataResponse | null, boolean, any] => {
    const [response, setResponse] = useState<null | CostFacilityDataResponse>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<CostFacilityDataRequest, CostFacilityDataResponse>({
            method: "GET",
            url: "/lotte/cost/facility/year/plans/plan",
            params: {
                costFacilityId,
                ...(budgetDivision ? {budgetDivision} : {}),
            },
        });
    }, [costFacilityId]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return [submit, response, isLoading, error];
};

export {useCostFacilityData};

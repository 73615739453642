import React, {useEffect, useState} from "react";
import classNames from "classnames/bind";

import {CheckList, useCheckListForms} from "~/data/check/form/use-checklist-form";
import {useUser} from "~/data/user";
import {
    ChecklistFormSelectProps,
    ChecklistFormValue,
} from "~/pages/check/instruction/checklist/checklist-form-select.interface";

import styles from "./checklist-form-select.module.scss";

const cx = classNames.bind(styles);

const ChecklistFormSelect = ({defaultValue = null, onChange, type}: ChecklistFormSelectProps) => {
    const [value, setValue] = useState<CheckList | null>(defaultValue);
    const [selected, setSelected] = useState<number | null>(null);

    const {user} = useUser();
    const {checklistForms, loading, totalCount, refresh} = useCheckListForms(user!.buildingId, type);

    useEffect(() => {
        if (defaultValue) setSelected(defaultValue.checkFormId);
    }, [defaultValue]);

    useEffect(() => {
        if (value) onChange(value);
    }, [value]);

    return (
        <div className={cx("container")}>
            <ul className={cx("list")}>
                {checklistForms.map((form) => (
                    <li
                        key={form.checkFormId}
                        className={cx("item", {selected: form.checkFormId === selected})}
                        onClick={() => {
                            setSelected(form.checkFormId);
                            setValue(form);
                        }}
                    >
                        {form.checkFormName}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export {ChecklistFormSelect};

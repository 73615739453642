import classNames from "classnames/bind";

import {MergeGridItemProps} from "./merge-grid-item.interface";

import styles from "./merge-grid-item.module.scss";

const cx = classNames.bind(styles);

const MergeGridItem = ({name, children, span, extraClassName, require}: MergeGridItemProps) => {
    return (
        <div className={cx("item", {span})}>
            <div className={cx("item-name")}>
                {require && <span style={{color: "red", paddingRight: "2px"}}>{"*"}</span>}
                {name}
            </div>
            <div className={cx("item-value", {[`${extraClassName}`]: extraClassName})}>{children}</div>
        </div>
    );
};

export {MergeGridItem};

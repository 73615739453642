import {useCallback, useMemo, useState} from "react";
import dayjs from "dayjs";

import {axiosInstance} from "~/fetch/common/axios-instance";
import {httpRequest} from "~/fetch/common/http-request";
import {useShopCascaderRefined} from "~/hooks/use-shop-cascader/use-shop-cascader";

import {
    CostMaintenanceExcelDownload,
    CostMaintenanceExcelUpload,
    CostMaintenanceExcelUploadRequest,
    CostMaintenanceFormatDownload,
    CostMaintenanceLightingConfig,
    CostMaintenanceLightingRequest,
    CostMaintenanceLightingResponse,
    CostMaintenanceLightingTable,
    CostMaintenanceLightingTotalTable,
} from "./maintenance-lighting.interface";

const useCostMaintenanceLightingList = (
    config: CostMaintenanceLightingConfig,
): [
    (inquiry?: boolean) => Promise<void>,
    {
        allRecordCnt: number;
        total: CostMaintenanceLightingTotalTable;
        list: CostMaintenanceLightingTable[];
    },
    boolean,
] => {
    const [response, setResponse] = useState<{
        allRecordCnt: number;
        total: CostMaintenanceLightingTotalTable;
        list: CostMaintenanceLightingTable[];
    }>({
        allRecordCnt: 0,
        total: {
            costRepairId: 0,
            year: dayjs(new Date()).format("YYYY"),
            month: dayjs(new Date()).format("MM"),
            targetCost: 0,
            cost: {
                request: 0,
                reflect: 0,
            },
            lighting: {
                request: 0,
                reflect: 0,
            },
            maintenance: {
                request: 0,
                reflect: 0,
            },
        },
        list: [],
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const refinedData = () => {
            if (config.selected.length) {
                switch (config.selected[0]) {
                    case "ALL":
                        return null;
                    // break;
                    case "SECTOR":
                        return {
                            sectorId: config.selected[1],
                            ...(config.selected[2] !== "0" && {buildingId: config.selected[2]}),
                        };
                    // break;
                    case "TRAINER":
                        return {
                            trainerSectorId: config.selected[1],
                            ...(config.selected[2] !== "0" && {buildingId: config.selected[2]}),
                        };
                    // break;
                    default:
                        return null;
                    // break;
                }
            }
        };

        return (inquiry?: boolean) => {
            const {
                year = dayjs(new Date()).format("YYYY"),
                month = dayjs(new Date()).format("MM"),
                recordPerPage = 20,
                pageNo,
            } = {
                year: config.date.year?.format("YYYY"),
                month: config.date.month?.format("MM"),
                recordPerPage: config.perPage,
                pageNo: inquiry ? 1 : config.pageNo,
            };

            return httpRequest<CostMaintenanceLightingRequest, CostMaintenanceLightingResponse>({
                method: "GET",
                url: "/lotte/cost/repair/total/list",
                params: {
                    year,
                    month,
                    recordPerPage,
                    pageNo,
                    ...refinedData(),
                },
            });
        };
    }, [config]);

    const submit = async (inquiry?: boolean) => {
        try {
            setIsLoading(true);

            const response = await request(inquiry).submit();
            const data = response.data;

            setResponse({
                allRecordCnt: data.requestData.allRecordCnt,
                total: {
                    costRepairId: data.responseData.costRepairId,
                    year: data.responseData.year,
                    month: data.responseData.month,
                    targetCost: data.responseData.costRepairGoal,
                    cost: {
                        request: data.responseData.applyCostTotal,
                        reflect: data.responseData.replectCostTotal,
                    },
                    lighting: {
                        request: data.responseData.applyCostLightTotal,
                        reflect: data.responseData.replectCostLightTotal,
                    },
                    maintenance: {
                        request: data.responseData.applyCostPartTotal,
                        reflect: data.responseData.replectCostPartTotal,
                    },
                },
                list: data.responseData.costRepairApplyDTOList.map((data) => ({
                    no: data.rownum,
                    buildingId: data.buildingId,
                    buildingName: data.buildingName,
                    sectorId: data.sectorId,
                    sectorName: data.sectorName,
                    targetCost: data.costRepairGoal,
                    cost: {
                        request: data.applyCost,
                        reflect: data.replectCost,
                    },
                    lighting: {
                        request: data.applyCostLight,
                        reflect: data.replectCostLight,
                    },
                    maintenance: {
                        request: data.applyCostPart,
                        reflect: data.replectCostPart,
                    },
                    note: {
                        request: data.applyMemo,
                        reflect: data.replectMemo,
                    },
                    createDate: dayjs(new Date(data.createDate)).format("YYYY-MM-DD"),
                    updateDate: data.updateDate === null ? "-" : dayjs(new Date(data.updateDate)).format("YYYY-MM-DD"),
                })),
            });
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading];
};

// 양식 다운로드
const useCostMaintenanceFormatDownload = (config: CostMaintenanceFormatDownload) => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const {
            year = dayjs(new Date()).format("YYYY"),
            month = dayjs(new Date()).format("MM"),
            trainerSectorId = 0,
        } = {
            year: config.date.year.format("YYYY"),
            month: config.date.month.format("MM"),
            trainerSectorId: config.trainerSectorId,
        };
        return httpRequest<{year: string; month: string; trainerSectorId: number}, Blob>({
            method: "GET",
            url: "/lotte/cost/repair/excelForUpload",
            params: {
                year,
                month,
                trainerSectorId,
            },
            responseType: "blob",
        });
    }, [config]);

    const submit = async () => {
        try {
            const response = await request.submit();

            // 파일 다운로드 처리
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // 파일명추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";
            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = matches[1].trim().replace(/['"]/g, "");
                }
            }
            link.setAttribute("download", decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            setError(error);
        }
    };
    return [submit, error];
};

// 엑셀 다운로드
const useCostMaintenanceExcelDownload = (config: CostMaintenanceExcelDownload) => {
    const [error, setError] = useState<any>(null);

    const refinedData = useShopCascaderRefined(config.selected);

    const request = useCallback(() => {
        const {year = dayjs(new Date()).format("YYYY"), month = dayjs(new Date()).format("MM")} = {
            year: config.date.year.format("YYYY"),
            month: config.date.month.format("MM"),
        };
        const url = `${axiosInstance.defaults.baseURL}/lotte/cost/repair/excel?year=${year}&month=${month}${refinedData?.buildingId ? `&buildingId=${refinedData.buildingId}` : ""}${refinedData?.sectorId ? `&sectorId=${refinedData.sectorId}` : ""}${refinedData?.trainerSectorId ? `&trainerSectorId=${refinedData.trainerSectorId}` : ""}`;
        window.location.href = url;
    }, [config]);

    const submit = async () => {
        try {
            await request();
        } catch (e) {
            setError(e);
        }
    };
    return [submit, error];
};

// 엑셀 업로드
const useCostMaintenanceExcelUpload = (config: CostMaintenanceExcelUpload) => {
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const {
            file,
            year = dayjs(new Date()).format("YYYY"),
            month = dayjs(new Date()).format("MM"),
        } = {
            file: config.file,
            year: config.date.year.format("YYYY"),
            month: config.date.month.format("MM"),
        };

        return httpRequest<CostMaintenanceExcelUploadRequest, string>({
            method: "POST",
            url: "lotte/cost/repair/excelUpload",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: {
                uploadFile: file,
                year,
                month,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            console.log(res.data);
        } catch (e) {
            setError(e);
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, isLoading, error];
};

export {
    useCostMaintenanceExcelDownload,
    useCostMaintenanceExcelUpload,
    useCostMaintenanceFormatDownload,
    useCostMaintenanceLightingList,
};

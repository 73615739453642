import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import classNames from "classnames/bind";

import Button from "~/components/button";

import {SideModalProps} from "./side-modal.interface";

import styles from "./side-modal.module.scss";

const cx = classNames.bind(styles);

const SideModal = ({
    onClose,
    children,
    size = "normal",
    confirmLabel,
    onConfirm = () => {
        return;
    },
    nonePrevent,
    extraButton,
}: SideModalProps) => {
    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);
    }, []);

    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => onClose(), 500);
    }, [isLoading]);

    useEffect(() => {
        const handleCloseESC = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            document.addEventListener("keydown", handleCloseESC);
        }
        return () => {
            document.removeEventListener("keydown", handleCloseESC);
        };
    }, [isLoading]);

    const handleClose = () => {
        setIsLoading(false);
    };

    return createPortal(
        <div className={cx("container", {active: isLoading})}>
            <div
                className={cx("inner", {active: isLoading}, size)}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!nonePrevent) e.preventDefault();
                }}
            >
                <div className={cx("close-button-wrapper")}>
                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                </div>
                <div className={cx("contents-container")}>{children}</div>
                <div className={cx("button-container")}>
                    {confirmLabel && <Button onClick={onConfirm} label={confirmLabel} color={"signature"} />}
                    {extraButton}
                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                </div>
            </div>
        </div>,
        document.body,
    );
};

export {SideModal};

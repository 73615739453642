import React, {useEffect} from "react";
import {RouterProvider} from "react-router-dom";
import {ConfigProvider} from "antd";
import koKr from "antd/locale/ko_KR";

import {UserProvider} from "~/data/user/user-context";
import {router} from "~/router";
import {usePlatform} from "~/utills/use-platform";

const App = () => {
    const {platform} = usePlatform();
    useEffect(() => {
        const root = document.getElementById("root") as HTMLElement;
        if (platform === "mobile") root.classList.add("mobile");

        return () => {
            root.classList.remove("mobile");
        };
    }, [platform]);
    return (
        <UserProvider>
            <ConfigProvider locale={koKr} theme={{token: {colorPrimary: "#F1291C", borderRadius: 4}}}>
                <RouterProvider router={router} />
            </ConfigProvider>
        </UserProvider>
    );
};

export {App};

import {useMemo, useState} from "react";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";

import {InquiryCommentRegisterRequest} from "./inquiry-comment.interface";

const useInquiryComment = (noticeId: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const register = (config: InquiryCommentRegisterRequest) => {
        const formData = new FormData();

        const temp = JSON.stringify({
            content: config.content,
            boardId: noticeId,
        });
        formData.append("data", temp);
        if (config.file) formData.append("file", config.file);

        return httpRequest<FormData, any>({
            method: "POST",
            url: "/commonBoard/createInquiryReply",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .submit()
            .then(() => {
                Swal.fire({
                    text: "등록되었습니다",
                    confirmButtonText: "확인",
                });
            })
            .catch((e) => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            });
    };

    const deleteComment = async (commentId: number) => {
        return httpRequest<null, any>({
            method: "DELETE",
            url: "/commonBoard/removeInquiryReply/" + commentId,
        })
            .submit()
            .then(() => {
                Swal.fire({
                    text: "삭제되었습니다.",
                    confirmButtonText: "확인",
                });
            })
            .catch((e) => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            });
    };

    return {commentRegister: register, deleteComment, loading: isLoading, error};
};

export {useInquiryComment};

import axios from "axios";

import {onFulfilled, onRejected} from "~/fetch/common/axios-interceptor";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(onFulfilled, onRejected);
axiosInstance.defaults.timeout = 10000;
// * TODO: Change BaseURL from ENV file
axiosInstance.defaults.baseURL = `${window.location.protocol}//lottemartfms.com:9988`;
axiosInstance.defaults.method = "get";

export {axiosInstance};

import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DatePicker, Toast} from "antd-mobile";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import {useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {useUser} from "~/data/user";

import styles from "./list.module.scss";

const cx = classNames.bind(styles);

const MobileCheckElevatorListPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [visibleDatePicker, setVisibleDatePicker] = useState<boolean>(false);
    const [date, setDate] = useState<Dayjs>(dayjs(searchParams.get("date") || dayjs()));

    const navigation = useNavigate();

    const location = useLocation();
    const isInitialMount = useRef(true);
    const [isBlocking, setIsBlocking] = React.useState(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            window.history.pushState(null, "", window.location.href);
        }

        const handlePopState = (event: PopStateEvent) => {
            if (isBlocking) {
                setIsBlocking(false); // 뒤로가기를 허용
                navigation("/mobile/check/elevator"); // 사용자가 확인을 누르면 뒤로가기
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [isBlocking, navigation, location.pathname]);

    useEffect(() => {
        Toast.show(date.format("YYYY-MM-DD"));
        searchParams.set("date", date.format("YYYY-MM-DD"));
        setSearchParams(searchParams, {replace: true});
    }, [date]);

    const {user} = useUser();
    const {list} = useCheckElevatorList({
        perPage: 1000,
        page: 1,
        buildingId: user!.buildingId,
        startDate: searchParams.get("date") as string,
        endDate: searchParams.get("date") as string,
    });

    const handleClickLink = (id: string) => {
        navigation(`/mobile/check/elevator/request/edit/${id}`);
    };

    return (
        <div className={cx("container")}>
            <h1 className={cx("title")}>승강기점검</h1>
            <div className={cx("button-container")}>
                <button
                    className={cx("btn-with-icon")}
                    onClick={() => {
                        setDate((prev) => prev.add(-1, "day"));
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                    className={cx("btn-with-icon")}
                    onClick={() => {
                        setVisibleDatePicker(true);
                    }}
                >
                    <FontAwesomeIcon icon={faCalendar} />
                </button>
                <DatePicker
                    visible={visibleDatePicker}
                    onClose={() => {
                        setVisibleDatePicker(false);
                    }}
                    precision="day"
                    onConfirm={(val: Date) => {
                        setDate(dayjs(val));
                    }}
                    confirmText="확인"
                    cancelText="취소"
                />
                <button
                    className={cx("btn-with-icon")}
                    onClick={() => {
                        setDate((prev) => {
                            if (+new Date(prev.format("YYYY-MM-DD")) < +new Date(dayjs().format("YYYY-MM-DD"))) {
                                return prev.add(1, "day");
                            } else return prev;
                        });
                    }}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
            <div className={cx("date")}>{searchParams.get("date")}</div>
            <table className={cx("table")}>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>요청일시</th>
                        <th>도착시간</th>
                        <th>구역</th>
                        <th>점검인</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, index) => (
                        <tr key={item.liftId} onClick={() => handleClickLink(item.liftId)}>
                            <td>{index + 1}</td>
                            <td>{dayjs(item.breakDate).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{item.arriveTime}</td>
                            <td>
                                {item.breakLocation} {item.breakUnit}
                            </td>
                            <td>{item.employeeName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export {MobileCheckElevatorListPage};

import {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {DatePicker, InputNumber, Pagination, Spin} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {CostMaintenanceTable} from "~/components/cost/maintenance-lighting/cost-table/cost-maintenance-table";
import {CostMaintenanceTableRow} from "~/components/cost/maintenance-lighting/cost-table/cost-maintenance-table.row";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title/page-title";
import {CostRepairApplyDTOListType} from "~/data/cost/maintenance-lighting/modal/maintenance.modal.interface";
import {
    useCostMaintenanceModalData,
    useCostMaintenanceModalUpdate,
    useCostMaintenanceModalUpdateManager,
} from "~/data/cost/maintenance-lighting/modal/use-maintenance.modal";
import {useShopCascader} from "~/hooks/use-shop-cascader/use-shop-cascader";
import {formatCost} from "~/utills/formatCost";

import {CostMaintenanceLightingModalProps, FilterDateType} from "./cost-maintenance-lighting.modal.interface";

import styles from "./cost-maintenance-lighting.modal.module.scss";

const cx = classNames.bind(styles);

const CostMaintenanceLightingModal = ({
    close,
    no,
    userId,
    division,
    buildingId,
    sectorId,
    year,
    month,
}: CostMaintenanceLightingModalProps) => {
    const perPage = 20;
    const [rows, setRows] = useState<JSX.Element[]>([]);
    const [page, setPage] = useState<number>(1);
    const [date, setDate] = useState<FilterDateType>({
        year: dayjs(new Date()),
        month: dayjs(new Date()),
    });
    const [tableData, setTableData] = useState<CostRepairApplyDTOListType[]>([]);
    const [targetCost, setTargetCost] = useState<number | null>(0);

    const modalRef = useRef<HTMLDivElement>(null);

    const {ShopCascader, selected, setSelected} = useShopCascader(
        400,
        no === null ? ["ALL"] : ["SECTOR", sectorId.toString(), buildingId.toString()],
    );

    // 기본 데이터 호출
    const [submit, response, isLoading] = useCostMaintenanceModalData({
        date,
        selected,
        buildingId,
        perPage,
        page,
    });

    // 수정
    const [update, updateLoading] = useCostMaintenanceModalUpdate({
        costRepairId: response?.responseData.costRepairId,
        date,
        tableData,
        targetCost,
        userId,
        close,
    });

    const [updateManager] = useCostMaintenanceModalUpdateManager(tableData, close);

    // 목표금액
    useEffect(() => {
        setTargetCost(response?.responseData.costRepairGoal ?? 0);
    }, [response]);

    //구분
    useEffect(() => {
        setDate({
            year,
            month,
        });
    }, [no, sectorId, buildingId, year, month, setSelected]);

    useEffect(() => {
        submit();
    }, [selected, date]);

    // 페이지 이동 시 스크롤
    useEffect(() => {
        if (page !== 1 && modalRef.current) {
            modalRef.current.scrollIntoView({behavior: "auto"});
        }
        submit();
    }, [page]);

    useEffect(() => {
        if (response) {
            setTableData(response?.responseData.costRepairApplyDTOList ?? []);

            setRows(
                response?.responseData.costRepairApplyDTOList.map((data, index) => (
                    <CostMaintenanceTableRow
                        index={index}
                        data={data}
                        key={index}
                        division={division}
                        userId={userId}
                        setTableData={setTableData}
                        page={page}
                        perPage={perPage}
                    />
                )),
            );
        }
    }, [response]);

    const handleGetLastMonthData = () => {
        Swal.fire({
            text: `${date.year.format("YYYY년")} ${date.month.subtract(1, "month").format("MM월")} 목표금액을 불러오시겠습니까?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then((result) => {
            if (result.isConfirmed) submit(true);
        });
    };

    return createPortal(
        <SideModal
            onClose={close}
            size="large"
            confirmLabel="저장"
            onConfirm={() => {
                if (division === "MEMBER") {
                    update();
                } else {
                    Swal.fire({
                        title: "수선비 등록",
                        text: "저장하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonText: "저장하기",
                        cancelButtonText: "취소하기",
                    }).then((info) => {
                        if (info.isConfirmed) {
                            updateManager();
                        }
                    });
                }
            }}
        >
            {" "}
            {isLoading ? (
                <div className={cx("loading")}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <div ref={modalRef} />
                    <PageTitle>수선비 등록</PageTitle>
                    <div className={cx("modal-filter-area")}>
                        연도
                        <DatePicker
                            placeholder={"연도 선택"}
                            picker={"year"}
                            style={{width: "120px"}}
                            value={date.year}
                            allowClear={false}
                            onChange={(e) => setDate((prev) => ({...prev, year: e}))}
                            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                        />
                        월
                        <DatePicker
                            placeholder={"월 선택"}
                            popupClassName="no-header"
                            picker={"month"}
                            format={"MM"}
                            style={{width: "120px"}}
                            allowClear={false}
                            value={date.month}
                            onChange={(e) => setDate((prev) => ({...prev, month: e}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                            )}
                            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                        />
                        {division === "MEMBER" && (
                            <>
                                구분
                                <ShopCascader />
                            </>
                        )}
                    </div>
                    <div className={cx("form-container")}>
                        <div className={cx("subtitle")}>종합</div>
                        <div className={cx("total-table")}>
                            <div className={cx("col")}>연도</div>
                            <div className={cx("col")}>월</div>
                            <div className={cx("col")}>목표금액</div>
                            <div>{date.year?.format("YYYY")}</div>
                            <div>{date.month?.format("MM") ?? "전체"}</div>
                            <div>
                                {division === "MEMBER" ? (
                                    <InputNumber
                                        controls={false}
                                        className={cx("input-number")}
                                        style={{width: "100%", textAlign: "center"}}
                                        value={targetCost}
                                        formatter={(value) => formatCost(value)}
                                        onChange={(e) => setTargetCost(e)}
                                    />
                                ) : (
                                    response?.responseData.costRepairGoal.toLocaleString()
                                )}
                            </div>
                        </div>
                        <div className={cx("subtitle")}>
                            <span className={cx("subtitle-text")}>지점별</span>
                            <Button label={"전월 목표금액 불러오기"} onClick={handleGetLastMonthData} />
                        </div>
                        <CostMaintenanceTable rows={rows} />
                        <Pagination
                            current={page}
                            total={response?.requestData.allRecordCnt}
                            pageSize={perPage}
                            showSizeChanger={false}
                            onChange={(pageNo) => setPage(pageNo)}
                            style={{marginTop: "20px", textAlign: "right"}}
                        />
                    </div>
                </>
            )}
        </SideModal>,
        document.body,
    );
};

export {CostMaintenanceLightingModal};

import React, {useEffect, useMemo, useState} from "react";
import classNames from "classnames/bind";

import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {httpRequest} from "~/fetch/common/http-request";

import {ApprovalSignProps, SignImage} from "./approval-sign.interface";

import styles from "./approval-sign.modal.module.scss";

const cx = classNames.bind(styles);

const ApprovalSignModal = ({onClose, report}: ApprovalSignProps) => {
    const [sign, setSign] = useState<SignImage | null>(null);
    const [poorList, setPoorList] = useState([]);

    const signRequest = useMemo(() => {
        return httpRequest<any, SignImage>({
            method: "GET",
            url: "/lotte/fms/sign/image",
            params: {
                checkHistoryId: report.checkHistoryId,
            },
        });
    }, [report.checkHistoryId]);

    const poorListRequest = useMemo(() => {
        return httpRequest<any, any>({
            method: "GET",
            url: "/lotte/fms/sign/poor/list",
            params: {
                historyId: report.checkHistoryId,
            },
        });
    }, [report.checkHistoryId]);

    useEffect(() => {
        signRequest
            .submit()
            .then((response) => {
                setSign(response.data);
            })
            .catch(() => setSign(null));
    }, [signRequest]);

    /** TODO: 불량내역 추가 */
    // useEffect(() => {
    //     poorListRequest
    //         .submit()
    //         .then((response) => {
    //             setPoorList(response.data);
    //         })
    //         .catch(() => {
    //             return;
    //         });
    // }, [poorListRequest]);

    return (
        <SideModal onClose={onClose}>
            <PageTitle>결재하기</PageTitle>
            <div className={cx("container")}>
                <h2>점검자 결재</h2>
                <div className={cx("image-container")}>
                    {sign?.inspectorSign ? (
                        <img src={"data:image/png;base64," + sign?.inspectorSign} />
                    ) : (
                        <p>결재 전입니다.</p>
                    )}
                </div>
                <h2>관리자 결재</h2>
                <div className={cx("image-container")}>
                    {sign?.managerSign ? (
                        <img src={"data:image/png;base64," + sign?.managerSign} />
                    ) : (
                        <p>결재 전입니다.</p>
                    )}
                </div>
            </div>
        </SideModal>
    );
};

export {ApprovalSignModal};

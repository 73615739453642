import React, {useEffect, useState} from "react";
import classNames from "classnames/bind";

import {useLegalForm, useLegalForms} from "~/data/check/form/use-legal-form";
import {LegalForm} from "~/data/check/form/use-legal-form.interface";
import {LegalFormSelectProps} from "~/pages/check/instruction/legal/legal-form-select.interface";

import styles from "./legal-form-select.module.scss";

const cx = classNames.bind(styles);

const LegalFormSelect = ({defaultValue = null, onChange, type}: LegalFormSelectProps) => {
    const [value, setValue] = useState<LegalForm | null>(defaultValue);
    const [selected, setSelected] = useState<number | null>(null);

    const {legalForms} = useLegalForms(type);
    const {legalFormDetail} = useLegalForm(value?.reportInfoId);

    // useEffect(() => {
    //     if (defaultValue) setSelected(defaultValue.reportInfoId);
    // }, [defaultValue]);

    useEffect(
        () =>
            legalFormDetail &&
            onChange({
                form: value as LegalForm,
                detail: legalFormDetail,
            }),
        [legalFormDetail],
    );

    return (
        <div className={cx("container")}>
            <ul className={cx("list")}>
                {legalForms.map((form) => (
                    <li
                        key={form.reportInfoId}
                        className={cx("item", {selected: form.reportInfoId === selected})}
                        onClick={() => {
                            setSelected(form.reportInfoId);
                            setValue(form);
                        }}
                    >
                        {form.reportNm}
                    </li>
                ))}
            </ul>
            <div>
                <ul className={cx("list")}>
                    {legalFormDetail?.list.map((item) => (
                        <li key={item.reportCheckId} className={cx("item", "selected")}>
                            {item.checkHistoryNm}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export {LegalFormSelect};

import React, {Fragment} from "react";
import {useParams} from "react-router-dom";

import {ChecklistFormPage} from "~/pages/check/form/checklist/checklist-form.page";
import {LegalFormPage} from "~/pages/check/form/legal/legal-form.page";

const FormRoutePage = () => {
    const {type} = useParams<{type: "checklist" | "extra" | "legal" | "normal"}>();
    return (
        <Fragment>
            {type === "legal" && <LegalFormPage />}
            {type === "checklist" && <LegalFormPage />}
            {type === "extra" && <ChecklistFormPage />}
            {type === "normal" && <ChecklistFormPage />}
        </Fragment>
    );
};

export {FormRoutePage};

import {axiosInstance} from "./axios-instance";
import {HttpRequest} from "./http-request.interface";

const httpRequest: HttpRequest = (config) => {
    const controller = new AbortController();
    return {
        submit: () => axiosInstance({...config, signal: controller.signal}),
        cancel: controller.abort,
    };
};

export {httpRequest};

import {useMemo, useState} from "react";
import dayjs from "dayjs";

import {httpRequest} from "~/fetch/common/http-request";

import {MergeBoardRefinedData, MergeBoardRequest, MergeBoardResponse} from "./board.interface";

const useMergeBoardList = (config: MergeBoardRequest) => {
    const [response, setResponse] = useState<MergeBoardRefinedData | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const {
            isComplete = null,
            mergeType = null,
            startDate,
            endDate,
            title = null,
            pageNo,
            recordPerPage = 50,
        } = {
            ...config,
            isComplete: config.isComplete === "" ? null : config.isComplete,
            mergeType: config.mergeType === "" ? null : config.mergeType,
        };
        return httpRequest<MergeBoardRequest, MergeBoardResponse>({
            method: "GET",
            url: "/lotte/merge/list",
            params: {
                isComplete,
                mergeType,
                startDate,
                endDate,
                title,
                pageNo,
                recordPerPage,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const response = await request.submit();

            const data = response.data.responseData.map((data) => ({
                noticeMergeId: data.noticeMergeId,
                mergePeriod:
                    dayjs(new Date(data.startDate)).format("YYYY-MM-DD") +
                    " ~ " +
                    dayjs(new Date(data.endDate)).format("YYYY-MM-DD"),
                important: data.important,
                targetName: data.targetName,
                title: data.title,
                manageName: data.managerNm,
                targetCnt: data.targetCnt,
                completeCnt: data.completeCnt,
                incompleteCnt: data.targetCnt - data.completeCnt,
                createDate: dayjs(new Date(data.createDate)).format("YYYY-MM-DD"),
            }));

            setResponse({
                resultList: data,
                allRecordCnt: response.data.requestData.allRecordCnt,
            });
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

export {useMergeBoardList};

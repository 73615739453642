import {useCallback, useEffect, useState} from "react";
import {ConfigProvider} from "antd";
import locale from "antd/lib/locale/ko_KR";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import Button from "~/components/button/button";
import {CnInput} from "~/components/cn-input/cn-input";
import {CnRangePicker} from "~/components/cn-range-picker/cn-range-picker";
import {CnSelect} from "~/components/cn-select/cn-select";
import {CnTable} from "~/components/cn-table/cn-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {PageTitle} from "~/components/page-title/page-title";
import {useMergeBoardList} from "~/data/merge/board/use-board";
import {MergeBoardAnswerModal} from "~/pages/merge/answer/merge-board-answer.modal";
import {MergeBoardDetailModal} from "~/pages/merge/detail/merge-board-detail.modal";
import {MergeBoardRegisterModal} from "~/pages/merge/register/merge-board-register.modal";
import {useLocalStorage} from "~/utills/useLocalStorage";

import styles from "./merge-board.module.scss";

import "dayjs/locale/ko";

const cx = classNames.bind(styles);

const MergeBoardPage = () => {
    const perPage = 50;
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(new Date()).add(-7, "d"), dayjs(new Date())]);
    const [division, setDivision] = useState<"MEMBER" | "MANAGER" | "EMPLOYEE" | "">("");
    const [isComplete, setIsComplete] = useState<"Y" | "N" | "">(""); // 1:완료 , 2:미완료, "" : 전체
    const [mergeType, setMergeType] = useState<"FILE" | "DATA" | "">(""); // "":전체
    const [searchTitle, setSearchTitle] = useState<string | null>(null); // 제목 검색
    const [isShowModal, setIsShowModal] = useState<boolean>(false); // 상세 모달
    const [isShowRegisterModal, setIsShowRegisterModal] = useState<boolean>(false); // 게시물 등록 모달
    const [noticeMergeId, setNoticeMergeId] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const [submit, response, isLoading] = useMergeBoardList({
        isComplete: isComplete,
        mergeType: mergeType,
        startDate: dateRange![0]?.format("YYYY-MM-DD"),
        endDate: dateRange![1]?.format("YYYY-MM-DD"),
        title: searchTitle,
        pageNo: page,
        recordPerPage: perPage,
    });

    dayjs.locale("ko");

    const {setItem, getItem} = useLocalStorage();

    // 계정 권한
    useEffect(() => {
        setDivision(JSON.parse(getItem("LM_USER")!).division);
    }, [getItem]);

    useEffect(() => {
        window.scrollTo(0, 0);
        submit();
    }, [page]);

    const handleSubmit = () => {
        setPage(1);
        submit();
    };
    const handleResetFilter = useCallback(() => {
        setPage(1);
        setDateRange([dayjs(new Date()).add(-7, "d"), dayjs(new Date())]);
        setIsComplete("");
        setMergeType("");
        setSearchTitle(null);
    }, []);

    const handleDetailModal = (data: number) => {
        setNoticeMergeId(data);
        setIsShowModal(true);
    };
    const handleCreateModal = () => {
        setIsShowRegisterModal(true);
    };

    return (
        <div>
            {isShowRegisterModal && (
                <MergeBoardRegisterModal submit={submit} close={() => setIsShowRegisterModal(false)} />
            )}
            {isShowModal &&
                (division === "MEMBER" ? (
                    <MergeBoardDetailModal
                        submit={submit}
                        noticeMergeId={noticeMergeId}
                        close={() => setIsShowModal(false)}
                    />
                ) : (
                    <MergeBoardAnswerModal
                        submit={submit}
                        noticeMergeId={noticeMergeId}
                        close={() => setIsShowModal(false)}
                    />
                ))}
            <PageTitle>취합 게시판</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <ConfigProvider locale={locale}>
                        <CnRangePicker
                            label="취합기간"
                            value={dateRange}
                            onChange={(value) => setDateRange(value)}
                            allowClear={false}
                        />
                    </ConfigProvider>
                    <CnSelect
                        label={"완료여부"}
                        placeholder={"완료여부"}
                        onChange={(value) => setIsComplete(value)}
                        value={isComplete}
                        options={[
                            {
                                value: "",
                                label: "전체",
                            },
                            {
                                value: "Y",
                                label: "완료",
                            },
                            {
                                value: "N",
                                label: "미완료",
                            },
                        ]}
                    />
                    <CnSelect
                        label={"구분"}
                        placeholder={"구분"}
                        onChange={(value) => setMergeType(value)}
                        value={mergeType}
                        options={[
                            {
                                value: "",
                                label: "전체",
                            },
                            {
                                value: "FILE",
                                label: "파일 취합",
                            },
                            {
                                value: "DATA",
                                label: "데이터 취합",
                            },
                        ]}
                    />
                    <CnInput
                        label="제목"
                        placeholder="제목 입력"
                        value={searchTitle ? searchTitle : ""}
                        onChange={(value) => setSearchTitle(value.target.value)}
                    />
                    <div className={cx("button-container")}>
                        <Button onClick={handleSubmit} label={"조회"} color={"primary"} />
                        <Button onClick={handleResetFilter} label={"검색조건 초기화"} />
                        {division === "MEMBER" && <Button onClick={handleCreateModal} label={"게시물 등록"} />}
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CnTable
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    loading={isLoading}
                    rowClassName={cx("table-row")}
                    totalCount={response?.allRecordCnt}
                    onRow={(data) => ({
                        onClick: () => {
                            handleDetailModal(data.noticeMergeId);
                        },
                    })}
                    dataSource={response?.resultList}
                    rowKey={"noticeMergeId"}
                    pagination={false}
                    page={page}
                    columns={[
                        {
                            title: "No",
                            key: "noticeMergeId",
                            dataIndex: "noticeMergeId",
                            width: "5%",
                        },
                        {
                            title: "취합기간",
                            key: "mergePeriod",
                            dataIndex: "mergePeriod",
                            width: "15%",
                        },
                        {
                            title: "중요도",
                            dataIndex: "important",
                            key: "important",
                            width: "5%",
                            render: (value) => <span> {["-", "★", "★★", "★★★"][value] || "-"}</span>,
                        },
                        {
                            title: "대상",
                            key: "targetName",
                            dataIndex: "targetName",
                            ellipsis: true,
                        },
                        {
                            title: "제목",
                            key: "title",
                            dataIndex: "title",
                            width: "20%",
                            ellipsis: true,
                        },
                        {
                            title: "담당자",
                            key: "managerName",
                            dataIndex: "manageName",
                        },
                        {
                            title: "대상지점",
                            key: "targetCnt",
                            dataIndex: "targetCnt",
                        },
                        {
                            title: "완료지점",
                            key: "completeCnt",
                            dataIndex: "completeCnt",
                        },
                        {
                            title: "미완료지점",
                            key: "incompleteCnt",
                            dataIndex: "incompleteCnt",
                        },
                        {
                            title: "등록일시",
                            key: "createDate",
                            dataIndex: "createDate",
                            width: "8%",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {MergeBoardPage};

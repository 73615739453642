import {useEffect, useState} from "react";
import {valueType} from "antd/es/statistic/utils";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button/button";
import {CnInput} from "~/components/cn-input/cn-input";
import {CnRangePicker} from "~/components/cn-range-picker/cn-range-picker";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {PageTitle} from "~/components/page-title/page-title";
import {useBoardList} from "~/data/board/use-board-list";
import {useUser} from "~/data/user/use-user";
import {NoticeBoardModal} from "~/pages/board/notice/modal/notice-board.modal";

import {NoticeBoardInput} from "./notice-board.interface";

import styles from "./notice-board.module.scss";

const cx = classNames.bind(styles);

const NoticeBoardPage = () => {
    const perPage = 50;
    const [page, setPage] = useState<number>(1);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [noticeId, setNoticeId] = useState<number | null>(null);
    const [inputs, setInputs] = useState<NoticeBoardInput>({
        dateRange: [dayjs(new Date()).add(-7, "d"), dayjs(new Date())],
        title: null,
    });

    const {user} = useUser();
    const [submitList, tableList, isListLoading] = useBoardList({
        category: "notice",
        title: inputs.title,
        startDate: inputs.dateRange ? inputs.dateRange[0].format("YYYY-MM-DD") : null,
        endDate: inputs.dateRange ? inputs.dateRange[1].format("YYYY-MM-DD") : null,
        page,
        perPage,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        submitList();
    }, [page]);

    const handleReset = () => {
        setInputs({
            dateRange: [dayjs(new Date()).add(-7, "d"), dayjs(new Date())],
            title: null,
        });
    };

    const handleSubmit = () => {
        setPage(1);
        submitList();
        window.scrollTo(0, 0);
    };

    return (
        <div>
            {isShowModal && (
                <NoticeBoardModal close={() => setIsShowModal(false)} noticeId={noticeId} refresh={submitList} />
            )}
            <PageTitle>공지사항</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnRangePicker
                        label="기간"
                        value={inputs.dateRange}
                        onChange={(value) => setInputs((prev) => ({...prev, dateRange: value}))}
                        allowClear={true}
                    />
                    <CnInput
                        label="제목"
                        placeholder="제목 검색"
                        value={inputs.title as valueType}
                        onChange={(e) => setInputs((prev) => ({...prev, title: e.target.value}))}
                    />
                    <div className={cx("button-container")}>
                        <Button label={"조회"} color={"primary"} onClick={handleSubmit} />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <div className={cx("table-label-container")}>
                            <span>
                                {tableList?.allRecordCnt ?? 0} 건{" "}
                                {`(${page}/${Math.ceil((tableList?.allRecordCnt ? tableList.allRecordCnt : 1) / perPage)} 페이지)`}
                            </span>
                            {user?.division === "MEMBER" && (
                                <Button
                                    label="등록하기"
                                    onClick={() => {
                                        setIsShowModal(true);
                                        setNoticeId(null);
                                    }}
                                />
                            )}
                        </div>
                    }
                    totalCount={tableList?.allRecordCnt ?? 0}
                    onPageChange={(e) => setPage(e)}
                    perPage={perPage}
                    page={page}
                    pagination={false}
                    loading={isListLoading}
                    rowKey={"noticeId"}
                    onRow={(record) => ({
                        onClick: () => {
                            setIsShowModal(true);
                            setNoticeId(record.id);
                        },
                    })}
                    dataSource={tableList?.list}
                    columns={[
                        {title: "No", key: "rowNum", dataIndex: "rowNum", width: "4%"},
                        {title: "작성자", key: "name", dataIndex: "name", width: "10%"},
                        {title: "제목", key: "title", dataIndex: "title", width: "50%", ellipsis: true},
                        {
                            title: "등록일",
                            key: "createDate",
                            dataIndex: "createDate",
                            width: "15%",
                            render: (record) => {
                                return dayjs(record).format("YYYY-MM-DD HH:mm");
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {NoticeBoardPage};

import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Table} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import Button from "~/components/button";
import {CnRangePicker} from "~/components/cn-range-picker";
import {CnSelect} from "~/components/cn-select";
import {CnTable} from "~/components/cn-table/cn-table";
import {FilterArea} from "~/components/filter-area";
import {PageTitle} from "~/components/page-title";
import {useReportSignList} from "~/data/check/sign";
import {Report} from "~/data/check/sign/sign.interface";
import {useUser} from "~/data/user";
import {useShop} from "~/hooks/use-shop/use-shop";
import {ApprovalParam} from "~/pages/check/approval/approval.interface";
import {ApprovalSignModal} from "~/pages/check/approval/sign/approval-sign.modal";

import styles from "./approval.module.scss";

const cx = classNames.bind(styles);

const CheckApprovalPage = () => {
    const perPage = 50;
    const [inspectorSignStatus, setInspectorSignStatus] = useState<1 | 2 | null>(null);
    const [managerSignStatus, setManagerSignStatus] = useState<1 | 2 | null>(null);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState<Report | null>(null);

    const {shop, ShopSelect, reset: shopReset} = useShop();
    const {type} = useParams<ApprovalParam>();
    const {user} = useUser();

    const title = useMemo(() => {
        if (type === "legal") return "법정점검";
        else if (type === "checklist") return "체크리스트";
        else if (type === "extra") return "추가점검";
        else if (type === "normal") return "일반점검";
    }, [type]);

    const [submit, response, isLoading] = useReportSignList({
        type: type as string,
        buildingId: shop,
        startDate: dateRange![0]?.format("YYYY-MM-DD"),
        endDate: dateRange![1].format("YYYY-MM-DD"),
        pageNo: page,
        inspectorState: inspectorSignStatus,
        managerState: managerSignStatus,
        recordPerPage: perPage,
    });

    useEffect(() => {
        handleResetFilter();
        window.scrollTo(0, 0);
        submit();
    }, [type]);

    useEffect(() => {
        window.scrollTo(0, 0);
        submit();
    }, [page]);

    const handleSubmit = () => {
        setPage(1);
        submit();
    };

    const handleResetFilter = () => {
        shopReset();
        setPage(1);
        setDateRange([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
        setManagerSignStatus(null);
        setInspectorSignStatus(null);
    };

    return (
        <Fragment>
            <div>
                {isModalOpen && <ApprovalSignModal report={isModalOpen} onClose={() => setIsModalOpen(null)} />}
                <PageTitle>{title + " 결재하기"}</PageTitle>
                <FilterArea>
                    <div className={cx("filter-inner")}>
                        <CnRangePicker label={"기간"} value={dateRange} onChange={(value) => setDateRange(value)} />
                        {user?.division === "MEMBER" ? <ShopSelect /> : null}
                        <CnSelect
                            label={"점검자결재상태"}
                            showSearch
                            placeholder="점검자결재상태"
                            onChange={(value) => setInspectorSignStatus(value)}
                            value={inspectorSignStatus}
                            options={[
                                {
                                    value: null,
                                    label: "전체",
                                },
                                {
                                    value: 1,
                                    label: "완료",
                                },
                                {
                                    value: 2,
                                    label: "미완료",
                                },
                            ]}
                        />
                        <CnSelect
                            label={"관리자결재상태"}
                            showSearch
                            placeholder="관리자결재상태"
                            onChange={(value) => setManagerSignStatus(value)}
                            value={managerSignStatus}
                            options={[
                                {
                                    value: null,
                                    label: "전체",
                                },
                                {
                                    value: 1,
                                    label: "완료",
                                },
                                {
                                    value: 2,
                                    label: "미완료",
                                },
                            ]}
                        />
                        <div className={cx("button-container")}>
                            <Button onClick={handleSubmit} label={"조회"} color={"primary"} />
                            <Button onClick={handleResetFilter} label={"검색조건 초기화"} />
                        </div>
                    </div>
                </FilterArea>
                <div className={cx("table-container")}>
                    <CnTable
                        totalCount={response?.allRecordCnt}
                        onPageChange={(page) => setPage(page)}
                        perPage={perPage}
                        page={page}
                        dataSource={response?.resultList.map((row: any) => {
                            row["key"] = row.checkHistoryId;
                            return row;
                        })}
                        pagination={false}
                        loading={isLoading}
                        onRow={(data, index) => ({
                            onClick: () => setIsModalOpen(data),
                        })}
                        columns={[
                            {
                                title: "No",
                                key: "index",
                                render: (_, __, index) => Number((page - 1) * perPage + index + 1).toLocaleString(),
                                align: "center",
                            },
                            {
                                title: "일자",
                                dataIndex: "orderDate",
                                key: "orderDate",
                            },
                            {
                                title: "보고서명",
                                dataIndex: "checkFormName",
                                key: "checkFormName",
                            },
                            {
                                title: "구역",
                                dataIndex: "regionName",
                                key: "regionName",
                            },
                            {
                                title: "점검자 결재",
                                dataIndex: "inspectorSignStatus",
                                key: "inspectorSignStatus",
                                align: "center",
                            },
                            {
                                title: "관리자 결재",
                                dataIndex: "managerSignStatus",
                                key: "managerSignStatus",
                                align: "center",
                            },
                        ]}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export {CheckApprovalPage};

import React, {useEffect, useState} from "react";
import {Spin} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import Button from "~/components/button/button";
import {PageTitle} from "~/components/page-title";

import {useMachineDetail} from "./use-detail";

import styles from "./equipment-detail.module.scss";

const cx = classNames.bind(styles);

const EquipmentDetailModal = ({onClose, machineId}: any) => {
    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // 상세 정보
    const [submit, response, isDetailLoading] = useMachineDetail(machineId);
    const detailData = response?.machineDTO;

    // 초기 셋팅
    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);
        submit();
    }, []);

    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => onClose(), 500);
    }, [isLoading]);

    useEffect(() => {
        const handleCloseESC = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            document.addEventListener("keydown", handleCloseESC);
        }
        return () => {
            document.removeEventListener("keydown", handleCloseESC);
        };
    }, [isLoading]);

    const handleClose = () => {
        setIsLoading(false);
    };

    return (
        <div className={cx("container", {active: isLoading})}>
            <div
                className={cx("inner", {active: isLoading}, "large")}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <div className={cx("contents-container")}>
                    <PageTitle>설비현황상세</PageTitle>
                    {isDetailLoading ? (
                        <div className={cx("loading")}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <>
                            <div className={cx("info")}>
                                <h3>기본정보</h3>
                                <table className={cx("table")}>
                                    <colgroup>
                                        <col width="40%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td rowSpan={10} className={cx("image-cell")}>
                                                <img
                                                    style={{width: "100%"}}
                                                    src={
                                                        detailData?.base64Image.startsWith("/9j/")
                                                            ? `data:image/jpeg;base64,${detailData?.base64Image}`
                                                            : detailData?.base64Image.startsWith("iVBORw0KGgo")
                                                              ? `data:image/png;base64,${detailData?.base64Image}`
                                                              : `data:image/webp;base64,${detailData?.base64Image}`
                                                    }
                                                />
                                            </td>
                                            <th>지점명</th>
                                            <td>{detailData?.buildingName}</td>
                                            <th>장비명</th>
                                            <td>{detailData?.machineName}</td>
                                        </tr>
                                        <tr>
                                            <th>카테고리</th>
                                            <td colSpan={3}>{detailData?.machineCategoryName}</td>
                                        </tr>
                                        <tr>
                                            <th>제조사</th>
                                            <td>{detailData?.machineManufactureCompany}</td>
                                            <th>구역</th>
                                            <td>{detailData?.machineRegionName}</td>
                                        </tr>
                                        <tr>
                                            <th>설치연월</th>
                                            <td>
                                                {dayjs(detailData?.machineInstallDate).isValid()
                                                    ? dayjs(detailData?.machineInstallDate).format("YYYY-MM")
                                                    : ""}
                                            </td>
                                            <th>제조연월</th>
                                            <td>
                                                {dayjs(detailData?.machineManufactureDate).isValid()
                                                    ? dayjs(detailData?.machineManufactureDate).format("YYYY-MM")
                                                    : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>제조번호</th>
                                            <td colSpan={3}>{detailData?.machineManufactureNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>모델명</th>
                                            <td colSpan={3}>{detailData?.machineModelName}</td>
                                        </tr>
                                        <tr>
                                            <th>설치업체</th>
                                            <td colSpan={3}>{detailData?.machineInstallCompany}</td>
                                        </tr>
                                        <tr>
                                            <th>장비코드</th>
                                            <td colSpan={3}>{detailData?.machineLocalCode}</td>
                                        </tr>
                                        <tr>
                                            <th>사용여부</th>
                                            <td>{detailData?.machineUseYn}</td>
                                            <th>보유수량</th>
                                            <td>{detailData?.machineQuantitiy}</td>
                                        </tr>
                                        <tr>
                                            <th>비고</th>
                                            <td colSpan={3}>{detailData?.machineNote}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {detailData?.machineCheckDTOList.filter((m) => m.isDel === "N").length !== 0 && (
                                <div className={cx("info")}>
                                    <h3>추가정보</h3>
                                    <table className={cx("table")}>
                                        <colgroup>
                                            <col width="30%" />
                                            <col width="70%" />
                                        </colgroup>
                                        <tbody>
                                            {detailData?.machineCheckDTOList
                                                .filter((m) => m.isDel === "N")
                                                .map(
                                                    (
                                                        el: {machineCheckName: string; machineCheckAnswer: string},
                                                        index: number,
                                                    ) => (
                                                        <tr key={index}>
                                                            <th>{el.machineCheckName}</th>
                                                            <td>{el.machineCheckAnswer}</td>
                                                        </tr>
                                                    ),
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {detailData?.machineHistoryDTOList.filter((m) => m.isDel === "N").length !== 0 && (
                                <div className={cx("info")}>
                                    <h3>장비이력</h3>
                                    <table className={cx("table")}>
                                        <colgroup>
                                            <col width="3%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="42%" />
                                            <col width="25%" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>날짜</th>
                                                <th>비용</th>
                                                <th>구분</th>
                                                <th>업무내역</th>
                                                <th>비고</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailData?.machineHistoryDTOList
                                                .filter((m) => m.isDel === "N")
                                                .map(
                                                    (
                                                        el: {
                                                            machineHistoryDate: number;
                                                            machineHistoryCost: number;
                                                            machineHistoryContent: string;
                                                            machineHistoryNote: string;
                                                            type: string;
                                                        },
                                                        index: number,
                                                    ) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {dayjs(new Date(el.machineHistoryDate)).format(
                                                                    "YYYY.MM.DD",
                                                                )}
                                                            </td>
                                                            <td>{el.machineHistoryCost.toLocaleString()}</td>
                                                            <td>
                                                                {el.type === "self"
                                                                    ? "자체개선"
                                                                    : el.type === "construction"
                                                                      ? "실제공사"
                                                                      : ""}
                                                            </td>
                                                            <td>{el.machineHistoryContent}</td>
                                                            <td>{el.machineHistoryNote}</td>
                                                        </tr>
                                                    ),
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className={cx("button-container")}>
                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                </div>
            </div>
        </div>
    );
};

export {EquipmentDetailModal};

import {useEffect, useState} from "react";
import {faDownload, faPaperclip, faTrashCan, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, Input, List, Spin, Upload} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";
import {v4 as uuidv4} from "uuid";

import Button from "~/components/button/button";
import {MergeGridItem} from "~/components/merge/merge-grid-item/merge-grid-item";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title/page-title";
import {useInquiryBoardDetail} from "~/data/board/inquiry/use-inquiry-board";
import {FileInfo} from "~/data/board/use-board-list.interface";
import {useCommonBoard} from "~/data/board/use-common-board";
import {useUser} from "~/data/user/use-user";

import {NoticeBoardModalInput, NoticeBoardModalProps} from "./notice-board.modal.interface";

import styles from "./notice-board.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const NoticeBoardModal = ({close, noticeId, refresh}: NoticeBoardModalProps) => {
    const [inputs, setInputs] = useState<NoticeBoardModalInput>({
        title: "",
        content: "",
        files: [],
    });
    const [deleteFileList, setDeleteFileList] = useState<number[]>([]);
    const [fileList, setFileList] = useState<FileInfo[]>([]);
    const [isOwner, setIsOwner] = useState<boolean>(false);

    const {user} = useUser();
    const {submitDetail, detail, isDetailLoading} = useInquiryBoardDetail(noticeId ?? 0);
    const {register, update, deleteBoard, fileDownload} = useCommonBoard({noticeId: noticeId ?? 0, category: "notice"});

    useEffect(() => {
        if (noticeId) submitDetail();
    }, [noticeId]);

    useEffect(() => {
        if (detail) {
            setFileList(detail?.files ?? []);
            setIsOwner(detail?.commonBoard.username === user?.username);
            setInputs((prev) => ({
                ...prev,
                title: detail?.commonBoard.title,
                content: detail?.commonBoard.content,
            }));
        }
    }, [detail]);

    useEffect(() => {
        const temp = inputs.files.map((data) => ({
            id: uuidv4(),
            fileOriginName: data.name,
            fileStorePath: "",
        }));
        setFileList((prev) => {
            const mergedList = [...prev, ...temp];
            return mergedList.filter(
                (file, index, self) => index === self.findIndex((f) => f.fileOriginName === file.fileOriginName),
            );
        });
    }, [inputs.files]);

    const handleRemoveFile = (file: FileInfo) => {
        setFileList((prev) => [...prev.filter((item) => item.id !== file.id)]);
        setDeleteFileList((prev) => [...prev, Number(file.id)]);
        setInputs((prev) => ({...prev, files: prev.files.filter((item) => item.name !== file.fileOriginName)}));
    };

    const handleConfirm = () => {
        if (noticeId) {
            Swal.fire({
                text: "수정하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "수정하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    await update({
                        title: inputs.title,
                        content: inputs.content,
                        files: inputs.files,
                        removalFileIds: deleteFileList,
                    });
                    setTimeout(refresh, 500);
                    close();
                }
            });
        } else {
            Swal.fire({
                text: "등록하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "등록하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    await register({
                        title: inputs.title,
                        content: inputs.content,
                        files: inputs.files,
                    });
                    setTimeout(refresh, 500);

                    close();
                }
            });
        }
    };
    const handleDelete = () => {
        if (noticeId)
            Swal.fire({
                text: "삭제하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "삭제하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    await deleteBoard();
                    setTimeout(refresh, 500);
                    close();
                }
            });
    };

    return (
        <SideModal
            onClose={close}
            onConfirm={handleConfirm}
            confirmLabel={noticeId ? (isOwner ? "수정하기" : undefined) : "등록하기"}
            extraButton={user?.division === "MEMBER" && <Button label="삭제하기" onClick={handleDelete} />}
        >
            <PageTitle>공지사항 {!noticeId && "등록하기"}</PageTitle>
            {isDetailLoading ? (
                <div className={cx("loading")}>
                    <Spin size="large" />
                </div>
            ) : (
                <div className={cx("form-container")}>
                    <div className={cx("detail-content")}>
                        <MergeGridItem name={"제목"} span>
                            {noticeId && !isOwner ? (
                                detail?.commonBoard.title
                            ) : (
                                <Input
                                    className={cx("input")}
                                    placeholder="제목을 입력하세요"
                                    value={inputs.title}
                                    onChange={(e) => setInputs((prev) => ({...prev, title: e.target.value}))}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"내용"} span extraClassName={"padding-container"}>
                            {noticeId && !isOwner ? (
                                <pre className={cx("content")}>{detail?.commonBoard.content}</pre>
                            ) : (
                                <Input.TextArea
                                    className={cx("text-area")}
                                    placeholder="내용을 입력하세요"
                                    value={inputs.content}
                                    onChange={(e) => setInputs((prev) => ({...prev, content: e.target.value}))}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"첨부파일"} span extraClassName={"upload-container"}>
                            <div className={gridCx("file-container")}>
                                <Upload.Dragger
                                    className={gridCx("upload-dragger")}
                                    name={"file"}
                                    multiple={true}
                                    maxCount={5}
                                    showUploadList={false}
                                    disabled={noticeId !== null && !isOwner}
                                    beforeUpload={(e) => {
                                        setInputs((prev) => {
                                            const newFiles = [...prev.files];
                                            newFiles.push(e);
                                            return {
                                                ...prev,
                                                files: newFiles,
                                            };
                                        });
                                        return false;
                                    }}
                                >
                                    {fileList.length ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={fileList}
                                            className={gridCx("file-list")}
                                            renderItem={(file, index) => (
                                                <List.Item
                                                    className={gridCx("file-list-item")}
                                                    key={index}
                                                    actions={[
                                                        <ButtonAntd
                                                            type="link"
                                                            key={index}
                                                            icon={<FontAwesomeIcon icon={faDownload} />}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                fileDownload(file.id);
                                                            }}
                                                        />,
                                                        <ButtonAntd
                                                            type="link"
                                                            danger
                                                            key={index}
                                                            icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRemoveFile(file);
                                                            }}
                                                            disabled={!isOwner}
                                                        />,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                        title={file.fileOriginName}
                                                        className={gridCx("file-list-item-meta")}
                                                        style={{textAlign: "left"}}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <>
                                            {noticeId !== null && !isOwner ? (
                                                <p>첨부된 파일이 없습니다</p>
                                            ) : (
                                                <p>드래그하여 파일을 업로드 하거나 파일을 선택하세요</p>
                                            )}
                                        </>
                                    )}
                                </Upload.Dragger>
                            </div>
                        </MergeGridItem>
                    </div>
                </div>
            )}
        </SideModal>
    );
};

export {NoticeBoardModal};

import {useEffect, useMemo, useState} from "react";
import dayjs from "dayjs";

import {httpRequest} from "~/fetch/common/http-request";

import {
    MachineBoardRefinedData,
    MachineBoardRequest,
    MachineBoardResponse,
    MachineChartRefinedData,
    MachineChartResponse,
} from "./use-machine.interface";

const useMachineBoardList = (config: MachineBoardRequest) => {
    const [response, setResponse] = useState<MachineBoardRefinedData | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const {
            pageNo,
            isNoImage,
            searchMachineBuildingId,
            searchMachineCategoryCode,
            searchMachineSectorId,
            searchMachineUseYn,
            searchProcessCode,
            trainerSectorId,
        } = config;
        return httpRequest<MachineBoardRequest, MachineBoardResponse>({
            method: "GET",
            url: "/machine/ml",
            params: {
                pageNo,
                isNoImage,
                searchMachineBuildingId,
                searchMachineCategoryCode,
                searchMachineSectorId,
                searchMachineUseYn,
                searchProcessCode,
                trainerSectorId,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const response = await request.submit();

            const data = response.data.machineCategoryDTOList.map((data: any) => ({
                machineId: data.machineId,
                buildingName: data.buildingName,
                machineName: data.machineName,
                machineCategoryName: data.machineCategoryName,
                machineInstallCompany: data.machineInstallCompany,
                machineInstallDate: data.machineInstallDate,
                machineManufactureCompany: data.machineManufactureCompany,
                machineUseYn: data.machineUseYn,
                process: data.process,
                updateDate: data.updateDate,
                machineCategoryCode: data.machineCategoryCode,
                buildingId: data.buildingId,
                companyId: data.companyId,
            }));

            setResponse({
                resultList: data,
                allRecordCnt: response.data.machineSearchVO.maxCount,
            });
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

const useMachineBoardChart = () => {
    // MachineChartRefinedData
    const [chartResponse, setChartResponse] = useState<MachineChartRefinedData | any>({
        sector1: 0,
        sector2: 0,
        sector3: 0,
        sector4: 0,
        sector5: 0,
        sector6: 0,
        electricity: 0,
        firefighting: 0,
        building: 0,
        autoSorter: 0,
        elevator: 0,
        freezing: 0,
        machine: 0,
        useY: 0,
        useN: 0,
    });
    // const [chartError, setChartError] = useState<any>(null);
    const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);

    useEffect(() => {
        setIsLoadingChart(true);
        const requestMachine = httpRequest<null, MachineChartResponse>({
            method: "GET",
            url: "/machine/ml/chart",
            params: {
                isNoImage: "ALL",
                searchMachineCategoryCode: "ALL",
                searchMachineUseYn: "ALL",
            },
        });

        requestMachine
            .submit()
            .then((res) => {
                setChartResponse(res.data);
            })
            .finally(() => {
                setIsLoadingChart(false);
            });
    }, []);

    return [chartResponse, isLoadingChart];
};

// mobile - 설비기사 로그인시 config 필요없음
const useMobileMachineBoardList = (config: any) => {
    const [response, setResponse] = useState<MachineBoardRefinedData | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<null, MachineBoardResponse>({
            method: "GET",
            url: "/machine/ml",
            params: config,
        });
    }, []);

    const submit = async () => {
        try {
            setIsLoading(true);
            const response = await request.submit();

            const data = response.data.machineCategoryDTOList.map((data: any) => ({
                machineId: data.machineId,
                buildingName: data.buildingName,
                machineName: data.machineName,
                machineCategoryName: data.machineCategoryName,
                machineInstallCompany: data.machineInstallCompany,
                machineInstallDate: data.machineInstallDate,
                machineManufactureCompany: data.machineManufactureCompany,
                machineUseYn: data.machineUseYn,
                process: data.process,
                updateDate: data.updateDate,
                machineCategoryCode: data.machineCategoryCode,
                buildingId: data.buildingId,
                companyId: data.companyId,
            }));

            setResponse({
                resultList: data,
                allRecordCnt: response.data.machineSearchVO.maxCount,
            });
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

// mobile - 설비기사 로그인시 설비코드 가져오기
const useMobileProcessData = () => {
    const [response, setResponse] = useState<{code: string; name: string}[]>([{code: "", name: ""}]);
    // const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const requestMachine = httpRequest({
            url: "/common/detailCodes?groupCd=LC007",
        });

        requestMachine
            .submit()
            .then((res: any) => {
                setIsLoading(true);
                const getProcessData = res.data.map((i: {code: string; description: string}) => ({
                    code: i.code,
                    name: i.description,
                }));
                setResponse(getProcessData);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return {response, isLoading};
};

export {useMachineBoardChart, useMachineBoardList, useMobileMachineBoardList, useMobileProcessData};

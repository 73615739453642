import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {ReportListRequest, ReportListResponse} from "./sign.interface";

const useReportSignList = (config: ReportListRequest) => {
    const [response, setResponse] = useState<ReportListResponse | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const {
            buildingId,
            endDate,
            inspectorState = null,
            managerState = null,
            pageNo,
            recordPerPage = 50,
            reportType = null,
            startDate,
            startRecordNo = 0,
        } = config;

        return httpRequest<ReportListRequest, ReportListResponse>({
            method: "GET",
            url: "/lotte/fms/sign/list",
            params: {
                buildingId,
                endDate,
                inspectorState,
                managerState,
                pageNo,
                recordPerPage,
                reportType,
                startDate,
                startRecordNo,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const response = await request.submit();
            setResponse(response.data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

export {useReportSignList};

import {ChangeEvent, useEffect, useState} from "react";
import {Input} from "antd";
import classNames from "classnames/bind";

import {MergeAnswerListProps} from "./merge-answer-list.interface";

import styles from "./merge-answer-list.module.scss";

const cx = classNames.bind(styles);

const MergeAnswerList = ({
    noticeMergeQuestionId,
    question,
    index,
    answer,
    isNone,
    setQnaList,
}: MergeAnswerListProps) => {
    const [inputs, setInputs] = useState<{answer: string; isNone: "Y" | "N"}>({
        answer: "",
        isNone: "N",
    });

    useEffect(() => {
        setInputs({answer, isNone});
    }, [answer]);

    const onChangeCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
        setInputs(() => ({
            answer: "",
            isNone: e.target.checked ? "Y" : "N",
        }));

        setQnaList((prev) =>
            prev.map((item) =>
                item.noticeMergeQuestionId === noticeMergeQuestionId
                    ? {...item, answer: "", isNone: e.target.checked ? "Y" : "N"}
                    : item,
            ),
        );
    };

    const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setInputs((prev) => ({
            ...prev,
            answer: e.target.value,
        }));
        setQnaList((prev) =>
            prev.map((item) =>
                item.noticeMergeQuestionId === noticeMergeQuestionId ? {...item, answer: e.target.value} : item,
            ),
        );
    };

    return (
        <div className={cx("container")}>
            <div className={cx("index")}>{index}</div>
            <div className={cx("content")}>
                <div className={cx("info")}>
                    <div className={cx("question")}>{question}</div>
                    <div className={cx("not-applicable")}>
                        <div>해당없음</div>
                        <input
                            type="checkbox"
                            name={"isNone"}
                            checked={inputs.isNone === "Y"}
                            onChange={onChangeCheckBox}
                            className={cx("check-box")}
                        />
                    </div>
                </div>
                <div className={cx("textarea-container")}>
                    <Input.TextArea
                        className={cx("text-area")}
                        value={inputs.answer}
                        onChange={onChangeTextArea}
                        disabled={inputs.isNone === "Y"}
                    />
                </div>
            </div>
        </div>
    );
};

export {MergeAnswerList};

import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {useBlocker, useLocation, useNavigate, useParams} from "react-router-dom";
import {faKeyboard} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import type {Blocker} from "@remix-run/router";
import {message, Radio, Spin, Upload} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button";
import button from "~/components/button";
import {useMobileCheck} from "~/data/check/mobile/use-mobile-check-list";
import {FormItem, FormRaw, SaveForm} from "~/data/check/mobile/use-mobile-check-list.interface";
import {useUser} from "~/data/user";
import {checkFileUpload, singleFileUpload} from "~/fetch/single-file-upload";
import {CheckFormCategory, CheckTableData} from "~/pages/mobile/check/check.page.interface";

import styles from "./check.module.scss";

const cx = classNames.bind(styles);

const CheckPage: React.FC = () => {
    const {checkFormArr, date, regionId} = useParams<{
        checkFormArr: string;
        date: string;
        regionId: string;
    }>();

    const navigate = useNavigate();

    const [spin, setSpin] = useState(false);
    const blocked = useRef(true);
    const blocker: Blocker = useBlocker(({currentLocation, nextLocation}) => {
        return blocked.current && currentLocation.pathname !== nextLocation.pathname;
    });

    useEffect(() => {
        if (blocker.state === "blocked") {
            Swal.fire({
                title: "정말 뒤로 가시겠습니까?",
                text: "변경사항이 저장되지 않을 수 있습니다.",
                showCancelButton: true,
                confirmButtonText: "예",
                cancelButtonText: "아니오",
            }).then((result) => {
                if (result.isConfirmed && blocker.proceed) {
                    blocker.proceed();
                }
            });
        }
    }, [blocker]);

    const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
    const [remark, setRemark] = useState<string>("");

    const {loading, raw, save} = useMobileCheck(date as string, checkFormArr as string, Number(regionId));

    const flatted = useRef<Map<number, FormItem>>(new Map());

    useEffect(() => {
        raw.map((row) => {
            row.formItems.map((item) => {
                flatted.current.set(item.checkItemHistoryId, item);
            });
        });
    }, [raw]);

    useEffect(() => {
        setStartDate(dayjs(new Date()));
        setRemark(raw[0]?.remark || "");
    }, [raw]);

    const [showFloating, setShowFloating] = useState<false | HTMLInputElement>(false);

    useEffect(() => {
        const handleFocusCheck = (event: FocusEvent) => {
            if (event.target instanceof HTMLInputElement) {
                if (event.type === "focusin") setShowFloating(event.target);
                else setShowFloating(false);
            }
        };

        window.addEventListener("focusin", handleFocusCheck);
        window.addEventListener("focusout", handleFocusCheck);

        return () => {
            window.removeEventListener("focusin", handleFocusCheck);
            window.removeEventListener("focusout", handleFocusCheck);
        };
    }, []);

    const {user} = useUser();

    const handleSubmit = () => {
        setSpin(true);
        const forms: Array<SaveForm> = [];
        raw.map((item) => {
            forms.push({
                remark: remark,
                checkHistoryId: item.checkHistoryId,
                checkFormId: item.checkFormId,
                employeeId: user!.userId,
                orderDate: date as string,
                regionId: Number(regionId),
                startTime: startDate.format("YYYY-MM-DD hh:mm:ss"),
                endTime: dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                notiTime: item.notiTime,
                state: "ACTIVE",
                result: "BEFORE",
                employeeName: user!.name,
                checkItemHistoryList: [
                    ...item.formItems.map((item) => {
                        const formItem = flatted.current.get(item.checkItemHistoryId);
                        return {
                            checkItemHistoryId: formItem!.checkItemHistoryId,
                            checkHistoryId: formItem!.checkHistoryId,
                            checkFormItemName: formItem!.checkFormItemName,
                            result: formItem!.result as "PROTECTION" | "NORMAL" | "POOR" | "NONE",
                            image: formItem!.image,
                            value: formItem!.value,
                            imageYn: formItem!.imageYn,
                            valueYn: formItem!.valueYn,
                            imageUploadWait: formItem!.imageUploadWait as "Y" | "N",
                            orderNumber: formItem!.orderNumber,
                            reportItemId: formItem!.reportItemId,
                            publicYn: formItem!.reportItemId ? ("Y" as "Y" | "N") : ("N" as "Y" | "N"),
                        };
                    }),
                ],
            });
        });
        Promise.all(forms.map((form) => save(form, user!.buildingId, form.checkHistoryId)))
            .then((res) => {
                message.success("저장되었습니다");
                blocked.current = false;
                navigate(-1);
            })
            .catch(() => {
                Swal.fire({
                    title: "오류가 발생했습니다.",
                    text: "문제가 지속될 경우 관리자에 문의하세요.",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => {
                setSpin(false);
            });
    };

    const transformData = (raw: FormRaw[]): CheckTableData[] => {
        const response: CheckTableData[] = [];

        raw.forEach((form) => {
            let checkTableData = response.find((data) => data.checkFormName === form.checkFormName);

            if (!checkTableData) {
                checkTableData = {
                    checkFormName: form.checkFormName,
                    children: [],
                };
                response.push(checkTableData);
            }

            form.formItems.forEach((item) => {
                const itemNameParts = item.checkFormItemName.split(" > ");
                let currentCategoryArray = checkTableData!.children;

                itemNameParts.forEach((part, index) => {
                    let existingCategory = currentCategoryArray.find((cat) => cat.subject === part);

                    if (!existingCategory) {
                        existingCategory = {
                            subject: part,
                            children: [],
                        };
                        currentCategoryArray.push(existingCategory);
                    }

                    if (index === itemNameParts.length - 1) {
                        // 마지막 레벨의 subject일 때 row에 데이터를 추가
                        if (!existingCategory.row) {
                            existingCategory.row = {};
                        }

                        existingCategory.row[form.notiTime] = item;
                    } else {
                        currentCategoryArray = existingCategory.children;
                    }
                });
            });
        });

        return response;
    };

    const data = useMemo(() => transformData(raw), [raw]);

    const handleChange = (item: FormItem) => {
        flatted.current.set(item.checkItemHistoryId, {...item});
    };

    const cnt = useRef<number>(1);

    const inputs = useRef<{
        [id: number]: HTMLInputElement;
    }>({});

    const renderChildren = (child: Array<CheckFormCategory>) => {
        return (
            <Fragment>
                {child.map((item) => {
                    return (
                        <Fragment key={item.subject}>
                            {item.row ? (
                                <div className={cx("section")}>
                                    <table className={cx("table")}>
                                        <colgroup>
                                            {Object.keys(item.row as any).length > 1 ? (
                                                <Fragment>
                                                    <col width={100} />
                                                    <col />
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <col />
                                                    <col />
                                                </Fragment>
                                            )}
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                {Object.keys(item.row as any).length > 1 ? (
                                                    <Fragment>
                                                        <th colSpan={2}>
                                                            <span>{cnt.current++ || cnt.current}.&nbsp;</span>
                                                            <span>{item.subject}</span>
                                                        </th>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <th>
                                                            <span>{cnt.current++ || cnt.current}.&nbsp;</span>
                                                            <span>{item.subject}</span>
                                                        </th>
                                                    </Fragment>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(item.row).map((notiTime) => {
                                                const isShowNotiTime = Object.keys(item.row as any).length > 1;
                                                return (
                                                    <tr key={notiTime + item.subject}>
                                                        {isShowNotiTime && <td>{notiTime.substring(0, 5)}</td>}
                                                        <td>
                                                            {item.row![notiTime].valueYn ? (
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        inputMode="numeric"
                                                                        ref={(ref) => {
                                                                            inputs.current[
                                                                                item.row![notiTime]
                                                                                    .checkItemHistoryId as number
                                                                            ] = ref as HTMLInputElement;
                                                                        }}
                                                                        defaultValue={item.row![notiTime].value}
                                                                        onChange={(e) => {
                                                                            handleChange({
                                                                                ...item.row![notiTime],
                                                                                value: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <button
                                                                        tabIndex={-1}
                                                                        className={cx("set-na")}
                                                                        onClick={() => {
                                                                            inputs.current[
                                                                                item.row![notiTime]
                                                                                    .checkItemHistoryId as number
                                                                            ].value = "N/A";
                                                                            handleChange({
                                                                                ...item.row![notiTime],
                                                                                value: "N/A",
                                                                            });
                                                                        }}
                                                                    >
                                                                        N/A
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <Radio.Group
                                                                        defaultValue={item.row![notiTime].result}
                                                                        onChange={(e) => {
                                                                            handleChange({
                                                                                ...item.row![notiTime],
                                                                                result: e.target.value,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <Radio.Button value="PROTECTION">
                                                                            양호
                                                                        </Radio.Button>
                                                                        <Radio.Button value="NORMAL">보통</Radio.Button>
                                                                        <Radio.Button value="POOR">불량</Radio.Button>
                                                                        <Radio.Button value="NONE">
                                                                            해당없음
                                                                        </Radio.Button>
                                                                    </Radio.Group>
                                                                </div>
                                                            )}
                                                            {item.row![notiTime].imageYn && (
                                                                <div>
                                                                    <Upload
                                                                        customRequest={singleFileUpload}
                                                                        onChange={(e) => {
                                                                            if (e.fileList.length === 0) {
                                                                                handleChange({
                                                                                    ...item.row![notiTime],
                                                                                    image: "",
                                                                                });
                                                                            }

                                                                            if (e.file.status === "done") {
                                                                                handleChange({
                                                                                    ...item.row![notiTime],
                                                                                    image: e.file.response.filePath,
                                                                                });
                                                                            }
                                                                        }}
                                                                        accept="image/*"
                                                                        maxCount={1}
                                                                    >
                                                                        <Button label="사진업로드" />
                                                                    </Upload>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className={cx("subject")}>
                                    <h4>{item.subject}</h4>
                                    {renderChildren(item.children)}
                                </div>
                            )}
                        </Fragment>
                    );
                })}
            </Fragment>
        );
    };

    console.log(showFloating);

    return (
        <Fragment>
            <Spin spinning={spin} fullscreen />
            {data.map((form) => {
                cnt.current = 1;
                return (
                    <div key={form.checkFormName} className={cx("form")}>
                        <h4 className={cx("checkFormName")}>{form.checkFormName}</h4>
                        {renderChildren(form.children)}
                    </div>
                );
            })}

            <div className={cx("remark-area")}>
                <h4 className={cx("remark-area-title")}>비고</h4>
                <textarea onChange={(e) => setRemark(e.target.value)} defaultValue={remark} />
            </div>

            <Button size={"large"} label={"저장하기"} fullWidth onClick={handleSubmit} />
            {showFloating && (
                <button
                    className={cx("change-keyboard")}
                    onMouseDown={(event) => {
                        event.preventDefault();
                        showFloating.focus();
                    }}
                    onClick={() => {
                        if (showFloating.getAttribute("inputMode") === "numeric") {
                            showFloating.setAttribute("inputMode", "text");
                        } else showFloating.setAttribute("inputMode", "numeric");
                    }}
                >
                    <FontAwesomeIcon icon={faKeyboard} />
                </button>
            )}
        </Fragment>
    );
};

export {CheckPage};

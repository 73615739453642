import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {DatePicker, Upload} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {FilterItem} from "~/components/filter-item/filter-item";
import {PageTitle} from "~/components/page-title/page-title";
import {
    useCostMaintenanceExcelDownload,
    useCostMaintenanceExcelUpload,
    useCostMaintenanceFormatDownload,
    useCostMaintenanceLightingList,
} from "~/data/cost/maintenance-lighting/use-maintenance-lighting";
import {useShopCascader} from "~/hooks/use-shop-cascader/use-shop-cascader";
import {CostMaintenanceLightingModal} from "~/pages/cost/maintenance-lighting/modal/cost-maintenance-lighting.modal";
import {useLocalStorage} from "~/utills/useLocalStorage";

import styles from "./cost-maintenance-lighting.module.scss";
import tableStyles from "~/components/custom-label-table/custom-label-table.module.scss";
import filterStyles from "~/components/filter-item/filter-item.module.scss";

const cx = classNames.bind(styles);
const filterCx = classNames.bind(filterStyles);
const tableCx = classNames.bind(tableStyles);

const CostMaintenanceAndLightingPage = () => {
    const perPage = 30;
    const {trainerSectorId, dates} = Object.fromEntries(new URLSearchParams(useLocation().search));
    const [date, setDate] = useState({
        year: dates ? dayjs(dates.split(",")[0]) : dayjs(new Date()),
        month: dates ? dayjs(dates.split(",")[1]) : dayjs(new Date()),
    });
    const [file, setFile] = useState<File | null>(null);
    const [page, setPage] = useState<number>(1);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<{no: null | number; buildingId: number; sectorId: number}>({
        no: null,
        buildingId: 0,
        sectorId: 0,
    });
    const [userInfo, setUserInfo] = useState<{division: "MEMBER" | "MANAGER" | "EMPLOYEE"; userId: number} | null>(
        null,
    );

    const {setItem, getItem} = useLocalStorage();
    const {ShopCascader, selected, setSelected} = useShopCascader(
        300,
        trainerSectorId ? ["TRAINER", trainerSectorId, "0"] : undefined,
    );

    // 테이블 데이터
    const [submit, response, isLoading] = useCostMaintenanceLightingList({
        date: date,
        selected,
        perPage: perPage,
        pageNo: page,
    });
    // 양식 다운
    const [downloadFormat] = useCostMaintenanceFormatDownload({
        date,
        trainerSectorId: selected[1] ?? 0,
    });

    // 엑셀 다운
    const [downloadExcel] = useCostMaintenanceExcelDownload({
        date,
        selected,
    });
    //엑셀 업로드
    const [excelUpload] = useCostMaintenanceExcelUpload({
        date: date,
        file: file,
    });

    // 계정 권한
    useEffect(() => {
        const user = JSON.parse(getItem("LM_USER")!);
        setUserInfo({division: user.division, userId: user.userId});
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        submit();
    }, [page]);

    useEffect(() => {
        if (!isShowModal) submit();
    }, [isShowModal]);

    const handleModal = (no: number, buildingId: number, sectorId: number) => {
        setModalInfo({
            no: no,
            buildingId: buildingId,
            sectorId: sectorId,
        });
        setIsShowModal(true);
    };

    const handleReset = () => {
        setDate({
            year: dayjs(new Date()),
            month: dayjs(new Date()),
        });
        setSelected(["ALL"]);
    };

    // 양식 다운로드
    const handleDownloadFormat = () => {
        if (selected[0] !== "TRAINER") {
            Swal.fire({
                text: "트레이너 권역을 선택해 주세요",
                confirmButtonText: "확인",
            });
        } else {
            downloadFormat();
        }
    };

    // 엑셀 다운
    const handleDownloadExcel = () => {
        downloadExcel();
    };

    //등록 버튼
    const handleRegister = () => {
        setModalInfo({
            no: null,
            buildingId: 0,
            sectorId: 0,
        });
        setIsShowModal(true);
    };

    // 엑셀 업로드
    const handleExcelUpload = () => {
        if (file) {
            Swal.fire({
                text: `${date.year.format("YYYY년")} ${date.month.format("MM월")} 수선비에 저장됩니다.`,
                confirmButtonText: "확인",
                showCancelButton: true,
                cancelButtonText: "취소",
            }).then((result) => {
                if (result.isConfirmed) {
                    excelUpload();
                    setTimeout(submit, 500);
                }
            });
        } else {
            Swal.fire({
                text: "업로드할 파일을 선택해주세요",
                confirmButtonText: "확인",
            });
        }
    };

    return (
        <div>
            {isShowModal && (
                <CostMaintenanceLightingModal
                    division={userInfo?.division ?? null}
                    userId={userInfo?.userId ?? 0}
                    no={modalInfo.no}
                    buildingId={modalInfo.buildingId}
                    sectorId={modalInfo.sectorId}
                    year={date.year}
                    month={date.month}
                    close={() => setIsShowModal(false)}
                />
            )}
            <PageTitle>수선비품/등기구</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <FilterItem label={"연도"}>
                        <DatePicker
                            placeholder={"연도 선택"}
                            picker={"year"}
                            style={{width: "100px"}}
                            status={date.year === null ? "error" : ""}
                            value={date.year}
                            onChange={(e) => setDate((prev) => ({...prev, year: e}))}
                            allowClear={false}
                        />
                    </FilterItem>
                    <FilterItem label={"월"}>
                        <DatePicker
                            placeholder={"월 선택"}
                            className={filterCx("filter-datepicker")}
                            popupClassName="no-header"
                            picker={"month"}
                            format={"MM"}
                            style={{width: "100px"}}
                            value={date.month}
                            onChange={(e) => setDate((prev) => ({...prev, month: e}))}
                            allowClear={false}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                            )}
                        />
                    </FilterItem>
                    {userInfo?.division === "MEMBER" && (
                        <FilterItem label="구분">
                            <ShopCascader />
                        </FilterItem>
                    )}
                    <div className={cx("button-container")}>
                        <Button label={"엑셀다운"} color={"primary"} onClick={handleDownloadExcel} />
                        <Button
                            label={"조회"}
                            color={"primary"}
                            onClick={() => {
                                setPage(1);
                                submit(true);
                            }}
                        />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    rowKey={"targetCost"}
                    bordered
                    onRow={() => ({
                        onClick: handleRegister,
                    })}
                    label={
                        <>
                            <span>수선비 TOTAL</span>
                            <div className={tableCx("button-container")}>
                                {userInfo?.division === "MEMBER" && (
                                    <>
                                        <Button label="양식다운로드" color={"primary"} onClick={handleDownloadFormat} />
                                        <Upload.Dragger
                                            className={tableCx("upload")}
                                            maxCount={1}
                                            showUploadList={false}
                                            beforeUpload={(file) => {
                                                const isExcel =
                                                    file.type === "application/vnd.ms-excel" ||
                                                    file.type ===
                                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                                if (!isExcel) {
                                                    Swal.fire({
                                                        text: "엑셀(xls, xlsx) 파일만 업로드할 수 있습니다",
                                                        confirmButtonText: "확인",
                                                    });
                                                    return Upload.LIST_IGNORE;
                                                }
                                                setFile(file);
                                                return false;
                                            }}
                                        >
                                            <div className={tableCx("upload-text")}>
                                                {file ? file.name : "선택된 파일 없음"}
                                            </div>
                                        </Upload.Dragger>
                                        <Button label="엑셀 업로드" onClick={handleExcelUpload} />
                                    </>
                                )}
                                <Button label="등록" onClick={handleRegister} />
                            </div>
                        </>
                    }
                    dataSource={[response.total]}
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    loading={isLoading}
                    rowClassName={cx("table-row")}
                    noPagination
                    pagination={false}
                    page={page}
                    totalCount={1}
                    columns={[
                        {
                            title: "연도",
                            key: "totalYear",
                            dataIndex: "year",
                            align: "center",
                        },
                        {
                            title: "월",
                            key: "totalMonth",
                            dataIndex: "month",
                            align: "center",
                        },
                        {
                            title: "목표",
                            key: "totalTargetCost",
                            dataIndex: "targetCost",
                            align: "center",
                            render: (value) => value.toLocaleString(),
                        },
                        {
                            title: "신청",
                            children: [
                                {
                                    title: "신청금액",
                                    align: "center",
                                    key: "totalCostRequest",
                                    dataIndex: ["cost", "request"],
                                    width: "12%",
                                    render: (value) => value.toLocaleString(),
                                },
                                {
                                    title: "등기구구입대",
                                    align: "center",
                                    key: "totalLightingRequest",
                                    dataIndex: ["lighting", "request"],
                                    width: "12%",
                                    render: (value) => value.toLocaleString(),
                                },
                                {
                                    title: "수선비품구입대",
                                    align: "center",
                                    key: "totalMaintenanceRequest",
                                    dataIndex: ["maintenance", "request"],
                                    width: "12%",
                                    render: (value) => value.toLocaleString(),
                                },
                            ],
                        },
                        {
                            title: "정산",
                            children: [
                                {
                                    title: "정산금액",
                                    align: "center",
                                    key: "totalCostReflect",
                                    dataIndex: ["cost", "reflect"],
                                    width: "12%",
                                    render: (value) => value.toLocaleString(),
                                },
                                {
                                    title: "등기구구입대",
                                    align: "center",
                                    key: "totalLightingReflect",
                                    dataIndex: ["lighting", "reflect"],
                                    width: "12%",
                                    render: (value) => value.toLocaleString(),
                                },
                                {
                                    title: "수선비품구입대",
                                    align: "center",
                                    key: "totalMaintenanceReflect",
                                    dataIndex: ["maintenance", "reflect"],
                                    width: "12%",
                                    render: (value) => value.toLocaleString(),
                                },
                            ],
                        },
                    ]}
                />
                <CustomLabelTable
                    label={<span>지점별 수선비</span>}
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    rowKey={"buildingId"}
                    onRow={(item) => ({onClick: () => handleModal(item.no, item.buildingId, item.sectorId)})}
                    loading={isLoading}
                    dataSource={response.list}
                    bordered
                    rowClassName={cx("table-row")}
                    pagination={false}
                    page={page}
                    totalCount={response.allRecordCnt}
                    columns={[
                        {
                            title: "지점",
                            key: "buildingName",
                            dataIndex: "buildingName",
                            align: "center",
                        },
                        {
                            title: "부문",
                            key: "sectorName",
                            dataIndex: "sectorName",
                            align: "center",
                        },
                        {
                            title: "목표",
                            key: "target",
                            dataIndex: "targetCost",
                            align: "center",
                            render: (value) => value.toLocaleString(),
                        },

                        {
                            title: "신청",
                            children: [
                                {
                                    title: "신청금액",
                                    align: "center",
                                    key: "costRequest",
                                    dataIndex: ["cost", "request"],
                                    width: "9%",
                                    render: (value) => value.toLocaleString(),
                                },
                                {
                                    title: "등기구구입대",
                                    align: "center",
                                    key: "lightingRequest",
                                    dataIndex: ["lighting", "request"],
                                    width: "9%",
                                    render: (value) => value.toLocaleString(),
                                },
                                {
                                    title: "수선비품구입대",
                                    align: "center",
                                    key: "maintenanceRequest",
                                    dataIndex: ["maintenance", "request"],
                                    width: "9%",
                                    render: (value) => value.toLocaleString(),
                                },
                            ],
                        },
                        {
                            title: "정산",
                            children: [
                                {
                                    title: "정산금액",
                                    align: "center",
                                    key: "costReflect",
                                    dataIndex: ["cost", "reflect"],

                                    width: "9%",
                                    render: (value) => value.toLocaleString(),
                                },
                                {
                                    title: "등기구구입대",
                                    align: "center",
                                    key: "lightingReflect",
                                    dataIndex: ["lighting", "reflect"],
                                    width: "9%",
                                    render: (value) => value.toLocaleString(),
                                },
                                {
                                    title: "수선비품구입대",
                                    align: "center",
                                    key: "maintenanceReflect",
                                    dataIndex: ["maintenance", "reflect"],
                                    width: "9%",
                                    render: (value) => value.toLocaleString(),
                                },
                            ],
                        },
                        {
                            title: "등록/수정일",
                            children: [
                                {
                                    title: "최초 등록일",
                                    align: "center",
                                    dataIndex: "createDate",
                                    key: "createDate",
                                },
                                {
                                    title: "최종 수정일",
                                    align: "center",
                                    dataIndex: "updateDate",
                                    key: "updateDate",
                                },
                            ],
                        },
                    ]}
                ></CustomLabelTable>
            </div>
        </div>
    );
};

export {CostMaintenanceAndLightingPage};

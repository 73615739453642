import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import {Input, Table} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useMobileMachineBoardList} from "~/data/machine/board/use-machine";

import styles from "./search.module.scss";

const cx = classNames.bind(styles);

const MobileEquipmentSearchPage = () => {
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState<string>("");
    const [originalList, setOriginalList] = useState<JSX.Element[]>([]);
    const [displayList, setDisplayList] = useState<JSX.Element[]>([]);

    const [submit, response, isLoading] = useMobileMachineBoardList({});

    useEffect(() => {
        submit();
    }, []);
    useEffect(() => {
        if (response?.resultList) {
            setOriginalList(response?.resultList);
            setDisplayList(response?.resultList);
        }
    }, [response]);
    useEffect(() => {
        if (searchValue === "") {
            setDisplayList(originalList);
        }
    }, [searchValue, originalList]);

    const onClickSearchValue = () => {
        const lowerCaseSearchValue = searchValue.toLowerCase();
        const filterValue = originalList.filter((item) =>
            Object.values(item).some((value) => String(value).toLowerCase().includes(lowerCaseSearchValue)),
        );
        setDisplayList(filterValue);
    };
    const onClickOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onClickSearchValue();
            (e.target as HTMLInputElement).blur();
        }
    };

    return (
        <div>
            <div className={cx("inner-container")}>
                <h1 className={cx("title")}>설비 현황 검색</h1>
                <CloseOutlined
                    className={cx("close-button")}
                    onClick={() => {
                        navigate("/mobile/equipment");
                    }}
                />
            </div>
            <div className={cx("inner-container")}>
                <Input
                    className={cx("input")}
                    placeholder="검색어를 입력해 주세요."
                    onChange={(e) => setSearchValue(e.target.value)}
                    allowClear={{clearIcon: <CloseOutlined className={cx("input-reset-button")} />}}
                    onKeyUp={onClickOnKeyUp}
                />
                <button className={cx("button")} onClick={onClickSearchValue}>
                    검색
                </button>
            </div>
            <div className={cx("list-container")}>
                <div className={cx("count")}>{displayList?.length.toLocaleString()}건</div>
                <Table
                    className={cx("table")}
                    loading={isLoading}
                    dataSource={displayList?.map((row: any) => {
                        row["key"] = row.machineId;
                        return row;
                    })}
                    pagination={false}
                    onRow={(data, index) => ({
                        onClick: () => {
                            navigate(
                                `/mobile/equipment/detail/${data!.companyId}/${data!.buildingId}/${data!.machineId}`,
                            );
                        },
                    })}
                    columns={[
                        {
                            title: "공정",
                            dataIndex: "process",
                            key: "process",
                            align: "center",
                            width: "20%",
                        },
                        {
                            title: "장비명",
                            dataIndex: "machineName",
                            key: "machineName",
                            align: "center",
                            ellipsis: true,
                        },
                        {
                            title: "제조사",
                            dataIndex: "machineManufactureCompany",
                            key: "machineManufactureCompany",
                            align: "center",
                        },
                        {
                            title: "설치연월",
                            dataIndex: "machineInstallDate",
                            key: "machineInstallDate",
                            align: "center",
                            width: "20%",
                            render: (date) => (dayjs(date).isValid() ? date : ""),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {MobileEquipmentSearchPage};

import {useEffect, useState} from "react";
import {Checkbox} from "antd";
import classNames from "classnames/bind";

import {MergeTargetListProps} from "./merge-target-list.interface";

import styles from "./merge-target-list.module.scss";

const cx = classNames.bind(styles);

const MergeTargetList = ({
    selectedTargetList,
    targetType,
    targetList,
    searchText,
    disabled,
    onChange,
}: MergeTargetListProps) => {
    const [options, setOptions] = useState<Array<{label: string; value: string | number}>>([]);
    const [checkBox, setCheckBox] = useState<JSX.Element[]>([]);

    const renderContent = () => {
        switch (targetType) {
            case "ALL":
                return [{name: "전체", id: 0}];
            case "SECTOR":
                return targetList?.sector;
            case "TRAINER":
                return targetList?.trainer;
            case "SPECIFIC":
                return targetList?.specific;
            default:
                return [];
        }
    };

    useEffect(() => {
        const content = renderContent() ?? [];
        setOptions(content.map((data) => ({label: data.name, value: data.id})));
    }, [targetList, targetType]);

    useEffect(() => {
        setCheckBox(
            options.map((data, index) => (
                <Checkbox
                    key={index}
                    value={data.value}
                    className={cx({hidden: options.some((item) => !data.label.includes(searchText))})}
                >
                    {data.label}
                </Checkbox>
            )),
        );
    }, [options, searchText]);

    return (
        <div className={cx("container")}>
            <Checkbox.Group
                className={cx("checkbox-group")}
                // options={options}
                onChange={onChange}
                value={selectedTargetList}
                disabled={targetType === "ALL" || disabled}
            >
                {checkBox}
            </Checkbox.Group>
        </div>
    );
};

export {MergeTargetList};

import {InternalAxiosRequestConfig} from "axios";

const onFulfilled = (config: InternalAxiosRequestConfig) => {
    const userString = localStorage.getItem("LM_USER") || "";
    if (userString) {
        const user = JSON.parse(userString);
        if (!user.token) window.location.href = "/login";
        config.headers.set("Authtoken", user.token);
    }
    return config;
};

const onRejected = (error: ((error: any) => any) | null) => {
    return Promise.reject(error);
};

export {onFulfilled, onRejected};

import React, {Fragment, useMemo} from "react";
import classNames from "classnames/bind";

import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {ReportCategory, useLegalForm} from "~/data/check/form/use-legal-form";
import {UpdateFormProps} from "~/pages/check/form/legal/update/update-form.interface";

import styles from "./update-form.modal.module.scss";

const cx = classNames.bind(styles);

const UpdateFormModal = ({onClose, form}: UpdateFormProps) => {
    const {legalFormDetail} = useLegalForm(form.reportInfoId);

    return (
        <SideModal onClose={onClose} size="normal">
            <PageTitle>{form.reportNm}</PageTitle>
            <ul className={cx("list")}>
                {legalFormDetail?.list.map((data) => (
                    <li key={data.reportCheckId} className={cx("item")}>
                        <h3 className={cx("title")}>{data.checkHistoryNm}</h3>
                        <p className={cx("text")}>
                            {data.reportCheckDate}
                            {data.reportCheckDay} |{" "}
                            {data.reportCheckNotitimeList.map((notitime) => notitime.notitime).join(" ")}
                        </p>
                        <TableForm reportCategory={data.reportCategoryList} />
                    </li>
                ))}
            </ul>
        </SideModal>
    );
};

const TableForm = ({reportCategory}: {reportCategory: Array<ReportCategory>}) => {
    return (
        <Fragment>
            <table className={cx("table")}>
                {reportCategory.map((category) => {
                    return (
                        <Fragment key={category.reportItemId}>
                            <tr>
                                <th>{category.reportItemNm}</th>
                                {!category.reportCategoryVO?.length && (
                                    <Fragment>
                                        {category.unitNm ? (
                                            <Fragment>
                                                <td className={cx("center")}>{category.unitNm}</td>
                                                <td className={cx("center")}>
                                                    {category.unitNm && category.settingValue
                                                        ? category.settingValue + category.unitNm
                                                        : "-"}
                                                </td>
                                                <td className={cx("center")}>
                                                    {category.unitNm && category.settingMinValue
                                                        ? category.settingMinValue + category.unitNm
                                                        : "-"}
                                                </td>
                                                <td className={cx("center")}>
                                                    {category.unitNm && category.settingMaxValue
                                                        ? category.settingMaxValue + category.unitNm
                                                        : "-"}
                                                </td>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                {/*<td className={cx("center")}>양호</td>*/}
                                                {/*<td className={cx("center")}>보통</td>*/}
                                                {/*<td className={cx("center")}>불량</td>*/}
                                                {/*<td className={cx("center")}>해당없음</td>*/}
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </tr>
                            {!!category.reportCategoryVO?.length && (
                                <tr>
                                    <td
                                        key={category.reportItemId}
                                        colSpan={!category.reportCategoryVO?.length ? 1 : 6}
                                    >
                                        <TableForm reportCategory={category.reportCategoryVO} />
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    );
                })}
            </table>
        </Fragment>
    );
};

export {UpdateFormModal};

import React, {useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {Member} from "./use-department.interface";

function useMemberFromDepartment(buildingId: number) {
    const [members, setMembers] = useState<Array<Member>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        if (buildingId > 0)
            return httpRequest<undefined, Array<Member>>({
                url: `/buildings/${buildingId}/departments`,
            });
        else return null;
    }, [buildingId]);

    useEffect(() => getList(), [request]);

    const getList = () => {
        if (request) {
            setLoading(true);
            request
                .submit()
                .then((response) => setMembers(response.data))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return {
        loading,
        refresh: getList,
        members: members,
    };
}

export {useMemberFromDepartment};
export type {Member};

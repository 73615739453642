import classNames from "classnames/bind";

import {CostMaintenanceTableProps} from "./cost-maintenance-table.interface";

import styles from "./cost-maintenance-table.module.scss";

const cx = classNames.bind(styles);

const CostMaintenanceTable = ({rows}: CostMaintenanceTableProps) => {
    return (
        <div className={cx("table")}>
            <div className={cx("table-header")}>
                <div className={cx("table-column")}>No</div>
                <div className={cx("table-column")}>지점</div>
                <div className={cx("table-column")}>부문</div>
                <div className={cx("table-column")}>목표금액</div>
                <div className={cx("table-column")}>구분</div>
                <div className={cx("table-column")}>정산일자</div>
                <div className={cx("table-column")}>금액</div>
                <div className={cx("table-column")}>등기구구입대</div>
                <div className={cx("table-column")}>수선비품구입대</div>
                <div className={cx("table-column")}>비고</div>
            </div>
            <div className={cx("table-body")}>{rows}</div>
        </div>
    );
};

export {CostMaintenanceTable};

import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {DatePicker} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {FilterItem} from "~/components/filter-item/filter-item";
import {PageTitle} from "~/components/page-title/page-title";
import {useCostTrainerExcelDownload, useCostTrainerList} from "~/data/cost/trainer/use-cost-trainer-list";
import {CostTrainerModal} from "~/pages/cost/trainer/modal/cost-trainer.modal";
import {useLocalStorage} from "~/utills/useLocalStorage";

import {FlatDataType} from "./cost-trainer.interface";

import styles from "./cost-trainer.module.scss";
import tableStyles from "~/components/custom-label-table/custom-label-table.module.scss";
import filterStyles from "~/components/filter-item/filter-item.module.scss";

const cx = classNames.bind(styles);
const filterCx = classNames.bind(filterStyles);
const tableCx = classNames.bind(tableStyles);

const CostTrainerPage = () => {
    const {dates} = Object.fromEntries(new URLSearchParams(useLocation().search));

    const [page, setPage] = useState<number>(1);
    const [division, setDivision] = useState<"MEMBER" | "MANAGER" | "EMPLOYEE" | null>(null);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [flatData, setFlatData] = useState<FlatDataType[]>([]);
    const [trainerSectorId, setTrainerSectorId] = useState<number>(0);
    const [inputs, setInputs] = useState<{year: Dayjs | null; month: Dayjs | null}>({
        year: dates ? dayjs(dates.split(",")[0]) : dayjs(new Date()),
        month: dates ? dayjs(dates.split(",")[1]) : dayjs(new Date()),
    });
    // 테이블
    const [submitTableList, tableList, isTableListLoading] = useCostTrainerList({
        pageNo: page,
        ...inputs,
    });
    const [excelDownload] = useCostTrainerExcelDownload(inputs);

    const {setItem, getItem} = useLocalStorage();

    const handleReset = () => {
        setInputs({
            year: null,
            month: null,
        });
    };

    // 계정 권한
    useEffect(() => {
        setDivision(JSON.parse(getItem("LM_USER")!).division);
    }, [getItem]);

    useEffect(() => {
        window.scrollTo(0, 0);
        submitTableList();
    }, [page]);

    // 지점 디스플레이 시
    // useEffect(() => {
    //     if (tableList) {
    //         setFlatData(
    //             tableList.trainerReserveFundList.flatMap((item) =>
    //                 item.trainerReserveFundBuildingList.map((building, index) => ({
    //                     key: `${item.trainerSectorId}-${building.num}`,
    //                     ...item,
    //                     ...building,
    //                     rowSpan: index === 0 ? item.trainerReserveFundBuildingList.length : 0, // 첫 번째 항목에만 rowSpan을 적용
    //                     trainerReserveFund: index === 0 ? item.trainerReserveFund : 0,
    //                     allocationAmount: index === 0 ? item.allocationAmount : 0,
    //                     requestAmount: index === 0 ? item.requestAmount : 0,
    //                     differenceAmount: index === 0 ? item.differenceAmount : 0,
    //                 })),
    //             ),
    //         );
    //     }
    // }, [tableList]);

    return (
        <div>
            {isShowModal && (
                <CostTrainerModal
                    year={inputs.year}
                    month={inputs.month}
                    close={() => setIsShowModal(false)}
                    division={division}
                    submitTableList={submitTableList}
                    trainerSectorId={trainerSectorId}
                />
            )}
            <PageTitle>트레이너 예비비</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <FilterItem label={"연도"}>
                        <DatePicker
                            placeholder={"연도 선택"}
                            picker={"year"}
                            style={{width: "100px"}}
                            status={inputs.year === null && inputs.month !== null ? "error" : ""}
                            value={inputs.year}
                            onChange={(e) => {
                                if (e === null) {
                                    setInputs((prev) => ({...prev, year: e, month: null}));
                                } else {
                                    setInputs((prev) => ({...prev, year: e}));
                                }
                            }}
                        />
                    </FilterItem>
                    <FilterItem label={"월"}>
                        <DatePicker
                            placeholder={"전체"}
                            className={filterCx("filter-datepicker")}
                            popupClassName="no-header"
                            picker={"month"}
                            format={"MM"}
                            status={inputs.year !== null && inputs.month === null ? "error" : ""}
                            style={{width: "100px"}}
                            value={inputs.month}
                            onChange={(e) => setInputs((prev) => ({...prev, month: e}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                            )}
                        />
                    </FilterItem>
                    <div className={cx("button-container")}>
                        <Button
                            label={"조회"}
                            color={"primary"}
                            onClick={() => {
                                if (
                                    (inputs.year === null && inputs.month !== null) ||
                                    (inputs.year !== null && inputs.month === null)
                                ) {
                                    Swal.fire({
                                        text: "연도, 월 모두 선택하거나 선택하지 않아야 합니다.",
                                        confirmButtonText: "확인",
                                    });
                                } else {
                                    submitTableList();
                                }
                            }}
                        />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <>
                            <div className={tableCx("button-container")}>
                                <Button
                                    label="엑셀다운로드"
                                    color={"primary"}
                                    onClick={() => {
                                        if (
                                            (inputs.year === null && inputs.month !== null) ||
                                            (inputs.year !== null && inputs.month === null)
                                        ) {
                                            Swal.fire({
                                                text: "연도, 월 모두 선택하거나 선택하지 않아야 합니다.",
                                                confirmButtonText: "확인",
                                            });
                                        } else {
                                            excelDownload();
                                        }
                                    }}
                                />
                            </div>
                        </>
                    }
                    perPage={10}
                    page={page}
                    loading={isTableListLoading}
                    onPageChange={(page) => setPage(page)}
                    totalCount={tableList?.allRecordCnt ?? 0}
                    pagination={false}
                    noPagination
                    onRow={(record) => ({
                        onClick: (e) => {
                            setTrainerSectorId(record.trainerSectorId);
                            setIsShowModal(true);
                        },
                    })}
                    rowKey={"trainerSectorName"}
                    dataSource={tableList?.trainerReserveFundList}
                    bordered
                    columns={[
                        // {
                        //     title: "No",
                        //     key: "num",
                        //     dataIndex: "num",
                        //     align: "center",
                        //     width: "3%",
                        // },
                        {
                            title: "지역",
                            key: "trainerSectorName",
                            dataIndex: "trainerSectorName",
                            onCell: (record: FlatDataType) => ({
                                rowSpan: record.rowSpan,
                                style: {verticalAlign: "middle"},
                            }),
                            align: "center",
                            width: "5%",
                        },
                        // {
                        //     title: "지점명",
                        //     key: "buildingName",
                        //     dataIndex: "buildingName",
                        //     align: "center",
                        //     width: "9%",
                        // },
                        // {
                        //     title: "점별 배정 금액",
                        //     children: [
                        //         {
                        //             title: "기타유형자산",
                        //             key: "totalOtherTangible",
                        //             dataIndex: "totalOtherTangible",
                        //             render: (value) => (value ? value.toLocaleString() : 0),
                        //             width: "8%",
                        //         },
                        //         {
                        //             title: "안전시설공사",
                        //             key: "totalSafetyFacility",
                        //             dataIndex: "totalSafetyFacility",
                        //             render: (value) => (value ? value.toLocaleString() : 0),
                        //             width: "8%",
                        //         },
                        //         {
                        //             title: "등기구구입대",
                        //             key: "totalLightFixture",
                        //             dataIndex: "totalLightFixture",
                        //             render: (value) => (value ? value.toLocaleString() : 0),
                        //             width: "8%",
                        //         },
                        //         {
                        //             title: "수선유지비품",
                        //             key: "totalRepairMaintenance",
                        //             dataIndex: "totalRepairMaintenance",
                        //             render: (value) => (value ? value.toLocaleString() : 0),
                        //             width: "8%",
                        //         },
                        //         {
                        //             title: "합계",
                        //             key: "subtotal",
                        //             dataIndex: "subtotal",
                        //             render: (value) => (value ? value.toLocaleString() : 0),
                        //             width: "8%",
                        //         },
                        //     ],
                        // },
                        {
                            title: "트레이너 예비비",
                            dataIndex: "trainerReserveFund",
                            key: "trainerReserveFund",
                            onCell: (record: FlatDataType) => ({
                                rowSpan: record.rowSpan,
                                style: {verticalAlign: "middle"},
                            }),
                            render: (value) => (value ? value.toLocaleString() : 0),
                            align: "center",
                            width: "9%",
                        },
                        {
                            title: "총 배정 금액",
                            dataIndex: "allocationAmount",
                            key: "allocationAmount",
                            onCell: (record: FlatDataType) => ({
                                rowSpan: record.rowSpan,
                                style: {verticalAlign: "middle"},
                            }),
                            render: (value) => (value ? value.toLocaleString() : 0),
                            align: "center",
                            width: "9%",
                        },
                        {
                            title: "총 요청 금액",
                            dataIndex: "requestAmount",
                            key: "requestAmount",
                            onCell: (record: FlatDataType) => ({
                                rowSpan: record.rowSpan,
                                style: {verticalAlign: "middle"},
                            }),
                            render: (value) => (value ? value.toLocaleString() : 0),
                            align: "center",
                            width: "9%",
                        },
                        {
                            title: "차액",
                            dataIndex: "differenceAmount",
                            key: "differenceAmount",
                            onCell: (record: FlatDataType) => ({
                                rowSpan: record.rowSpan,
                                style: {verticalAlign: "middle"},
                            }),
                            render: (value) => (value ? value.toLocaleString() : 0),
                            align: "center",
                            width: "9%",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {CostTrainerPage};
